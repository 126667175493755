export default function StatusBadge({
  status,
  color,
  className,
  testid,
}: {
  status: string | null;
  color?: string;
  className?: string;
  testid?: string;
}) {
  function determineStatusColor(status: string | null) {
    if (className) {
      return className;
    }
    if (color) {
      return `bg-${color}-200 text-${color}-800`;
    }
    switch (status) {
      case 'DONE':
        return 'bg-green-200 text-green-800';
      case 'DRAFT':
        return 'bg-blue-200 text-blue-800';
      case 'REQUESTED':
        return 'bg-orange-200 text-orange-800';
      case 'SUBMITTED':
        return 'bg-red-200 text-red-800';
      default:
        return 'bg-slate-300 text-slate-800';
    }
  }

  return (
    <div
      className={`flex h-6 justify-center rounded px-2.5 py-0.5 text-sm font-semibold ${determineStatusColor(
        status
      )}`}
      data-testid={testid ? `${testid}-status-badge-${status}` : undefined}
    >
      <div>{status ? status.toUpperCase() : ''}</div>
    </div>
  );
}
