import { WaveFile } from 'wavefile';
import { ITrack } from '../services/track.service';

import { IComposer } from '../services/composer.service';
import { IProject } from '../services/project.service';
import { ITag } from '../services/tag.service';
import { ITeam } from '../services/team.service';
import { buildListInfo } from './mapper_LISTINFO';
import { buildIXML } from './mapper_iXML';

/**
 * Converts a Blob object to a Uint8Array object.
 * @param blob The Blob object to convert.
 * @returns A Promise that resolves with the resulting Uint8Array object.
 * @throws An error if the Blob object cannot be converted to a Uint8Array object.
 */
export function blobToUint8Array(blob: Blob): Promise<Uint8Array> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const arrayBuffer = event.target?.result as ArrayBuffer;
      if (arrayBuffer) {
        const uint8Array = new Uint8Array(arrayBuffer);
        resolve(uint8Array);
      } else {
        reject(new Error('Failed to convert Blob to Uint8Array.'));
      }
    };

    reader.onerror = () => {
      reject(new Error('Failed to read Blob.'));
    };

    reader.readAsArrayBuffer(blob);
  });
}

/**
 * Converts a JSON object to an XML string.
 * @param jsonObj - The JSON object to convert.
 * @returns The XML string representation of the JSON object.
 */
function jsonToXml(jsonObj: any) {
  let xml = '';

  for (let key in jsonObj) {
    if (jsonObj.hasOwnProperty(key)) {
      if (Array.isArray(jsonObj[key])) {
        jsonObj[key].forEach((item: any) => {
          xml += `<${key}>${jsonToXml(item)}</${key}>`;
        });
      } else if (typeof jsonObj[key] === 'object') {
        xml += `<${key}>${jsonToXml(jsonObj[key])}</${key}>`;
      } else {
        xml += `<${key}>${jsonObj[key]}</${key}>`;
      }
    }
  }
  return xml;
}

export function addTagsToWav(
  buffer: Uint8Array,
  track: ITrack,
  project: IProject,
  composers: IComposer[],
  team: ITeam,
  tags: ITag[]
) {
  console.log(team);
  let wav = new WaveFile(buffer);

  // ------- LIST INFO -------
  // map LIST INFO
  const listInfo = buildListInfo(team, project, track, composers, tags);
  // set Tags
  Object.keys(listInfo).forEach((key) =>
    wav.setTag(key, listInfo[key as keyof typeof listInfo])
  );

  // ------- IXML -------
  // map IXML
  const ixmlObj = buildIXML(team, project, track, composers, tags);
  // convert to xml
  const ixmlString = jsonToXml(ixmlObj);
  // set IXML
  wav.setiXML(ixmlString);

  // ------- BEXT -------
  // map bext
  // const bext = buildBEXT(team, project, track, composers, tags);

  // ------- PMX -------
  // add pmx
  // wav.set_PMX()

  // ,"cue ":"\u0001","_PMX":"

  return wav.toBuffer();
}
