import {
  IPaginatedResponse,
  IResponse,
  ISearchRequest,
  errorWrapper,
} from '..';
import client from '../client';

interface INewProject {
  name: string;
  teamId: string;
  type: string;
}

export interface IProject {
  id: string;
  name: string;
  status: string;
  message?: string;
  description: string;
  type: 'DEMO' | 'SUBMISSION' | 'ALBUM' | 'PLAYLIST';

  publicAccess: boolean;
  publicAccessAllowDownload: boolean;

  teamId: string;
  metadata: {
    artworkFilename?: string;
    albumCode?: string;
    subtitle?: string;
    releaseDate?: string;
  };

  dueDate: string | null;
  createdAt: string;
  updatedAt: string;
  members: IProjectMembership[];
}

export interface IUserShort {
  id: string;
  firstname: string;
  lastname: string;
  avatar: string;
  email?: string;
}

export interface IProjectMembership {
  projectId: string;
  targetUserId: string;
  role: string;
  createdAt: string;

  user: IUserShort;
}

export interface IProjectInvitation {
  id: string;

  projectId: string;

  projectRole: string;

  targetUserEmail: string;
  targetUserId: string;

  status: string;

  inviteOriginUserId: string;

  createdAt: string;
  updatedAt: string;

  user: IUserShort;
}

async function getProjects() {
  return client.get<IResponse<IProject[]>>('/project');
}

async function getProjectsOfUser(id: string) {
  return client.get<IResponse<IProject[]>>(`/project/userId:${id}`);
}

async function getProject(id: string) {
  return client.get<IResponse<IProject>>(`/project/${id}`);
}

async function getProjectMembers(id: string) {
  return client.get<IResponse<IProjectMembership[]>>(`/project/${id}/member`);
}

async function addProjectMember(id: string, userId: string) {
  return client.post<IResponse<IUserShort[]>>(`/project/${id}/member`, {
    userId,
  });
}

async function inviteProjectMember(
  id: string,
  {
    email,
    userId,
    role,
    teamId,
  }: { email?: string | null; userId?: string; role?: string; teamId?: string }
) {
  return client.post<IResponse<IProjectInvitation>>(
    `/project/${id}/member/invite`,
    {
      email,
      userId,
      role: role || 'MEMBER',
      teamId: teamId,
    }
  );
}

async function createProjectJoinRequest(id: string, userId: string) {
  return client.post<IResponse<IProjectInvitation>>(
    `/project/${id}/member/joinRequest`,
    {
      userId,
    }
  );
}

async function getProjectInvitesOfUser(id: string) {
  return client.get<IResponse<IProjectInvitation[]>>(
    `/project/invite/user/${id}`
  );
}

async function updateProjectInvitePartially(
  projectInviteId: string,
  project: Partial<IProjectInvitation>
) {
  return client.patch<IResponse<IProject>>(
    `/projectInvite/${projectInviteId}`,
    project
  );
}

async function getProjectInvites(id: string) {
  return client.get<IResponse<IProjectInvitation[]>>(`/project/${id}/invite`);
}

async function revokeProjectInvite(id: string, email: string) {
  return client.delete<IResponse<string>>(`/project/${id}/invite/${email}`);
}

async function removeProjectMember(id: string, userId: string) {
  return client.delete<IResponse<IUserShort[]>>(
    `/project/${id}/member/${userId}`
  );
}

async function addProject(project: INewProject) {
  return client.post<IResponse<IProject>>(`/project`, project);
}

async function updateProject(id: string, project: Partial<IProject>) {
  return client.put<IResponse<IProject>>(`/project/${id}`, project);
}

// async function updateProjectPartially(
//   projectId: string,
//   project: Partial<IProject>
// ) {
//   return client.patch<IResponse<IProject>>(`/project/${projectId}`, project);
// }

async function updateProjectPartially(
  projectId: string,
  project: Partial<IProject>
) {
  return errorWrapper(
    () => client.patch<IResponse<IProject>>(`/project/${projectId}`, project),
    {}
  );
}

async function deleteProject(id: string) {
  return client.delete<IResponse<string>>(`/project/${id}`);
}

async function searchProject(req: ISearchRequest) {
  return client.post<IPaginatedResponse<IProject[]>>('/project/search', req);
}

const api = {
  getProjects,
  getProjectsOfUser,
  getProject,
  addProject,
  updateProject,
  updateProjectPartially,
  getProjectMembers,
  addProjectMember,
  removeProjectMember,
  inviteProjectMember,
  createProjectJoinRequest,
  getProjectInvites,
  getProjectInvitesOfUser,
  revokeProjectInvite,
  updateProjectInvitePartially,
  deleteProject,
  searchProject,
};

export default api;
