import axios from 'axios';
import { KeyboardEvent, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import OAuthBar from '../../components/auth/OAuthBar';
import { KTRLogo } from '../../components/shared/ktr';
import { useAuth } from '../../hooks/useAuth';

type LocationProps = {
  state: {
    from: Location;
  };
};

export default function Login() {
  const { login, isAuthenticated } = useAuth();
  let navigate = useNavigate();
  let location = useLocation() as LocationProps;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (isAuthenticated) {
      if (location.state && location.state.from) {
        console.log(`navigating to ${location.state.from.pathname}`);
        navigate(location.state.from.pathname, { replace: true });
      } else {
        navigate('/', { replace: true });
      }
    }
  });

  async function loginEmail() {
    try {
      const success = await login({ email, password });
      if (success) {
        toast.success('Login successful');
        // navigate(from, { replace: true });
      } else {
        toast.error('Error on signin');
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  function handleEnter(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      loginEmail();
    }
  }

  return (
    <div className='flex flex-grow lg:mt-20'>
      <div className='flex w-full flex-col items-center'>
        <div className='flex flex-col items-center'>
          <div className='h-40 w-40'>
            <KTRLogo />
          </div>
          <h2 className='mt-6 text-center text-3xl font-extrabold'>
            Sign in to your account
          </h2>
          <p className='mt-2 text-center text-sm'>
            Or{' '}
            <Link
              className='font-medium text-indigo-600 hover:text-indigo-500'
              to='/signUp'
            >
              sign up for a new account
            </Link>
          </p>
        </div>
        <div className='mt-8 w-full max-w-md rounded-md bg-white px-8 py-8 pt-1 shadow'>
          <div className='mt-6 space-y-5 rounded-md shadow-sm'>
            <div>
              <label
                htmlFor='email-address'
                className='block text-sm font-medium text-gray-700'
              >
                Email address
              </label>
              <div className='mt-1'>
                <input
                  id='email-address'
                  name='email'
                  type='email'
                  autoComplete='email'
                  required
                  className='w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  placeholder=''
                  onChange={(email) => setEmail(email.target.value)}
                  defaultValue={email}
                  onKeyDown={handleEnter}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor='password'
                className='block text-sm font-medium text-gray-700'
              >
                Password
              </label>
              <div className='mt-1'>
                <input
                  id='password'
                  name='password'
                  type='password'
                  autoComplete='current-password'
                  required
                  className='w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  placeholder=''
                  onChange={(password) => setPassword(password.target.value)}
                  defaultValue={password}
                  onKeyDown={handleEnter}
                />
              </div>
            </div>
          </div>

          <div className='mt-6 flex items-center justify-between'>
            <div className='flex items-center'>
              <input
                id='remember-me'
                name='remember-me'
                type='checkbox'
                className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500'
              />
              <label
                htmlFor='remember-me'
                className='ml-2 block text-sm text-gray-900'
              >
                Remember me
              </label>
            </div>

            <div className='text-sm'>
              <Link
                className='font-medium text-indigo-600 hover:text-indigo-500'
                to='/resetPassword'
              >
                Forgot your password?
              </Link>
            </div>
          </div>

          <div className='mt-6'>
            <button
              type='submit'
              className='group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
              onClick={() => loginEmail()}
            >
              Sign in
            </button>
          </div>
          <div className='relative my-8'>
            <div className='absolute inset-0 flex items-center'>
              <div className='w-full border-t border-gray-300' />
            </div>
            <div className='relative flex justify-center text-sm'>
              <span className='bg-white px-2 text-gray-500'>
                Or continue with
              </span>
            </div>
          </div>
          <OAuthBar />
        </div>
      </div>
    </div>
  );
}
