import { useState } from 'react';
import { FaInfoCircle, FaTag } from 'react-icons/fa';
import { TabBar } from '../../shared/TabBar';
import { useAlbumStudioState } from '../albumState';
import { AlbumTagsPicker } from './albumInfo/AlbumTagsPicker';
import { GeneralTabWindowAlbum } from './albumInfo/GeneralTabWindowAlbum';

const tabs = [
  {
    name: 'General',
    icon: <FaInfoCircle size={16} />,
  },
  {
    name: 'Tags',
    icon: <FaTag size={16} />,
  },
];

export default function AlbumInfo() {
  const { activeAlbum } = useAlbumStudioState();
  const [activeTab, setActiveTab] = useState<number>(0);

  const TABS_CONTENT =
    activeAlbum !== null
      ? [
          <GeneralTabWindowAlbum />,
          <div className='w-full'>
            <AlbumTagsPicker activeAlbum={activeAlbum} />
          </div>,
        ]
      : [];

  return (
    <div
      className='flex w-full flex-col overflow-hidden rounded-2xl bg-gray-50 shadow-xl dark:bg-gray-800'
      data-testid={`album-card-${activeAlbum?.name}`}
    >
      <TabBar tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      {activeAlbum !== null && (
        <div className='flex w-full flex-grow justify-center'>
          <div className='flex w-full flex-grow flex-col items-center'>
            <div className='flex w-full flex-grow bg-white py-2 px-8 dark:bg-slate-900'>
              {TABS_CONTENT[activeTab]}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
