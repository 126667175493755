import axios from 'axios';
import { FaChevronRight } from 'react-icons/fa';
import { toast } from 'react-toastify';
import api from '../../api';
import { IAuthenticatedUser } from '../../hooks/useAuth';
import { FormikErrors, useFormik } from 'formik';
import { classNames, isEmail } from '../../utils';
import { ITeam } from '../../api/services/team.service';
import { useTeamLogo } from '../../hooks/useTeamLogo';

interface FormValues {
  firstname: string;
  lastname: string;
  email: string;
}

export function LabelWelcomeStepWindow({
  user,
  team,
  nextStep,
}: {
  user: IAuthenticatedUser | null;
  team: ITeam | null;
  nextStep: () => void;
}) {
  const { tenantLandingImageUrl } = useTeamLogo();

  const formik = useFormik<FormValues>({
    initialValues: {
      firstname: user?.firstname || '',
      lastname: user?.lastname || '',
      email: user?.email || '',
    },
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {};

      if (!values.firstname) {
        errors.firstname = 'Required';
      }

      if (!values.email) {
        errors.firstname = 'Required';
      }

      if (values.email && !isEmail(values.email)) {
        errors.firstname = 'Email is invalid';
      }

      return errors;
    },
    onSubmit: ({ email, firstname, lastname }) => {
      if (!user) return;
      api.user
        .updateUser(user.id, {
          email,
          firstname,
          lastname,
        })
        .then(() => {
          toast.success('User Information updated successfully');
          nextStep();
        })
        .catch((e) => {
          if (axios.isAxiosError(e)) {
            if (e.response?.data) {
              toast.error(e.response?.data.errorMessage);
            }
          } else {
            console.log(e);
            toast.error(
              'Something really went wrong, you might want to contact support!'
            );
          }
        });
    },
  });

  return (
    <>
      {tenantLandingImageUrl && (
        <div className=''>
          <img
            className='mt-4 max-h-16'
            src={tenantLandingImageUrl}
            alt='tenantLandingImageUrl'
          />
        </div>
      )}
      <span className='mt-6 text-center font-medium text-gray-700 dark:text-slate-200'>
        <div>
          <b>{team?.name || 'your label'}</b> has invited you to
          {team?.teamMembershipDTO?.role === 'GUEST'
            ? ' submit your tracks!'
            : ' help managing their library.'}
        </div>
        <div className='mt-8'>
          Let's get started by confirming your account information.
        </div>
      </span>

      <form className='mt-2 w-full' onSubmit={formik.handleSubmit}>
        <div className='my-5 mx-auto flex w-full flex-col items-center justify-around pb-2 lg:w-4/6 lg:flex-row'>
          <div className='w-1/2 space-y-5'>
            <div>
              <label
                htmlFor='first-name'
                className='block text-sm font-medium text-gray-700 dark:text-slate-200'
              >
                Artist name
              </label>
              <div className='relative mt-1'>
                <input
                  id='first-name'
                  name='firstname'
                  type='text'
                  autoComplete='firstname'
                  className={classNames(
                    'w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10  focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 dark:border-slate-600 dark:bg-slate-800 dark:text-slate-200 sm:text-sm',
                    formik.errors.firstname ? 'border-red-500' : ''
                  )}
                  placeholder=''
                  onChange={formik.handleChange}
                  defaultValue={formik.values.firstname}
                />
                {formik.errors.firstname && (
                  <div className='absolute text-xs text-red-500'>
                    {formik.errors.firstname}
                  </div>
                )}
              </div>
            </div>
            <div>
              <label
                htmlFor='email-address'
                className='block text-sm font-medium text-gray-700 dark:text-slate-200'
              >
                Email address
              </label>
              <div className='relative mt-1'>
                <input
                  id='email-address'
                  name='email'
                  type='email'
                  autoComplete='email'
                  required
                  className='w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500  focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 disabled:bg-slate-200 dark:border-slate-600 dark:bg-slate-800 dark:text-slate-200 sm:text-sm'
                  placeholder=''
                  disabled={user?.authProviderId !== 'local'}
                  onChange={formik.handleChange}
                  defaultValue={formik.values.email}
                />
                {formik.errors.email && (
                  <div className='absolute text-xs text-red-500'>
                    {formik.errors.email}
                  </div>
                )}
              </div>
            </div>
            {/* <div>
            <label
              htmlFor="last-name"
              className="block text-sm font-medium text-gray-700 dark:text-slate-200"
            >
              Lastname
            </label>
            <div className="mt-1">
              <input
                id="last-name"
                name="lastname"
                type="text"
                autoComplete="lastname"
                required
                className="w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 dark:bg-slate-800 dark:border-slate-600  dark:text-slate-200 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder=""
                onChange={(lastname) => setLastname(lastname.target.value)}
                defaultValue={lastname}
              />
            </div>
          </div> */}
          </div>
        </div>
        <div className='flex w-full justify-end border-t py-2 pr-6 text-center dark:border-slate-500 dark:bg-slate-800'>
          <button
            type='submit'
            className='text-md flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:bg-indigo-800 dark:text-slate-300'
            // onClick={() => updateUser()}
          >
            <span className='inline-flex items-center font-semibold dark:text-slate-200'>
              Confirm <FaChevronRight className='ml-1' size={15} />
            </span>
          </button>
        </div>
      </form>
    </>
  );
}
