import { IComposer } from '../../../../api/services/composer.service';

interface Props {
  composer: IComposer;
}

export default function Composer({ composer }: Props) {
  return (
    <div className='flex w-full justify-between gap-x-3 rounded-lg border border-indigo-100 px-4 pt-3 pb-4 dark:text-gray-100'>
      <div className='w-3/5'>
        <div className='truncate text-lg font-semibold leading-none'>
          {composer.firstName} {composer.lastName}
        </div>
        <div className='flex'>
          <a
            href={`mailto:${composer.email}`}
            className='truncate underline transition-colors hover:text-slate-500 dark:hover:text-gray-300'
          >
            {composer.email}
          </a>
        </div>
        <div>
          PRO: <span className='font-semibold'>{composer.pro}</span>
        </div>
      </div>
      <div className='flex-grow'>
        <div>
          RoyaltyShare:{' '}
          <span className='font-semibold'>{composer.royaltyShare} %</span>
        </div>
        <div>
          IPI: <span className='font-semibold'>{composer.ipi}</span>
        </div>
      </div>
    </div>
  );
}
