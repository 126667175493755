import { ITrack } from '../../../../api/services/track.service';
import { useTags } from '../../../../hooks/useTags';
import { useEffect, useMemo } from 'react';
import { ITag, ITagType } from '../../../../api/services/tag.service';
import { toast } from 'react-toastify';
import api from '../../../../api';
import { TagPicker } from './tagTab/TagPicker';

export function TagsTabWindow({
  nextTab,
  activeTrack,
  canBeModified,
}: {
  nextTab: () => void;
  activeTrack: ITrack;
  canBeModified: boolean;
}) {
  const {
    activeTagType,
    setActiveTagType,
    activeTags,
    setActiveTags,
    allTags,
    selectableTagTypes,
  } = useTags();

  useEffect(() => {
    if (activeTrack && activeTrack.id) {
      api.tag.getTagsByObject(activeTrack.id).then((res) => {
        setActiveTags(res.data.result);
      });
    }
    // eslint-disable-next-line
  }, [activeTrack]);

  async function removeTagFromTrack(tagId: string) {
    try {
      await api.tag.removeTagFromObject(tagId, activeTrack.id);
    } catch (error) {
      console.error(error);
    }
  }

  async function addTagToTrack(tagId: string) {
    try {
      await api.tag.addTagToObject(tagId, 'track', activeTrack.id);
    } catch (error) {
      console.error(error);
    }
  }

  const curActivaTags = useMemo(
    () => activeTags.filter((tag) => tag.type === activeTagType?.name),
    [activeTagType, activeTags]
  );

  const curTypeTags = useMemo(
    () => allTags.filter((tag) => tag.type === activeTagType?.name),
    [activeTagType, allTags]
  );

  function handleTagSelect(tag: ITag) {
    if (!canBeModified) {
      return;
    }
    let index = activeTags.findIndex((tagObj) => tagObj.name === tag.name);
    if (index !== -1) {
      setActiveTags((p) => p.filter((x) => x.name !== tag.name));
      removeTagFromTrack(tag.id);
    } else {
      if (activeTagType && curActivaTags.length < activeTagType.tagsMax) {
        setActiveTags([...activeTags, tag]);
        addTagToTrack(tag.id);
      } else {
        toast.error('Tags Tag limit reached');
      }
    }
  }

  function showTagCount(tagType: ITagType) {
    const activeTagsForTypes = activeTags.filter(
      (tag) => tag.type === tagType.name
    );
    const isFull = activeTagsForTypes.length >= tagType.tagsMax;
    const isEnough = activeTagsForTypes.length >= tagType.tagsMin;
    return (
      <div
        className={`text-md ml-1 select-none ${
          isFull
            ? 'text-emerald-500'
            : isEnough
              ? 'text-yellow-500'
              : 'text-red-500'
        } `}
      >
        ( {activeTags.filter((tag) => tag.type === tagType.name).length} /{' '}
        {tagType.tagsMax} )
      </div>
    );
  }

  return (
    <div className='flex flex-grow flex-col pb-10' id='tags-window'>
      {activeTagType && (
        <>
          <div className='grid min-h-[88px] w-full grid-cols-6 gap-3'>
            {selectableTagTypes.map((tagType, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    setActiveTagType(tagType);
                  }}
                  className={`animate col-span-1 row-span-1 my-1 flex cursor-pointer items-center rounded-md border-2 border-gray-200 transition-all duration-1000 dark:border-gray-600 dark:hover:bg-slate-600 ${
                    activeTagType.name === tagType.name
                      ? 'dark:bg-slate-600'
                      : ''
                  }`}
                >
                  <div className='flex w-full flex-col items-center justify-center px-1 '>
                    <div className='text-md  font-semibold text-gray-600 dark:text-gray-300'>
                      {tagType.displayName}
                    </div>
                    {showTagCount(tagType)}
                  </div>
                </div>
              );
            })}
          </div>
          <TagPicker
            tagType={activeTagType}
            activeTags={curActivaTags}
            allTags={curTypeTags}
            handleTagSelect={handleTagSelect}
          />
        </>
      )}
    </div>
  );
}
