import { useState } from 'react';

export default function useDialog() {
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  const openDialog = () => {
    setIsDialogOpened(true);
  };

  const closeDialog = () => {
    setIsDialogOpened(false);
  };

  return {
    isDialogOpened,
    openDialog,
    closeDialog,
  };
}
