import { useEffect, useState } from 'react';
import {
  FaFileAudio,
  FaInfoCircle,
  FaTag,
  FaUserFriends,
} from 'react-icons/fa';
import api from '../../../api';
import { ITag } from '../../../api/services/tag.service';
import { ITrack } from '../../../api/services/track.service';
import { useAuth } from '../../../hooks/useAuth';
import { TabBar } from '../../shared/TabBar';
import { TagsTabWindow } from './trackInfo/TagsTabWindow';
import { useStudioState } from '../studioState';
import { GeneralTabWindow } from './trackInfo/GeneralTabWindow';
import { FilesTabProvider } from './trackInfo/fileTab/files-tab-context';
import { ComposerTabWindow } from './trackInfo/ComposerTabWindow';
import { FileTabWindow } from './trackInfo/FileTabWindow';

const tabs = [
  {
    name: 'Files',
    icon: <FaFileAudio size={16} />,
  },
  {
    name: 'General',
    icon: <FaInfoCircle size={16} />,
  },
  {
    name: 'Composer',
    icon: <FaUserFriends size={20} />,
  },
  {
    name: 'Tags',
    icon: <FaTag size={16} />,
  },
];

export function TrackInfo() {
  const { user, activeTeam } = useAuth();

  const [activeTags, setActiveTags] = useState<ITag[]>([]);
  const [activeTab, setActiveTab] = useState<number>(0);

  const {
    activeProject,
    activeTrack,
    activeFiles,
    refreshData,
    updateTrackState,
    setTrackUpdateLoading,
    canBeModified,
    isTrackValidationErrorsShown,
    isTourActive,
    activeProjectRole,
    activeContracts,
  } = useStudioState();

  useEffect(() => {
    if (activeTrack) {
      fetchTags(activeTrack?.id);
    }
  }, [activeTrack]);

  useEffect(() => {
    if (activeTab === 1) {
      refreshData('activeTrack');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  async function fetchTags(trackId: string) {
    try {
      const { data } = await api.tag.getTagsByObject(trackId);
      if (data.result) {
        setActiveTags(data.result);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const updateTrack = async (id: string, trackData: Partial<ITrack>) => {
    try {
      setTrackUpdateLoading(true);
      const response = await api.track.updateTrackPartially(id, trackData);
      // toast.success("Track updated successfully");
      updateTrackState(id, response.data.result);
      setTrackUpdateLoading(false);
    } catch (error) {
      setTrackUpdateLoading(false);
      throw error;
    }
  };

  return (
    <FilesTabProvider>
      <div className='flex w-10/12 flex-col overflow-hidden rounded-2xl bg-gray-50 shadow-xl dark:bg-gray-800'>
        <TabBar tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
        {activeProject && activeTrack !== null && activeTeam && (
          <>
            <div className='flex w-full flex-grow justify-center'>
              <div className='justify- flex w-full flex-grow flex-col items-center'>
                <div className='flex w-full flex-grow bg-white p-4 dark:bg-slate-900 2xl:p-6'>
                  {activeTab === 0 && (
                    <FileTabWindow
                      refreshData={refreshData}
                      project={activeProject}
                      activeTrack={activeTrack}
                      files={activeFiles}
                      canBeModified={canBeModified}
                      activeProjectRole={activeProjectRole}
                    />
                  )}
                  {activeTab === 1 && (
                    <GeneralTabWindow
                      isTrackValidationErrorsShown={
                        isTrackValidationErrorsShown
                      }
                      activeTags={activeTags}
                      activeTrack={activeTrack}
                      updateTrack={updateTrack}
                      canBeModified={canBeModified}
                    />
                  )}
                  {activeTab === 2 && (
                    <ComposerTabWindow
                      isTourActive={isTourActive}
                      user={user}
                      // nextTab={() => setActiveTab(activeTab + 1)}
                      submission={activeProject}
                      activeTrack={activeTrack}
                      canBeModified={canBeModified}
                      refreshData={refreshData}
                      contracts={activeContracts.filter((c) => !!c.composerId)}
                    />
                  )}
                  {activeTab === 3 && (
                    <TagsTabWindow
                      nextTab={() => setActiveTab(activeTab + 1)}
                      activeTrack={activeTrack}
                      canBeModified={canBeModified}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </FilesTabProvider>
  );
}
