import { useEffect, useState } from 'react';
import api from '../api';
import { useAuth } from '../hooks/useAuth';
import { ITeamMembership } from '../api/services/team.service';
import { FaUserCircle, FaUserNinja } from 'react-icons/fa';

import stringToColor from 'string-to-color';
import { useNavigate } from 'react-router-dom';
import { FEATURES } from '../config/features';

export default function Impersonate() {
  const { setAccessToken, setRefreshToken, isFeatureActive } = useAuth();

  let navigate = useNavigate();

  const [members, setMembers] = useState<ITeamMembership[]>([]);

  const { activeTeam, user } = useAuth();

  useEffect(() => {
    if (!activeTeam) return;
    api.team.getTeamMembers(activeTeam.id).then((response) => {
      setMembers(response.data.result);
    });
  }, [activeTeam]);

  return (
    <div className='flex grow flex-col items-center'>
      {user && isFeatureActive(FEATURES.KTR_IMPERSONATION) && (
        <div className='rounded-md border-b border-gray-400 bg-white p-10 pb-5'>
          <h3 className='text-base font-semibold leading-6 text-gray-900'>
            Label user
          </h3>
          <p className='mt-2 max-w-4xl text-sm text-gray-500'>
            All label artists, team members and owners are listed here.
          </p>
          <div className='mt-8 w-full overflow-hidden rounded-lg bg-gray-100 py-8 ring-1 ring-slate-900/10'>
            <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
              <ul className=' grid grid-cols-1 gap-6  sm:grid-cols-2 lg:grid-cols-3'>
                {members.map((member) => (
                  <li
                    key={member.user.email}
                    className='col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow'
                  >
                    <div className='flex w-full items-center justify-between space-x-6 p-6'>
                      <div className='flex-1 truncate'>
                        <div className='flex items-center space-x-3'>
                          <h3 className='truncate text-sm font-medium text-gray-900'>
                            {member.user.firstname}
                          </h3>
                          <span
                            className={`inline-flex flex-shrink-0 items-center rounded-full px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset ${
                              member.role === 'GUEST'
                                ? 'bg-green-50 text-green-700 ring-green-600/20'
                                : 'bg-red-50 text-red-700 ring-red-600/20'
                            }`}
                          >
                            {member.role}
                          </span>
                        </div>
                        <p className='mt-1 truncate text-sm text-gray-500'>
                          {member.user.email}
                        </p>
                      </div>
                      <FaUserCircle
                        className='h-10 w-10 flex-shrink-0 rounded-full '
                        color={member ? stringToColor(member.userId) : 'gray'}
                      />
                    </div>
                    <div>
                      <div className='-mt-px flex divide-x divide-gray-200'>
                        <div className='-ml-px flex w-0 flex-1'>
                          <div
                            className='relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900'
                            onClick={async () => {
                              const response = await api.auth.impersonate(
                                member.userId
                              );
                              if (
                                response.status === 200 &&
                                response.data.result?.access_token
                              ) {
                                setAccessToken(
                                  response.data.result.access_token
                                );
                                setRefreshToken(
                                  response.data.result.refresh_token
                                );
                                navigate('/login');
                              }
                            }}
                          >
                            <FaUserNinja
                              className='h-5 w-5 text-gray-400'
                              aria-hidden='true'
                            />
                            Impersonate
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
