import { IResponse } from '..';
import client from '../client';

interface ICheckoutSession {
  url: string;
}

async function createCheckoutSession(priceId: string) {
  return client.post<IResponse<ICheckoutSession>>(`/payment/checkout`, {
    priceId,
  });
}

async function createCustomerPortalSession() {
  return client.post<IResponse<ICheckoutSession>>(`/payment/portal`);
}

const api = {
  createCheckoutSession,
  createCustomerPortalSession,
};

export default api;
