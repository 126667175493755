import { FaFileImport } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { IProject } from '../../api/services/project.service';
import StatusBadge from '../shared/StatusBadge';

export default function SubmissionCard({ project }: { project: IProject }) {
  const navigate = useNavigate();

  return (
    <div
      className='w-full cursor-pointer rounded-lg
                bg-indigo-600 
                 p-4 hover:bg-indigo-700'
      onClick={() => navigate(`/submission/${project.id}`)}
    >
      <div className='flex items-center justify-between '>
        <div className='flex items-center font-bold text-slate-50 '>
          <FaFileImport size={25} />
          <span className='ml-2 break-words'>{project.name}</span>
        </div>
        <StatusBadge status={project.status} testid='submission' />
        {/* TODO: Unseen Notifications badge here? */}
      </div>
    </div>
  );
}
