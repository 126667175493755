import { PropsWithChildren } from 'react';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
interface Props {
  rootProps: DropzoneRootProps;
  inputProps: DropzoneInputProps;
  testid?: string;
}

export const DropzoneWrapper = ({
  rootProps,
  inputProps,
  children,
  testid,
}: PropsWithChildren<Props>) => {
  return (
    <div className='flex flex-grow cursor-default' {...rootProps}>
      <input {...inputProps} data-testid={testid} />
      {children}
    </div>
  );
};
