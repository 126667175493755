export const globalThemeStyles: {
  headerNav: string;
  container: string;
  box: string;
  hoverItem: string;
  inputbox: string;
} = {
  headerNav: 'bg-white dark:bg-slate-800 text-slate-700 dark:text-white',
  container:
    'bg-slate-200 dark:bg-slate-700 text-slate-700 dark:text-slate-200',
  box: 'bg-white dark:bg-slate-900 text-slate-700 dark:text-slate-100',
  hoverItem: 'hover:bg-slate-200 dark:hover:bg-slate-700',
  inputbox:
    'bg-gray-50 dark:bg-slate-800 text-slate-700 dark:text-slate-200 border-gray-300 dark:border-gray-600 disabled:bg-slate-200 dark:disabled:bg-slate-600 focus:border-indigo-500 focus:ring-indigo-500 rounded-md',
};
