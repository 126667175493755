import axios from 'axios';
import { useState } from 'react';
import { FaFolderPlus, FaSpinner } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../api';
import { IProject } from '../../api/services/project.service';
import { useAuth } from '../../hooks/useAuth';
import { Card } from '../shared/Card';
import TextEditDialog from '../shared/dialog/TextEditDialog';
import { SearchInput } from '../shared/form/SearchInput';
import { useSearch } from '../../hooks/useSearch';
import SubmissionCard from './SubmissionCard';

interface Props {
  className?: string;
  projects: IProject[] | undefined;
  loading: boolean;
  artistUserId?: string;
}

export default function SubmissionSection({
  className,
  projects,
  loading,
  artistUserId,
}: Props) {
  let navigate = useNavigate();
  const { activeTeam } = useAuth();

  const {
    onSearchChange,
    found: foundProjects,
    searchString,
  } = useSearch<IProject>({
    data: projects || [],
    filterFn: (v: string) => (p) => p.name.toLowerCase().includes(v),
  });

  const [isProjectAddDialogOpen, setIsProjectAddDialogOpen] = useState(false);

  async function addProject({ name }: { name: string }) {
    try {
      const { data } = await api.project.addProject({
        name,
        teamId: activeTeam?.id || '',
        type: 'SUBMISSION',
      });
      if (data.result) {
        if (artistUserId) {
          await api.project.inviteProjectMember(data.result.id, {
            userId: artistUserId,
            role: 'GUEST',
            teamId: activeTeam?.id,
          });
        }
        navigate(`/submission/${data.result.id}`);
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  const renderContent = () => {
    if (loading) {
      return (
        <div className='flex items-center justify-center p-20'>
          <FaSpinner size={30} className='animate-spin' />
        </div>
      );
    }

    if (!projects) return null;

    if (projects.length === 0) {
      return (
        <div className='flex flex-col items-center justify-center p-8'>
          <div className='mb-2 pt-52 font-semibold'>No Submissions yet</div>
          {activeTeam && activeTeam.teamMembershipDTO?.role !== 'GUEST' && (
            <div className='inline-flex items-center space-x-2'>
              <div>Create one via the</div>
              <FaFolderPlus
                size={24}
                className='cursor-pointer text-indigo-700'
                onClick={() => setIsProjectAddDialogOpen(true)}
              />
              <div>button on the top right</div>
            </div>
          )}
        </div>
      );
    }

    return (
      <div className='MiniScrollbar grid grid-cols-1 gap-8 overflow-auto p-4 lg:grid-cols-2 xl:grid-cols-3'>
        {foundProjects.map((project) => (
          <SubmissionCard key={project.id} project={project} />
        ))}
      </div>
    );
  };

  return (
    <Card
      testid='submission-section'
      headerChildren={
        <SearchInput onChange={onSearchChange} value={searchString} />
      }
      title='Submissions'
      className={className}
      onClickAdd={
        activeTeam && activeTeam.teamMembershipDTO?.role !== 'GUEST'
          ? () => {
              setIsProjectAddDialogOpen(true);
            }
          : undefined
      }
    >
      <TextEditDialog
        isOpen={isProjectAddDialogOpen}
        title={'New Submission'}
        initialValue={'New Submission'}
        onCancel={() => {
          setIsProjectAddDialogOpen(false);
        }}
        onSubmit={(newValue: string) => {
          addProject({ name: newValue });
          setIsProjectAddDialogOpen(false);
        }}
      />
      {renderContent()}
    </Card>
  );
}
