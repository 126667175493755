import { FaFolder, FaRecordVinyl, FaUserAstronaut } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { IProject } from '../../../api/services/project.service';

import StatsCard from './Stat';
import { IPaginatedResponse } from '../../../api';
import { TrackWithFiles } from '../../../api/services/track.service';
import { ITeamMembership } from '../../../api/services/team.service';
import { BiBulb } from 'react-icons/bi';

interface Props {
  projects: IProject[] | undefined;
  tracks: IPaginatedResponse<TrackWithFiles[]> | undefined;
  artists: ITeamMembership[] | undefined;
  artistUserId?: string;
}

export default function QuickStatsSection({
  projects,
  tracks,
  artists,
  artistUserId,
}: Props) {
  let navigate = useNavigate();

  const stats = [
    {
      id: 1,
      name: 'Open submissions',
      stat: projects?.filter((p) => p.status === 'REQUESTED').length.toString(),
      icon: FaFolder,
      // change: '4',
      changeType: 'increase',
      onClick: () => navigate('/submissions'),
      toolTip: 'Submissions that are waiting for your review',
    },
    {
      id: 2,
      name: 'Tracks in library',
      stat: tracks?.result?.totalElements,
      icon: FaRecordVinyl,
      // change: '13',
      changeType: 'increase',
      onClick: () => navigate('/tracks'),
      toolTip: 'Total number of tracks in your library',
    },
    {
      id: 3,
      name: 'Artists in team',
      stat: artists?.filter((a) => a.role === 'GUEST').length,
      icon: FaUserAstronaut,
      // change: '2',
      changeType: 'increase',
      onClick: () => navigate('/artists'),
      toolTip: 'Total number of artists in your team',
    },
  ];

  return (
    <div className='flex flex-col space-y-4'>
      <div className='flex items-center space-x-2'>
        <BiBulb className='text-2xl text-indigo-500' />
        <div className='text-lg font-semibold'>Quick Stats</div>
      </div>

      <dl className='grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 lg:gap-8 xl:gap-16'>
        {stats.map((item) => (
          <StatsCard key={item.id} stat={item} />
        ))}
      </dl>
    </div>
  );
}
