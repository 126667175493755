import { useLocation, useNavigate } from 'react-router-dom';
import { KTRLogo } from '../../components/shared/ktr';
import { useAuth } from '../../hooks/useAuth';

export default function Forbidden() {
  let navigate = useNavigate();
  let { user } = useAuth();
  let location = useLocation();

  return (
    <div className='mt-20 flex flex-grow'>
      <div className='flex w-full flex-col items-center'>
        <div className='h-40 w-40'>
          <KTRLogo />
        </div>
        <h2 className='mt-6 text-center font-mono text-3xl font-extrabold text-gray-900 dark:text-slate-200'>
          403 Forbidden
        </h2>
        <p className='mt-2 text-center text-sm text-gray-600 dark:text-slate-200'>
          Seems like you don't have the required permissions to access this
          project.
        </p>
        {!user && (
          <p className='mt-2 text-center text-sm text-gray-600 dark:text-slate-200'>
            If you have an existing account logging in might help.
          </p>
        )}
        <div className='mt-7 flex-col items-center'>
          {!user && (
            <button
              className='flex items-center rounded bg-indigo-600 px-4 py-1 font-semibold text-white hover:bg-indigo-800'
              onClick={() => navigate('/login', { state: { from: location } })}
            >
              <div>Login</div>
            </button>
          )}
          <button
            className='flex items-center rounded bg-indigo-600 px-4 py-1 font-semibold text-white hover:bg-indigo-800'
            onClick={() => navigate(-1)}
          >
            <div>Go Back</div>
          </button>
        </div>
      </div>
    </div>
  );
}
