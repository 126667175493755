import { errorWrapper, IResponse } from '..';
import client from '../client';
import tokenHandler from '../tokenHandler';

// SignUp
interface ISignUpRequest {
  email: string;
  password: string;
  firstname: string;
  lastname: string;
}

async function signUp(body: ISignUpRequest) {
  return client.post<IResponse<ITokenResponse>>('/auth/signup', body);
}

// Login variations
export interface ITokenResponse {
  access_token: string;
  refresh_token: string;
  userCreation: boolean;
  userId: string;
}

async function login(email: string, password: string) {
  return client.post<IResponse<ITokenResponse>>('/auth/login', {
    email,
    password,
  });
}

async function requestPwReset(email: string) {
  return client.post<IResponse<ITokenResponse>>('/auth/reset/init', {
    email,
  });
}

async function submitPwReset(token: string, password: string) {
  return client.post<IResponse<ITokenResponse>>(`/auth/reset/${token}`, {
    password,
  });
}

async function verifyPwResetToken(token: string) {
  return client.get<IResponse<ITokenResponse>>(`/auth/reset/${token}`);
}

async function impersonate(userId: string) {
  return client.post<IResponse<ITokenResponse>>(`/auth/impersonate/${userId}`);
}

async function loginToken(token: string) {
  return client.post<IResponse<ITokenResponse>>('/auth/login/token', {
    token,
  });
}

async function oAuth(provider: string, code: string) {
  return client.post<IResponse<ITokenResponse>>(
    `/auth/oauth/callback?provider=${provider}&code=${code}`
  );
}

// OAuth url
interface IUrlResponse {
  targetUrl: string;
}

// TODO: fix
async function getOAuthURL(provider: string, target: string | null) {
  return client.get<IResponse<IUrlResponse>>(
    `/auth/oauth/url?provider=${provider}${target ? `&target=${target}` : ''}`
  );
}

async function getSSORedirectUrl(provider: string, target: string | null) {
  return client.get<IResponse<IUrlResponse>>(
    `/auth/oauth/redirectUrl/${target}?provider=${provider}`
  );
}

// Logout
async function logout() {
  return client.post(`/auth/logout`, {
    refresh_token: tokenHandler.getRefreshToken(),
  });
}

const api = {
  login: (email: string, password: string) =>
    errorWrapper(
      login,
      {
        'K-SAAS-0006':
          'Either the user you provided was not found or the password did not match',
        'SAAS-AUTH-0002':
          'It seems like this email is only valid for a different login method! You might want to try logging in via Google, O365, Apple or password',
        'SAAS-AUTH-0003':
          "Something didn't quite work out on our end and its currently not possible for you to login using this method",
        'SAAS-AUTH-0013':
          "A user with this email address already exists. If that's your account, you might want to try logging in",
      },
      email,
      password
    ),
  loginToken,
  signUp: (body: ISignUpRequest) =>
    errorWrapper(
      signUp,
      {
        'SAAS-AUTH-0002':
          'It seems like this email is only valid for a different login method! You might want to try logging in via Google, O365, Apple or password',
        'SAAS-AUTH-0003':
          "Something didn't quite work out on our end and its currently not possible for you to login using this method",
        'SAAS-AUTH-0013':
          "A user with this email address already exists. If that's your account, you might want to try logging in",
      },
      body
    ),
  logout,
  oAuth,
  getOAuthURL,
  requestPwReset,
  verifyPwResetToken,
  submitPwReset,
  getSSORedirectUrl,
  impersonate,
  // refresh
};

export default api;
