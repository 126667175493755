import { FaSpinner } from 'react-icons/fa';
interface Props {
  className?: string;
}

export default function Loader({ className = '' }: Props) {
  return (
    <div
      className={`fixed inset-0 z-50 flex flex-col items-center justify-center bg-black bg-opacity-25 text-slate-800 dark:text-indigo-700 ${className}`}
    >
      <FaSpinner size={30} className='animate-spin' />
    </div>
  );
}
