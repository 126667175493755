export enum RoutesEnum {
  Dashboard = '/',
  Submissions = '/submissions',
  Albums = '/albums',
  Tracks = '/tracks',
  Artists = '/artists',
  Composers = '/composers',
  Contracts = '/contracts',
  TeamSettings = '/team',
  Members = '/members',
}
