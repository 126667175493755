import { IResponse } from '..';
import client from '../client';

export interface IBanner {
  id: string;
  message: string;
  validUntil: Date;
  type: 'info' | 'warning';
}

async function getBanners() {
  return client.get<IResponse<IBanner[]>>(`/banner`);
}

const api = {
  getBanners,
};

export default api;
