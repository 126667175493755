import axios from 'axios';
import { KeyboardEvent, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../api';
import { KTRLogo } from '../../components/shared/ktr';
import { useAuth } from '../../hooks/useAuth';

export default function PasswordReset() {
  const { isAuthenticated } = useAuth();
  let navigate = useNavigate();

  const [email, setEmail] = useState('');

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/', { replace: true });
    }
  });

  async function requestPwReset() {
    try {
      const success = await api.auth.requestPwReset(email);
      if (success) {
        toast.success(`We sent a password reset email to ${email}`);
        setEmail('');
      } else {
        toast.error('Error on password reset');
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  function handleEnter(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      requestPwReset();
    }
  }

  return (
    <div className='mt-20 flex flex-grow'>
      <div className='flex w-full flex-col items-center'>
        <div className='flex flex-col items-center'>
          <div className='h-40 w-40'>
            <KTRLogo />
          </div>
          <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
            Reset your password
          </h2>
        </div>
        <div className='mt-8 w-full max-w-md rounded-md bg-white px-8 py-8 pt-1 shadow'>
          <div className='mt-6 space-y-5 rounded-md shadow-sm'>
            <p className='mt-2 text-sm text-gray-600'>
              Enter the email address you used to sign up and we'll send a
              password reset email with further instructions.
            </p>
            <div>
              <label
                htmlFor='email-address'
                className='block text-sm font-medium text-gray-700'
              >
                Email address
              </label>
              <div className='mt-1'>
                <input
                  id='email-address'
                  name='email'
                  type='email'
                  autoComplete='email'
                  required
                  className='w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  placeholder=''
                  onChange={(email) => setEmail(email.target.value)}
                  defaultValue={email}
                  value={email}
                  onKeyDown={handleEnter}
                />
              </div>
            </div>
          </div>
          <div className='mt-6'>
            <button
              type='submit'
              className='group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
              onClick={() => requestPwReset()}
            >
              Send Reset
            </button>
          </div>
          <div className='mt-6 flex justify-between'>
            <div className=''>
              <p className='text-slate-700'>Don't have an account?</p>
            </div>
            <Link
              className='font-medium text-indigo-600 hover:text-indigo-500'
              to='/signUp'
            >
              Sign up
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
