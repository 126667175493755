import axios from 'axios';
import { errorWrapper } from '.';
import { BACKEND_BASE_URL } from '../config/config';
import tokenHandler from './tokenHandler';

const instance = axios.create({
  baseURL: localStorage.getItem('backend') || BACKEND_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// provide token with every request
instance.interceptors.request.use(
  (config) => {
    const token = tokenHandler.getAccessToken();
    const tenant = tokenHandler.getLastActiveTeam();
    if (tenant && config.headers) {
      config.headers['X-TENANT'] = tenant;
    }
    if (token && config.headers) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// refresh access token if expired
instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    // console.log(originalConfig.url);
    // exclude public accessible urls like "/auth/login"
    if (
      originalConfig.url !== '/auth/refresh' &&
      originalConfig.url !== '/auth/login' &&
      originalConfig.url !== '/auth/logout' &&
      err.response
    ) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const { data } = await errorWrapper(
            instance.post,
            {
              'K-SAAS-0006': 'Your session timed out! Please login again',
            },
            '/auth/refresh',
            {
              refreshToken: tokenHandler.getRefreshToken(),
            }
          );
          tokenHandler.updateAccessToken(data.result.access_token);
          return instance(originalConfig);
        } catch (error) {
          // tokenHandler.deleteTokens();
          return Promise.reject(error);
        }
      }
    }
    return Promise.reject(err);
  }
);

export default instance;
