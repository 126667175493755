import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  DropzoneInputProps,
  DropzoneRootProps,
  useDropzone,
} from 'react-dropzone';
import { toast } from 'react-toastify';
import { IFile } from '../../api/services/file.service';
import { ITrack } from '../../api/services/track.service';
import { isContentTypeAllowed, isFileExtensionAllowed } from '../../utils';

interface InternalStateProps {
  openFileDialog: () => void;
  isDragActive: boolean;
  acceptedFiles: File[];
  setUploadCompleted: (id: string) => void;
  setAcceptFileTypes: (types: IAcceptFileTypes) => void;
  acceptFileTypes: IAcceptFileTypes;
  activeFileRole: String;
  setActiveFileRole: (role: String) => void;
  rootProps: DropzoneRootProps;
  inputProps: DropzoneInputProps;
  setAcceptedFilesWrapper: React.Dispatch<React.SetStateAction<File[]>>;
}

export interface IAcceptFileTypes {
  [key: string]: string[];
}

export interface IUploadFile extends IFile {
  progress?: number;
  track: ITrack;
}

const DEFAULT_ACCEPT_FILE_TYPES: IAcceptFileTypes = {
  // 'audio/*': ['.wav'],
  'audio/wav': ['.wav'],
  'audio/x-wav': ['.wav'],
};

export default function StudioDropzoneWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  const countRef = useRef(0);
  // ---------- START: handle upload and completion ----------
  const [acceptedFilesWrapper, setAcceptedFilesWrapper] = useState<File[]>([]);
  const [activeFileRole, setActiveFileRole] = useState<String>('MAIN');
  const [filesInUpload, setFilesInUpload] = useState<string[]>([]);

  useEffect(() => {
    if (acceptedFilesWrapper.length > 0) {
      console.log('setting files in upload');
      const files = [
        ...filesInUpload,
        ...acceptedFilesWrapper.map((file) => `${file.name}+${file.size}`),
      ];
      countRef.current = files.length;
      setFilesInUpload(files);
    }
    // eslint-disable-next-line
  }, [acceptedFilesWrapper]);

  // prevent upload cancel
  useEffect(() => {
    if (filesInUpload.length > 0) {
      console.log('setting lock', filesInUpload.length);
      window.onbeforeunload = () => true;
    } else {
      console.log('lifting lock');
      window.onbeforeunload = null;
      if (countRef.current > 0) {
        toast.success(
          countRef.current > 1
            ? `Uploaded ${countRef.current} files successfully`
            : 'File uploaded'
        );
      }
      countRef.current = 0;
      setAcceptedFilesWrapper([]);
      setActiveFileRole('MAIN');
    }
  }, [filesInUpload]);

  const [completedFile, setUploadCompleted] = useState<string | null>(null);

  useEffect(() => {
    if (completedFile !== null) {
      setFilesInUpload(filesInUpload.filter((file) => file !== completedFile));
      setUploadCompleted(null);
    }
  }, [filesInUpload, completedFile]);
  // ---------- END: handle upload and completion ----------

  // ---------- START: handle accept file type change ----------
  // control which file types can be uploaded in the dropzone
  const [acceptFileTypes, setAcceptFileTypes] = useState<IAcceptFileTypes>(
    DEFAULT_ACCEPT_FILE_TYPES
  );

  /**
   * Compares two arrays for equality, element by element.
   *
   * @template T - The type of elements in the arrays.
   * @param {T[]} arr1 - The first array.
   * @param {T[]} arr2 - The second array.
   * @returns {boolean} True if the arrays are equal, false otherwise.
   */
  function arraysAreEqual<T>(arr1: T[], arr2: T[]): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }
    return arr1.every((element, index) => element === arr2[index]);
  }

  // reset to wav audio files when upload is cancelled
  const wasJustCancelled = useRef(false);
  const onFileDialogCancel = useCallback(() => {
    // hardcoded check for audio, needed?
    if (
      !arraysAreEqual(
        Object.keys(acceptFileTypes),
        Object.keys(DEFAULT_ACCEPT_FILE_TYPES)
      )
    ) {
      console.log('resetting acceptFileTypes');
      wasJustCancelled.current = true;
      setAcceptFileTypes(DEFAULT_ACCEPT_FILE_TYPES);
      setActiveFileRole('MAIN');
    }
  }, [acceptFileTypes]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (acceptedFilesWrapper.length === 0) {
      // don't open a new file dialog if it was just cancelled
      if (!wasJustCancelled.current) {
        console.log('acceptFileTypes', acceptFileTypes);
        open();
      } else {
        wasJustCancelled.current = false;
      }
    }
    // eslint-disable-next-line
  }, [acceptFileTypes]);

  useEffect(() => {
    if (
      acceptedFilesWrapper.length > 0 &&
      !arraysAreEqual(
        Object.keys(acceptFileTypes),
        Object.keys(DEFAULT_ACCEPT_FILE_TYPES)
      )
    ) {
      console.log('resetting acceptFileTypes');
      setAcceptFileTypes(DEFAULT_ACCEPT_FILE_TYPES);
    }
  }, [acceptedFilesWrapper, acceptFileTypes]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const acceptedFilesWithCorrectType = acceptedFiles.filter((file) =>
        Object.keys(acceptFileTypes).some(
          (key) =>
            isContentTypeAllowed(key, file.type) &&
            isFileExtensionAllowed(acceptFileTypes[key], file.name)
        )
      );
      if (acceptedFilesWithCorrectType.length === acceptedFiles.length) {
        console.log(acceptedFilesWithCorrectType);
        setAcceptedFilesWrapper([
          ...acceptedFilesWrapper,
          ...acceptedFilesWithCorrectType,
        ]);
      } else {
        const rejectedFiles = acceptedFiles.filter(
          (file) => !acceptedFilesWithCorrectType.includes(file)
        );
        const rejectedFilesString = rejectedFiles
          .map((file) => `${file.name} (${file.type})`)
          .join(', ');
        toast.error(
          `The following files were rejected because they are not of the allowed file type: ${rejectedFilesString}`
        );
      }
    },
    [acceptedFilesWrapper, acceptFileTypes]
  );

  // ---------- END: handle accept file type change ----------

  useEffect(() => {
    console.log('activeFileRole', activeFileRole);
  }, [activeFileRole]);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: acceptFileTypes,
    noClick: true,
    multiple: true,
    onFileDialogCancel,
    onDrop,
  });

  let value = {
    setFilesInUpload,
    openFileDialog: open,
    isDragActive,
    setUploadCompleted,
    acceptedFiles: acceptedFilesWrapper,
    setAcceptFileTypes,
    acceptFileTypes,
    activeFileRole,
    setActiveFileRole,
    setAcceptedFilesWrapper,
    rootProps: getRootProps(),
    inputProps: getInputProps(),
  };

  return (
    <DropzoneStateContext.Provider value={value}>
      <div className='flex flex-grow cursor-default'>{children}</div>
    </DropzoneStateContext.Provider>
  );
}

let DropzoneStateContext = React.createContext<InternalStateProps>(null!);

export function useDropzoneState() {
  return React.useContext(DropzoneStateContext);
}
