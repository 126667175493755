import { Combobox, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { FaChevronDown, FaUserCircle } from 'react-icons/fa';
import stringToColor from 'string-to-color';
import { IUserShort } from '../../../api/services/project.service';

export default function UserCombobox({
  teamMembers,
  selected,
  setSelected,
}: {
  teamMembers: IUserShort[];
  selected: IUserShort | null;
  setSelected: (user: IUserShort) => void;
}) {
  const [query, setQuery] = useState('');

  const filteredPeople =
    query === ''
      ? teamMembers
      : teamMembers.filter((person) =>
          (person.firstname + person.lastname)
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        );

  return (
    <Combobox value={selected} onChange={setSelected} nullable>
      <div className='relative'>
        <div className='relative w-full cursor-default overflow-hidden rounded-lg border bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 dark:border-gray-600 dark:bg-slate-800 sm:text-sm'>
          <Combobox.Input
            className={`w-full border-none bg-white py-2 pl-3 pr-10 text-sm leading-5 focus:ring-0 dark:bg-slate-800 dark:text-gray-200`}
            placeholder='Invite Email or name of user'
            displayValue={(person: IUserShort) => {
              if (person) {
                if (person.id) {
                  return `${person?.firstname} ${person?.lastname}`;
                }
                return `Invite ${person.email}`;
              }
              return '';
            }}
            onChange={(event) => setQuery(event.target.value)}
          />
          <Combobox.Button
            className='absolute inset-y-0 right-0 flex items-center pr-2'
            data-testid='user-combobox-btn'
          >
            <FaChevronDown
              className='h-5 w-5 text-gray-400'
              aria-hidden='true'
            />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
          afterLeave={() => setQuery('')}
        >
          <Combobox.Options className='absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-slate-900 sm:text-sm'>
            {filteredPeople.length === 0 && query.indexOf('@') > -1 && (
              <Combobox.Option
                key={'1'}
                disabled={
                  query.match(
                    /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
                  ) === null
                }
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-4 pr-4 ${
                    active
                      ? 'bg-indigo-600 text-white'
                      : 'text-gray-900 dark:text-gray-100'
                  }`
                }
                value={(): { email: string; id: null } => {
                  const matches = query.match(
                    /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
                  );
                  return {
                    email: matches ? matches[0] : '',
                    id: null,
                  };
                }}
              >
                <div className='flex items-center'>
                  <FaUserCircle
                    className='h-8 w-8 rounded-full bg-white'
                    color={stringToColor('123')}
                  />
                  <span
                    className={`ml-2 block truncate ${
                      selected ? 'font-semibold' : 'font-medium'
                    }`}
                  >
                    Invite <span className='font-semibold'>{query}</span>
                  </span>
                </div>
              </Combobox.Option>
            )}
            {filteredPeople.length === 0 && query.indexOf('@') === -1 && (
              <div className='relative cursor-default select-none py-2 px-4 text-gray-700 dark:text-gray-200'>
                Nothing found.
              </div>
            )}
            {filteredPeople.length > 0 &&
              filteredPeople.map((person) => (
                <Combobox.Option
                  key={person.id}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-4 pr-4 ${
                      active
                        ? 'bg-indigo-600 text-white'
                        : 'text-gray-900 dark:text-gray-100'
                    }`
                  }
                  value={person}
                >
                  {({ selected, active }) => (
                    <div className='flex items-center'>
                      <FaUserCircle
                        className='h-8 w-8 rounded-full bg-white'
                        color={stringToColor(person.id)}
                      />
                      <span
                        className={`ml-2 block truncate ${
                          selected ? 'font-semibold' : 'font-medium'
                        }`}
                      >
                        {person.firstname} {person.lastname}
                      </span>
                    </div>
                  )}
                </Combobox.Option>
              ))}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
}
