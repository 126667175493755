import axios from 'axios';
import { IResponse } from '..';

const instance = axios.create({
  baseURL: 'https://ingest.ktr.studio',
  headers: {
    'Content-Type': 'application/json',
  },
});

async function ingest(target: string, body: any) {
  return instance.post<IResponse<String>>(`/`, {
    datetime: new Date(),
    target,
    body,
  });
}

const api = {
  ingest,
};

export default api;
