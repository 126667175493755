import { PropsWithChildren, useState } from 'react';
import { classNames } from '../utils';
import { globalThemeStyles } from '../config/globalStyles';

interface Props {
  trigger: JSX.Element;
  testid?: string;
}

export function HoverDropdown({
  children,
  trigger,
  testid = '',
}: PropsWithChildren<Props>) {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <div
      data-testid={testid}
      className='relative z-10'
      onMouseEnter={() => {
        setIsOpened(true);
      }}
      onMouseLeave={() => {
        setIsOpened(false);
      }}
    >
      <button>{trigger}</button>
      {isOpened && (
        <div className='absolute animate-fadeIn pt-2 opacity-0'>
          <div
            onClick={() => {
              setIsOpened(false);
            }}
            className={classNames(
              'min-w-[192px] whitespace-nowrap rounded-md py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
              globalThemeStyles.box
            )}
          >
            {children}
          </div>
        </div>
      )}
    </div>
  );
}
