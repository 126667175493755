import { AxiosResponse } from 'axios';
import client from '../client';

export interface AIInput {
  title: string;
  tags: string;
  jobID: string;
  jobStarted: string;
}

export interface DescriptionResponse {
  result: {
    body: string;
  };
}

async function getDescription(input: AIInput) {
  const res = await client.post<AIInput, AxiosResponse<DescriptionResponse>>(
    '/ai',
    input
  );
  return res.data;
}

const api = {
  getDescription,
};

export default api;
