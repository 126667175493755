import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { FaCompactDisc } from 'react-icons/fa';
import { globalThemeStyles } from '../../../config/globalStyles';
import { useAuth } from '../../../hooks/useAuth';
import { classNames } from '../../../utils';
import api from '../../../api';

export function TenantSelector() {
  const { teams, activeTeam, setActiveTeam, user } = useAuth();

  return (
    <Menu as='div' className='relative mr-4 flex-shrink-0'>
      <div>
        <Menu.Button className='flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'>
          <span className='sr-only'>Open tenant menu</span>
          {teams && teams.length > 1 && <FaCompactDisc className='h-8 w-8' />}
          {activeTeam && (
            <div className='ml-2 font-semibold'>{activeTeam.name}</div>
          )}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items
          className={classNames(
            'absolute left-0 mt-2 w-48 origin-top-left rounded-md py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
            globalThemeStyles.box
          )}
        >
          {teams.map((team, index) => (
            <Menu.Item key={index}>
              {({ active }) => (
                <div
                  onClick={() => {
                    setActiveTeam(team.id);
                    if (!user) return;
                    api.user
                      .updateUser(user?.id, {
                        metadata: {
                          ...(user.metadata || {}),
                          lastActiveTeamId: team.id,
                        },
                      })
                      .then(() => {})
                      .catch(console.error);
                  }}
                  className={classNames(
                    globalThemeStyles.hoverItem,
                    'flex items-center px-4 py-2 text-sm'
                  )}
                >
                  {team.name}
                </div>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
