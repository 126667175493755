import axios from 'axios';
import { useEffect, useMemo, useRef, useState } from 'react';
import { BiAlbum } from 'react-icons/bi';
// import { FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import api from '../../../../api';
import { IFile } from '../../../../api/services/file.service';
import useDialog from '../../../../hooks/useDialog';
import DeleteConfirmationDialog from '../../../shared/dialog/DeleteConfirmationDialog';
import { useAlbumStudioState } from '../../albumState';
import { FaUpload } from 'react-icons/fa';
import { HiOutlinePencilSquare } from 'react-icons/hi2';
import { classNames } from '../../../../utils';

export default function ArtworkUploader() {
  const { activeAlbum, activeFiles, fetchAlbumFiles } = useAlbumStudioState();
  const ref = useRef<HTMLInputElement>(null);
  const [url, setUrl] = useState<string | undefined>();
  const [isDeletingAlbum, setIsDeletingAlbum] = useState(false);
  const {
    isDialogOpened,
    // openDialog,
    closeDialog,
  } = useDialog();

  const albumId = activeAlbum!.id;

  const artworkFile = useMemo(() => {
    return activeFiles.find(
      (f) => f.parentId === albumId && f.role === 'ARTWORK'
    );
  }, [activeFiles, albumId]);

  const confirmDeleteImage = async () => {
    if (!artworkFile) return;
    setIsDeletingAlbum(true);
    await api.file.deleteFile(artworkFile.id);
    setUrl(undefined);
    await fetchAlbumFiles(activeAlbum!.id);
    closeDialog();
    setIsDeletingAlbum(false);
  };

  useEffect(() => {
    if (artworkFile) {
      api.file
        .getFilePresignedDownloadUrl(artworkFile.id)
        .then((res) => {
          setUrl(res.data.result.presignedUrl);
        })
        .catch(() => {
          toast.error("Can't get link for artwork");
        });
    }
  }, [artworkFile]);

  async function upload(file: File, url: string) {
    return await axios.put(url, file, {
      headers: {
        'Content-Type': file.type,
      },
    });
  }

  async function updateFile(file: IFile) {
    const { data: fileResponse } = await api.file.updateFilePartially(file.id, {
      status: 'READY',
    });
    return fileResponse.result;
  }

  return (
    <>
      <label className='relative block h-40 w-40 cursor-pointer items-center overflow-hidden rounded-lg border border-slate-300 text-center font-semibold text-white disabled:bg-slate-300 disabled:text-slate-200 dark:disabled:bg-slate-600 dark:disabled:text-slate-700'>
        <div
          className={classNames(
            'group absolute top-0 left-0 flex h-full w-full rounded-lg bg-[rgba(0,0,0,.3)] opacity-0 transition-colors hover:opacity-100',
            url ? 'bg-[rgba(0,0,0,.3)]' : ' bg-white dark:bg-slate-900'
          )}
        >
          {url ? (
            <HiOutlinePencilSquare className='m-auto h-20 w-20 scale-50 transition-all group-hover:scale-100' />
          ) : (
            <FaUpload className='m-auto h-20 w-20 scale-50 text-slate-900 transition-all group-hover:scale-100 dark:text-white' />
          )}
        </div>
        {url ? (
          <img src={url} alt='artwork' className='h-40 w-40 object-cover' />
        ) : (
          <BiAlbum className='h-40 w-40 text-slate-900 dark:text-white' />
        )}
        <input
          data-testid='artwork-input'
          multiple={false}
          ref={ref}
          className='hidden'
          type='file'
          accept='.jpg,.png'
          onChange={async (e) => {
            const file = e.target.files?.[0];

            if (ref.current) {
              ref.current.value = '';
            }

            if (file) {
              if (file.type && !file.type.startsWith('image/')) {
                toast.error('File is not an image.');
              } else {
                if (artworkFile) {
                  await api.file.deleteFile(artworkFile.id);
                }
                const {
                  data: { result: fileObj },
                } = await api.file.addFile({
                  name: file.name,
                  size: file.size,
                  contentType: file.type,
                  parentId: activeAlbum!.id,
                  tenantId: activeAlbum!.teamId,
                  role: 'ARTWORK',
                });
                const result = await api.file.getFilePresignedUploadUrl(
                  fileObj.id
                );
                const url = result.data.result?.presignedUrl;
                // blocks until file uploaded successfully
                await upload(file, url);
                // set file status to READY
                await updateFile(fileObj);

                fetchAlbumFiles(activeAlbum!.id);
              }
            }
          }}
        />
      </label>
      <DeleteConfirmationDialog
        isLoading={isDeletingAlbum}
        isOpen={isDialogOpened}
        title={`Delete Artwork`}
        message={
          <>
            Do you really want to delete{' '}
            <span className='font-semibold'>"Album artwork"</span> ?
          </>
        }
        close={closeDialog}
        onSubmit={confirmDeleteImage}
      />
    </>
  );
}
