import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import ai from './services/ai.service';
import album from './services/album.service';
import auth from './services/auth.service';
import banner from './services/banner.service';
import comment from './services/comment.service';
import composer from './services/composer.service';
import contract from './services/contract.service';
import feature from './services/feature.service';
import file from './services/file.service';
import ingest from './services/ingest.service';
import notification from './services/notification.service';
import payment from './services/payment.service';
import project from './services/project.service';
import subscription from './services/subscription.service';
import tag from './services/tag.service';
import team from './services/team.service';
import track from './services/track.service';
import user from './services/user.service';

export interface IResponse<T> {
  version: String;
  success: boolean;
  statusCode: number;
  errorCode?: String;
  errorMessage?: String;
  result: T; // in praxis?
}

export interface IValidationResult {
  fieldName: string;
  message: string;
  objectId: string;
}

export interface IValidationErrorResponse extends IResponse<any> {
  version: String;
  success: boolean;
  statusCode: number;
  errorCode?: String;
  errorMessage?: String;
  result: IValidationResult[];
}

export interface IPaginatedResponse<T> {
  version: String;
  success: boolean;
  statusCode: number;
  errorCode?: String;
  errorMessage?: String;
  result?: {
    content: T;
    empty: boolean;
    first: true;
    last: true;
    number: number;
    numberOfElements: number;
    pageable: {
      offset: number;
      pageNumber: number;
      pageSize: number;
      paged: boolean;
      sort: {
        empty: boolean;
        sorted: boolean;
        unsorted: boolean;
      };
      unpaged: boolean;
    };
    size: number;
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    totalElements: number;
    totalPages: number;
  };
}

export interface ISearchRequest {
  sort?: ISortCriteria[] | null;
  page?: number | null;
  size?: number | null;
  expand?: string[] | null;
  condition?: ISearchCondition | null;
}

export interface ISearchCondition {
  type: string;
  key?: string;
  value?: string;
  conditions?: ISearchCondition[] | null;
}

export interface ISortCriteria {
  key: string;
  direction?: 'asc' | 'desc';
}

export interface IViolation {
  fieldName: string;
  message: string;
}

const generalErrorCodeMap: { [key: string]: string } = {
  'K-SAAS-0001': 'Unexpected failure within the application',
  'K-SAAS-0006': 'Your session timed out! Please login again',
};

export async function errorWrapper2<Type>(
  action: () => Promise<Type>,
  errorCodeMap: {
    [key: string]: string;
    [key: number]: string;
  }
) {
  return action();
}

export async function errorWrapper<Type>(
  action: (...params: any[]) => Promise<Type>,
  errorCodeMap: {
    [key: string]: string;
    [key: number]: string;
  },
  ...params: any[]
) {
  try {
    const x: Type = await action(...params);
    return x;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data) {
        const errorCode = e.response.data.errorCode;
        const errorMessage = e.response.data.errorMessage;
        const statusCode = e.response.status;
        console.log(`API Error [${errorCode}]: ${errorMessage}`);
        console.log(e);
        if (errorCodeMap[errorCode]) {
          toast.error(errorCodeMap[errorCode]);
        } else if (generalErrorCodeMap[errorCode]) {
          toast.error(generalErrorCodeMap[errorCode]);
        } else if (statusCode === 400) {
          console.log(statusCode);
          let text = 'The following issues occurred:\n';
          const data: Array<IViolation> = e.response.data.result || [];
          const groupedViolations = _.groupBy(data, 'objectId');
          const objectKeys = Object.keys(groupedViolations);
          const objectKey = objectKeys[0];
          // show only violations of first obj
          toast.error(
            <div className='flex flex-col'>
              <div>{text}</div>
              <>
                {objectKey !== null && objectKey !== 'null' && (
                  <b>Track {objectKey}</b>
                )}
                <ul className='ml-5 list-disc'>
                  {groupedViolations[objectKey].map((violation) => (
                    <li>{violation.message}</li>
                  ))}
                </ul>
              </>
              {objectKeys.length > 1 && (
                <div>and {objectKeys.length - 1} other errors</div>
              )}
            </div>
          );
        }
      }
    } else {
      console.log(e);
      toast.error(
        'Something really went wrong, you might want to contact support!'
      );
    }
    throw e;
  }
}

const api = {
  auth,
  banner,
  user,
  team,
  file,
  project,
  album,
  comment,
  notification,
  subscription,
  payment,
  ingest,
  composer,
  tag,
  track,
  feature,
  ai,
  contract,
};

export default api;
