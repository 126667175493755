import { useNavigate } from 'react-router-dom';
import { KTRLogo } from '../../components/shared/ktr';
import { RELEASE_VERSION } from '../../config/config';

export default function MajorError({ error }: { error: Error }) {
  let navigate = useNavigate();

  return (
    <div className='mt-20 flex flex-grow'>
      <div className='flex w-full flex-col items-center'>
        <div className='h-40 w-40'>
          <KTRLogo />
        </div>
        <div className='mt-6 flex text-center font-mono text-3xl font-extrabold text-gray-900'>
          Error
        </div>
        <p className='mt-2 text-center text-sm text-gray-600'>
          Something did not go exactly as planned
        </p>
        <div className='mt-5'>
          <span
            className='cursor-pointer text-indigo-600'
            onClick={() => navigate(-1)}
          >
            Going back might help
          </span>
        </div>
        <pre className='mt-14 text-left font-mono text-sm text-gray-600'>
          {RELEASE_VERSION.indexOf('dev') > -1 && error.stack}
        </pre>
      </div>
    </div>
  );
}
