import { Float } from '@headlessui-float/react';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';
import { Fragment } from 'react';
import { FaDownload, FaEllipsisV, FaPen, FaTrash } from 'react-icons/fa';

export default function FileListOptionMenu({
  onRename,
  onDelete,
  onDownload,
  deleteOptionLabel = 'Delete',
}: {
  onRename: () => void;
  onDelete: () => void;
  onDownload: () => void;
  deleteOptionLabel?: string;
}) {
  return (
    <Menu
      as='div'
      className='relative inline-block text-left'
      onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
        event.stopPropagation()
      }
    >
      <Float placement='bottom-end' offset={4} portal>
        <MenuButton className='flex items-center justify-center'>
          <div className=' hover:border-1 flex items-center space-x-2 rounded py-2 font-semibold text-indigo-600 hover:ring-1 hover:ring-indigo-600'>
            <FaEllipsisV size={20} aria-hidden='true' />
          </div>
        </MenuButton>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <MenuItems className='absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-slate-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:border dark:border-white dark:bg-gray-900'>
            <div className='px-1 py-1 '>
              <MenuItem>
                {({ active }) => (
                  <button
                    className={`${
                      active
                        ? 'bg-indigo-500 text-white'
                        : 'text-slate-700 dark:text-slate-300'
                    } group flex w-full items-center px-2 py-2 text-sm`}
                    onClick={onRename}
                  >
                    {active ? (
                      <FaPen className='mr-2 h-5 w-5' aria-hidden='true' />
                    ) : (
                      <FaPen className='mr-2 h-5 w-5' aria-hidden='true' />
                    )}
                    Rename
                  </button>
                )}
              </MenuItem>
            </div>
            <div className='px-1 py-1'>
              <MenuItem>
                {({ active }) => (
                  <button
                    className={`${
                      active
                        ? 'bg-indigo-500 text-white'
                        : 'text-slate-700 dark:text-slate-300'
                    } group flex w-full items-center px-2 py-2 text-sm disabled:opacity-50`}
                    onClick={onDownload}
                    disabled={!onDownload}
                  >
                    {active ? (
                      <FaDownload className='mr-2 h-5 w-5' aria-hidden='true' />
                    ) : (
                      <FaDownload className='mr-2 h-5 w-5' aria-hidden='true' />
                    )}
                    Download
                  </button>
                )}
              </MenuItem>
            </div>
            <div className='px-1 py-1'>
              <MenuItem>
                {({ active }) => (
                  <button
                    className={`${
                      active
                        ? 'bg-indigo-500 text-white'
                        : 'text-slate-700 dark:text-slate-300'
                    } group flex w-full items-center px-2 py-2 text-sm`}
                    onClick={onDelete}
                  >
                    {active ? (
                      <FaTrash
                        className='mr-2 h-5 w-5 text-rose-600'
                        aria-hidden='true'
                      />
                    ) : (
                      <FaTrash className='mr-2 h-5 w-5 ' aria-hidden='true' />
                    )}
                    {deleteOptionLabel}
                  </button>
                )}
              </MenuItem>
            </div>
          </MenuItems>
        </Transition>
      </Float>
    </Menu>
  );
}
