import { IPaginatedResponse, IResponse, ISearchRequest } from '..';
import client from '../client';
import { IProject } from './project.service';
import { ITrack } from './track.service';

interface INewAlbum {
  name: string;
  teamId: string;
  type: string;
}

export interface IUserShort {
  id: string;
  firstname: string;
  lastname: string;
  avatar: string;
  email?: string;
}

export interface IProjectInvitation {
  id: string;

  albumId: string;

  albumRole: string;

  targetUserEmail: string;
  targetUserId: string;

  status: string;

  inviteOriginUserId: string;

  createdAt: string;
  updatedAt: string;

  user: IUserShort;
}

interface AddTrackToAlbumInput {
  trackId: string;
  trackCollectionId: string;
  createdAt: string;
}

async function getAlbums() {
  return client.get<IResponse<IProject[]>>('/project/type:ALBUM');
}

async function getAlbum(id: string) {
  return client.get<IResponse<IProject>>(`/project/${id}`);
}

async function getTracksOfAlbum(projectId: string) {
  return client.get<IResponse<ITrack[]>>(`/track/project:${projectId}`);
}

async function deleteTrackFromAlbum(albumId: string, trackId: string) {
  return client.delete(`/project/${albumId}/track/${trackId}`);
}

async function addTracktoAlbum(
  collectionId: string,
  data: AddTrackToAlbumInput
) {
  return client.post<IResponse<ITrack[]>>(
    `/project/${collectionId}/track`,
    data
  );
}

async function addAlbum(album: INewAlbum) {
  return client.post<IResponse<IProject>>(`/project`, album);
}

async function updateAlbum(id: string, album: Partial<IProject>) {
  return client.put<IResponse<IProject>>(`/project/${id}`, album);
}

async function updateAlbumPartially(albumId: string, album: Partial<IProject>) {
  return client.patch<IResponse<IProject>>(`/project/${albumId}`, album);
}

async function deleteAlbum(id: string) {
  return client.delete<IResponse<string>>(`/project/${id}`);
}

async function searchAlbum(req: ISearchRequest) {
  return client.post<IPaginatedResponse<IProject[]>>('/project/search', req);
}

const api = {
  getAlbums,
  getAlbum,
  addAlbum,
  updateAlbum,
  updateAlbumPartially,
  getTracksOfAlbum,
  deleteAlbum,
  searchAlbum,
  addTracktoAlbum,
  deleteTrackFromAlbum,
};

export default api;
