import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { ITrack } from '../../../../api/services/track.service';
import Track from './Track';
import { IComposer } from '../../../../api/services/composer.service';
import Loader from '../../../shared/Loader';
import api from '../../../../api';
import { IoCloseSharp } from 'react-icons/io5';

interface Props {
  isOpened: boolean;
  close: () => void;
  tracks: ITrack[];
  onConfirm: () => void;
}

async function fetchTrackComposers(trackId: string) {
  const response = await api.composer.getComposersByTrack(trackId);
  return response.data.result || [];
}

export default function ProjectConfirmDialog({
  isOpened,
  close,
  tracks,
  onConfirm,
}: Props) {
  const [isChecked, setIsChecked] = useState(false);
  const [composers, setComposers] = useState<{
    [key: string]: IComposer[];
  }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmation, setIsConfirmation] = useState(false);

  useEffect(() => {
    if (isOpened) {
      setIsConfirmation(false);
      setIsLoading(true);
      setComposers({});
      Promise.all(tracks.map((track) => fetchTrackComposers(track.id)))
        .then((res) => {
          setComposers(
            res.reduce(
              (
                acc: { [key: string]: IComposer[] },
                arr: IComposer[],
                i: number
              ) => {
                acc[tracks[i].id] = arr;
                return acc;
              },
              {}
            )
          );
        })
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
          setIsChecked(false);
        });
    }

    // eslint-disable-next-line
  }, [isOpened]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Transition appear show={isOpened} as={Fragment}>
      <Dialog as='div' className='relative z-10 w-full' onClose={close}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>
        <div className='fixed inset-0 overflow-y-auto text-slate-700'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white px-6 pb-6 text-left align-middle shadow-xl transition-all dark:bg-gray-900'>
                <div className='-mx-6 mb-2 flex items-center justify-between border-b border-gray-300 px-6 py-3'>
                  <Dialog.Title className='flex items-center text-2xl font-bold leading-6 dark:border-gray-500 dark:bg-gray-800  dark:text-gray-200 '>
                    Confirmation
                  </Dialog.Title>
                  <button
                    onClick={close}
                    className='transition-transform duration-300 hover:rotate-90'
                  >
                    <IoCloseSharp className='h-8 w-8 dark:fill-gray-300' />
                  </button>
                </div>

                {isConfirmation ? (
                  <div className='dark:text-gray-300'>
                    <div className='mb-3 text-3xl font-bold'>
                      Terms & Conditions
                    </div>
                    <ol className='list-decimal space-y-4 pl-5 text-lg leading-relaxed'>
                      <li className='space-y-3'>
                        <div>
                          The Contractual Partner represents and warrants to
                          Audiomode UG/ Midnight AV and its licensees that
                        </div>
                        <div>
                          a) That he has all rights necessary for the
                          contractual exploitation of the contractual
                          recordings/works and those transferred herein, in
                          particular with regard to the rights of the
                          participants, and that the contractual
                          recordings/works are free of rights of third parties
                          and that he is fully entitled and not prevented from
                          concluding and fulfilling this contract by any other
                          obligation, and that he has not granted, will not
                          grant, or has undertaken or will undertake any other
                          actions which could impair or prevent the execution of
                          this contract;
                        </div>
                        <div>
                          b) No third party claims exist which could impair or
                          prevent Audiomode’s exploitation of the contractual
                          recordings/works (e.g. uncleared "Samples") and
                          Audiomode’s or its sublicensees are in no way liable
                          for third party claims arising from the use and/or
                          exploitation of the contractual
                          recordings/works/compositions.
                        </div>
                      </li>
                      <li className='space-y-3'>
                        In the event a work is published or recorded with
                        musical or lyrical material which Audiomode UG/ Midnight
                        AV, in its sole discretion, regards as infringing
                        another work, Audiomode UG/ Midnight AV reserves the
                        right at any time to exclude the work in its entirety
                        from the provisions of any affiliation agreement and to
                        withdraw any previously-accepted registration of the
                        work. Audiomode UG/ Midnight AV shall under no
                        circumstances be liable for any loss or damage,
                        including but not limited to lost profits, goodwill or
                        indirect, consequential or punitive damages arising out
                        of the use of this online service.
                      </li>
                    </ol>
                    <div className='my-6 pl-5'>
                      <div className={`flex items-start`}>
                        <input
                          checked={isChecked}
                          onChange={(e) => {
                            setIsChecked(e.target.checked);
                          }}
                          id='confirm'
                          name='confirm'
                          type='checkbox'
                          className='h-6 w-6 rounded border-slate-300 text-emerald-600 focus:ring-emerald-600'
                        />
                        <label
                          htmlFor='confirm'
                          className='-mt-1 ml-4 text-lg font-normal text-slate-700 dark:text-gray-200'
                        >
                          By checking this box, I represent that, to the best of
                          my knowledge and belief, the information that I am
                          submitting is accurate, and that I have read and
                          agreed to the Terms and Conditions .
                        </label>
                      </div>
                    </div>
                    <div className='flex justify-end gap-x-2'>
                      <button
                        className='w-28 rounded-md border border-emerald-600 bg-transparent py-2 px-8 text-sm font-semibold text-emerald-600 transition-colors hover:border-emerald-800 hover:text-emerald-800 focus:outline-none dark:border-emerald-500 dark:text-emerald-500 dark:hover:border-emerald-600 dark:hover:text-emerald-600'
                        onClick={() => {
                          setIsConfirmation(false);
                        }}
                      >
                        Back
                      </button>
                      <button
                        disabled={!isChecked}
                        type='submit'
                        className='w-28 rounded-md bg-emerald-600 py-2 px-8 text-sm font-semibold text-white transition-colors hover:bg-emerald-800 focus:outline-none disabled:bg-gray-300 dark:disabled:bg-gray-600 dark:disabled:text-gray-400'
                        onClick={onConfirm}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className='flex flex-col gap-y-4 divide-y divide-gray-300 dark:divide-gray-400'>
                      {tracks.map((track) => (
                        <Track
                          track={track}
                          key={track.id}
                          composers={composers[track.id] || []}
                        />
                      ))}
                    </div>
                    <div className='flex justify-end gap-x-2'>
                      <button
                        className='w-28 rounded-md border border-emerald-600 bg-transparent py-2 px-8 text-sm font-semibold text-emerald-600 transition-colors hover:border-emerald-800 hover:text-emerald-800 focus:outline-none dark:border-emerald-500 dark:text-emerald-500 dark:hover:border-emerald-600 dark:hover:text-emerald-600'
                        onClick={close}
                      >
                        Close
                      </button>
                      <button
                        type='submit'
                        className='w-28 rounded-md bg-emerald-600 py-2 px-8 text-sm font-semibold text-white transition-colors hover:bg-emerald-800 focus:outline-none disabled:bg-gray-300 dark:disabled:bg-gray-600 dark:disabled:text-gray-400'
                        onClick={() => {
                          setIsConfirmation(true);
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
