import { Switch } from '@headlessui/react';
import { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import api from '../../../api';
import { ITagType, ITag } from '../../../api/services/tag.service';
import { globalThemeStyles } from '../../../config/globalStyles';
import GenericDialog from '../../shared/dialog/GenericDialog';
import Input from '../../shared/form/Input';
import {
  BiBook,
  BiCube,
  BiEdit,
  BiMusic,
  BiPalette,
  BiSearchAlt,
  BiSun,
  BiTag,
  BiTrash,
} from 'react-icons/bi';
import { MdPiano } from 'react-icons/md';
import DeleteConfirmationDialog from '../../shared/dialog/DeleteConfirmationDialog';

export function mapTagTypeToIcon(type: string | undefined) {
  switch (type) {
    case 'MUSICFOR':
      return <BiMusic size={20} />;
    case 'MOOD':
      return <BiSun size={20} />;
    case 'KEYWORD':
      return <BiSearchAlt size={20} />;
    case 'STYLE':
      return <BiPalette size={20} />;
    case 'INSTRUMENT':
      return <MdPiano size={20} />;
    case 'ELEMENT':
      return <BiCube size={20} />;
    case 'CATALOGUE':
      return <BiBook size={20} />;
    default:
      return <BiTag size={20} />;
  }
}

export function mapTagTypeToColorClass(type: string) {
  switch (type) {
    case 'MUSICFOR':
      return 'bg-indigo-500 text-white';
    case 'MOOD':
      return 'bg-amber-500 text-white';
    case 'KEYWORD':
      return 'bg-teal-500 text-white';
    case 'STYLE':
      return 'bg-blue-500 text-white';
    case 'INSTRUMENT':
      return 'bg-red-500 text-white';
    case 'ELEMENT':
      return 'bg-purple-500 text-white';
    case 'CATALOGUE':
      return 'bg-emerald-500 text-white';
    default:
      return 'bg-gray-500 text-white';
  }
}

export function ListTagsForm({
  isOpen,
  setIsOpen,
  setIsEditOpen,
  tagType,
  tags,
  reloadTags,
}: {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  setIsEditOpen: (tagId: string) => void;
  reloadTags: () => void;
  tagType: ITagType | null;
  tags: ITag[];
}) {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleteTypeDialogOpen, setIsDeleteTypeDialogOpen] = useState(false);
  const [activeTag, setActiveTag] = useState<string | null>(null);
  const [name, setName] = useState(tagType ? tagType.name : '');
  const [tagsMin, setTagsMin] = useState(tagType ? tagType.tagsMin : 0);
  const [tagsMax, setTagsMax] = useState(tagType ? tagType.tagsMax : 0);
  const [selectable, setSelectable] = useState(
    tagType ? tagType.selectable : false
  );
  const [displayName, setDisplayNme] = useState(
    tagType ? tagType.displayName : ''
  );

  useEffect(() => {
    setName(tagType ? tagType.name : '');
    setDisplayNme(tagType ? tagType.displayName : '');
    setTagsMax(tagType ? tagType.tagsMax : 0);
    setTagsMin(tagType ? tagType.tagsMin : 0);
    setSelectable(tagType ? tagType.selectable : false);
  }, [tagType]);

  const deleteTag = async (id: string) => {
    try {
      await api.tag.deleteTag(id);
      toast.success('Tag deleted successfully!');
      reloadTags();
    } catch (e) {
      console.error(e);
    }
  };

  const deleteTagType = async () => {
    try {
      if (!tagType) {
        return;
      }
      await api.tag.deleteTagType(tagType?.id);
      toast.success('Tag type deleted successfully!');
      reloadTags();
      setIsOpen(false);
    } catch (e) {
      console.error(e);
    }
  };

  const updateTagType = async () => {
    try {
      if (!tagType) {
        return;
      }
      await api.tag.updateTagTypePartially(tagType?.id, {
        name,
        displayName,
        selectable,
        tagsMax,
        tagsMin,
      });
      toast.success('Tag type updated successfully!');
      reloadTags();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <GenericDialog
      onUpperRightClick={() => setIsOpen(false)}
      upperRightIcon={
        <FaTimes size={20} className='text-gray-600 dark:text-gray-300' />
      }
      header={`${tagType?.displayName} Tags`}
      headerIcon={mapTagTypeToIcon(tagType?.name)}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      footer={
        <div className='mt-2 flex items-center justify-end gap-x-3'>
          <button
            type='button'
            className='rounded p-2 px-4 font-semibold text-slate-600 hover:bg-slate-200'
            onClick={() => setIsOpen(false)}
          >
            Close
          </button>
        </div>
      }
    >
      <div className='mt-6 max-w-xl text-base text-gray-600 dark:text-gray-300'>
        <DeleteConfirmationDialog
          isLoading={false}
          isOpen={isDeleteTypeDialogOpen}
          title={`Delete tag type ${tagType?.displayName}`}
          message={
            <>
              Do you really want to delete the tag type? This will delete all
              tags of this type from all tracks in your library.
            </>
          }
          close={() => setIsDeleteTypeDialogOpen(false)}
          onSubmit={() => {
            deleteTagType();
            setIsDeleteTypeDialogOpen(false);
          }}
        />
        <DeleteConfirmationDialog
          isLoading={false}
          isOpen={isDeleteDialogOpen}
          title={`Delete tag`}
          message={
            <>
              Do you really want to delete the tag? This will delete the tag
              from all tracks in your library.
            </>
          }
          close={() => setIsDeleteDialogOpen(false)}
          onSubmit={() => {
            deleteTag(activeTag || '');
            setIsDeleteDialogOpen(false);
          }}
        />
        <div className='flex flex-col space-y-6'>
          <div className='w-full xl:max-w-xl'>
            <Input
              label='Display name'
              // error={name.length === 0}
              onChange={(e) => setDisplayNme(e.target.value)}
              value={displayName}
              type='text'
              name='displayName'
              id='displayName'
              className={globalThemeStyles.inputbox}
              data-testid='tagTypeDisplayName'
            />
          </div>
          <div className='w-full xl:max-w-xl'>
            <Input
              label='Name'
              // error={name.length === 0}
              onChange={(e) => setName(e.target.value)}
              value={name}
              type='text'
              name='name'
              id='name'
              className={globalThemeStyles.inputbox}
              data-testid='tagTypeName'
            />
          </div>
          <div className='flex w-full space-x-4 xl:max-w-xl'>
            <div className='w-1/2'>
              <Input
                label='Min Tags required'
                error={name.length === 0}
                onChange={(e) => setTagsMin(parseInt(e.target.value))}
                value={tagsMin}
                type='number'
                name='tagsMin'
                id='tagsMin'
                className={globalThemeStyles.inputbox}
                data-testid='tagTypeTagsMin'
              />
            </div>
            <div className='w-1/2'>
              <Input
                label='Max Tags required'
                error={name.length === 0}
                onChange={(e) => setTagsMax(parseInt(e.target.value))}
                value={tagsMax}
                type='number'
                name='tagsMax'
                id='tagsMax'
                className={globalThemeStyles.inputbox}
                data-testid='tagTypeTagsMax'
              />
            </div>
          </div>
          <div className='flex w-full items-center space-x-4 xl:max-w-xl'>
            <div className='flex w-full items-center space-x-4 xl:max-w-xl'>
              <Switch
                checked={selectable}
                onChange={(e) => setSelectable(e)}
                className='group inline-flex h-6 w-11 items-center rounded-full bg-gray-200 transition data-[checked]:bg-indigo-600'
              >
                <span className='size-4 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-6' />
              </Switch>
              <div
                className={`flex items-center text-sm font-medium dark:text-slate-200 `}
              >
                Tag selectable in tags tab&nbsp;
              </div>
            </div>
            <button
              className='rounded bg-emerald-600 p-2 px-4 font-semibold text-white hover:bg-emerald-800'
              onClick={() => updateTagType()}
            >
              Save
            </button>
            <button
              className='rounded bg-red-600 p-2 px-4 font-semibold text-white hover:bg-red-800'
              onClick={() => setIsDeleteTypeDialogOpen(true)}
            >
              Delete
            </button>
          </div>
        </div>
        <hr className='mt-4' />
        <p className='mt-4'>{tagType?.displayName} Tags:</p>
      </div>
      <div className='flex flex-col space-y-4'>
        {tags.map((tag) => (
          <TagRow
            tag={tag}
            deleteTag={(id) => {
              setActiveTag(id);
              setIsDeleteDialogOpen(true);
            }}
            editTag={setIsEditOpen}
          />
        ))}
      </div>
    </GenericDialog>
  );
}

function TagRow({
  tag,
  editTag,
  deleteTag,
}: {
  tag: ITag;
  editTag: (id: string) => void;
  deleteTag: (id: string) => void;
}) {
  return (
    <div
      key={tag.id}
      className={`flex h-24 break-all rounded-lg bg-white px-4 py-2 shadow hover:cursor-pointer hover:bg-slate-50 dark:bg-slate-900 hover:dark:bg-slate-700 md:h-20 lg:h-14 xl:h-14 ${globalThemeStyles.container}`}
    >
      <div className='flex w-full items-center space-x-4'>
        <div className='flex rounded-md bg-slate-500 p-2 text-white '>
          {mapTagTypeToIcon(tag.type)}
        </div>

        <div className='flex-1 '>
          <div className='flex items-center justify-between'>
            <h3 className='text-sm font-medium'>{tag.name}</h3>
            <div className='flex space-x-8'>
              <div
                className={`flex h-5 items-center justify-center rounded px-2.5 py-0.5 text-sm font-semibold ${mapTagTypeToColorClass(
                  tag.type
                )}`}
              >
                <div>{tag.type.toUpperCase()}</div>
              </div>
              <div className='flex space-x-2'>
                <div onClick={() => editTag(tag.id)}>
                  <BiEdit size={20} />
                </div>
                <div onClick={() => deleteTag(tag.id)}>
                  <BiTrash size={20} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
