import { useNavigate } from 'react-router-dom';
import { KTRLogo } from '../../components/shared/ktr';

export default function NotFound() {
  let navigate = useNavigate();

  return (
    <div className='mt-20 flex flex-grow'>
      <div className='flex w-full flex-col items-center'>
        <div className='h-40 w-40'>
          <KTRLogo />
        </div>
        <h2 className='mt-6 text-center font-mono text-3xl font-extrabold text-gray-900 dark:text-gray-100'>
          404 Not Found
        </h2>
        <p className='mt-2 text-center text-sm text-gray-600 dark:text-gray-300'>
          We don't know what your looking for, but it's definitely not here...
        </p>
        <div className='mt-5'>
          <button
            className='flex items-center rounded bg-indigo-600 px-4 py-1 font-semibold text-white hover:bg-indigo-800'
            onClick={() => navigate(-1)}
          >
            <div>Go Back</div>
          </button>
        </div>
      </div>
    </div>
  );
}
