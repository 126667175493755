import { IPaginatedResponse, IResponse, ISearchRequest } from '..';
import client from '../client';

export interface ISubscription {
  id: string;
  userId: string;
  foreignCustomerId: string;
  foreignSubscriptionId: string;

  planId: string;

  status: string;

  createdAt: string;
  updatedAt: string;
  expiresAt: string;
}

export interface IPlan {
  id: string;
  defaultPriceId: string;

  price: number;

  active: boolean;
  listed: boolean;

  details: {
    primaryFeature: string;
    secondaryFeature: string;
    tagLine: string;
  };

  name: string;
  tagLine: string;

  createdAt: String;
  updatedAt: String;
}

async function getMySubscriptions() {
  return client.get<IResponse<ISubscription[]>>('/subscription/me');
}

async function getAvailablePlans() {
  return client.get<IResponse<IPlan[]>>('/plan');
}

async function searchSubscription(req: ISearchRequest) {
  return client.post<IPaginatedResponse<ISubscription[]>>(
    '/subscription/search',
    req
  );
}
const api = {
  getMySubscriptions,
  searchSubscription,
  getAvailablePlans,
};

export default api;
