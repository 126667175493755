import { useEffect, useState } from 'react';
import { IFile } from '../../../../api/services/file.service';
import { IProject } from '../../../../api/services/project.service';
import { ITrack } from '../../../../api/services/track.service';
import { useAuth } from '../../../../hooks/useAuth';
import { IStateProp } from '../../studioState';
import { FileUploadField, IFileInputField } from './fileTab/FileUploadField';
import { useFilesTabUpload } from './fileTab/useFilesTabUpload';
import { FaFileArchive, FaFileAudio } from 'react-icons/fa';
import {
  isContentTypeAllowed,
  isFileExtensionAllowed,
} from '../../../../utils';
import { toast } from 'react-toastify';
import {
  checkContentTypeMatch,
  uploadFile,
} from './fileTab/FileTabWindow.helpers';
import { v4 as uuidv4 } from 'uuid';

export function FileTabWindow({
  project,
  activeTrack,
  files,
  canBeModified,
  refreshData,
  activeProjectRole,
}: {
  project: IProject;
  activeTrack: ITrack;
  files: IFile[];
  canBeModified: boolean;
  refreshData: (state: IStateProp) => void;
  activeProjectRole: string | null;
}) {
  const {
    filesInUpload,
    addFileToUpload,
    setFilesInUpload,
    onUploadCompleted,
  } = useFilesTabUpload();

  const { activeTeam } = useAuth();

  const [trackFiles, setTrackFiles] = useState<IFile[]>([]);

  useEffect(() => {
    setTrackFiles([
      ...files.filter((file) => file.parentId === activeTrack.id),
    ]);
  }, [files, activeTrack]);

  const specialFileFields: IFileInputField[] = [
    {
      id: 'raw-file',
      name: 'Raw File',
      targetContentTypes: {
        'audio/*': ['.wav'],
      },
      targetRole: 'MAIN',
      canBeModified: true,
      icon: <FaFileAudio className='h-6 w-6' />,
      description: (
        <ul className='list-disc'>
          <li>
            Please upload your UNMASTERED WAVE files in 24 Bit and 48 Khz,
            bouncing at max. -6db headroom in peaks (RMS)
          </li>
          <li>No compression on the final mix / stereo sum!</li>
          <li>
            Be aware to not cut the tails at the end of your track, No
            fade-outs!
          </li>
          <li>
            Be aware of clicks, clips and other artifacts that can result from
            rough cuts / lack of micro fades
          </li>
        </ul>
      ),
    },
    {
      id: 'master-file',
      name: 'Master File',
      targetContentTypes: {
        'audio/*': ['.wav'],
      },
      targetRole: 'MASTER',
      canBeModified: true,
      icon: <FaFileAudio className='h-6 w-6' />,
      authorize: (userTenantRole: String, userProjectRole: String) => {
        return (
          userTenantRole === 'OWNER' ||
          userTenantRole === 'MEMBER' ||
          userProjectRole === 'OWNER' ||
          userProjectRole === 'MEMBER'
        );
      },
    },
    {
      id: 'stem-archive',
      name: 'Stem Archive',
      targetContentTypes: {
        'application/zip': ['.zip'],
        'application/x-zip-compressed': ['.zip'],
      },
      targetRole: 'STEMS',
      canBeModified: true,
      icon: <FaFileArchive className='h-6 w-6' />,
      description: (
        <ul className='list-disc'>
          <li>Please ensure all grouped stems are 24/48 Wav</li>
          <li>
            Name each stem file like this:{' '}
            <i>{`${activeTeam?.libraryCode}_${activeTrack.metadata.artistName}_${activeTrack.name}_STEMTYPE`}</i>
          </li>
          <li>
            Example:{' '}
            <i>{`${activeTeam?.libraryCode}_${activeTrack.metadata.artistName}_${activeTrack.name}_BASS`}</i>
          </li>
          <li>Not more than a maximum of 12 grouped stems all in all!</li>
          <li>
            Add all Stems of the same track to a folder, name it like this:
            <i>{`${activeTeam?.libraryCode}_${activeTrack.metadata.artistName}_${activeTrack.name}_Grouped Stems`}</i>
          </li>
          <li>Please compress the folder to a zip-file and upload it here.</li>
        </ul>
      ),
      hint: `File Format: wav files archived into one zip file.\nPlease name each stem like this: ${activeTeam?.libraryCode}_${activeTrack.metadata.artistName}_${activeTrack.name}_STEMTYPE`,
    },
  ];

  const trackId = activeTrack.id;

  useEffect(() => {
    console.log('activeProjectRole', activeProjectRole);
  }, [activeProjectRole]);

  return (
    <>
      <div className='flex w-full flex-col space-y-12'>
        {activeTeam &&
          filesInUpload &&
          specialFileFields
            .filter(
              (sff) =>
                sff.authorize === undefined ||
                (sff.authorize !== undefined &&
                  activeTeam &&
                  activeTeam.teamMembershipDTO &&
                  activeProjectRole &&
                  sff.authorize(
                    activeTeam.teamMembershipDTO.role,
                    activeProjectRole
                  ))
            )
            .map((fileField) => {
              return (
                <FileUploadField
                  file={trackFiles.find(
                    (file) =>
                      checkContentTypeMatch(
                        file.contentType,
                        fileField.targetContentTypes
                      ) && file.role === fileField.targetRole
                  )}
                  fileInUpload={
                    filesInUpload
                      .filter(
                        (f) =>
                          fileField.id === f.field &&
                          f.activeProject.id === project.id &&
                          f.trackId === trackId
                      )
                      .slice(-1)[0]
                  }
                  description={fileField.description}
                  refreshData={refreshData}
                  team={activeTeam}
                  project={project}
                  track={activeTrack}
                  key={fileField.name}
                  fileField={fileField}
                  canBeModified={canBeModified && fileField.canBeModified}
                  id={fileField.id}
                  onChange={(e) => {
                    if (!e.target.files) return;
                    const newFiles = Array.from(e.target.files);
                    const fileToUpload = newFiles[0];
                    if (!fileToUpload) return;

                    e.target.value = '';

                    const acceptedFilesWithCorrectType = newFiles.filter(
                      (file) => {
                        return Object.keys(fileField.targetContentTypes).some(
                          (key) =>
                            isContentTypeAllowed(key, file.type) &&
                            isFileExtensionAllowed(
                              fileField.targetContentTypes[key],
                              file.name
                            )
                        );
                      }
                    );

                    if (
                      acceptedFilesWithCorrectType.length !== newFiles.length
                    ) {
                      const rejectedFiles = newFiles.filter(
                        (file) => !acceptedFilesWithCorrectType.includes(file)
                      );
                      const rejectedFilesString = rejectedFiles
                        .map((file) => `${file.name} (${file.type})`)
                        .join(', ');
                      toast.error(
                        `The following files were rejected because they are not of the allowed file type: ${rejectedFilesString}`
                      );
                      return;
                    }

                    const activeFile = trackFiles.find(
                      (file) =>
                        checkContentTypeMatch(
                          file.contentType,
                          fileField.targetContentTypes
                        ) && file.role === fileField.targetRole
                    );

                    if (canBeModified && fileField.canBeModified) {
                      const id = uuidv4();
                      addFileToUpload({
                        id,
                        file: fileToUpload,
                        activeProject: project,
                        field: fileField.id,
                        progress: 0,
                        trackId,
                      });

                      uploadFile(
                        fileToUpload,
                        activeFile,
                        refreshData,
                        trackId,
                        project,
                        fileField.targetRole,
                        (progress: number) => {
                          setFilesInUpload((prev) =>
                            prev.map((f) => {
                              if (f.id === id) {
                                return {
                                  ...f,
                                  progress,
                                };
                              }
                              return f;
                            })
                          );
                        },
                        onUploadCompleted(refreshData, id)
                      );
                    }
                  }}
                />
              );
            })}
      </div>
    </>
  );
}
