import { Switch } from '@headlessui/react';

export default function SwitchRow({
  isActive,
  onChange,
  title,
  children,
  icon,
  disabled,
  noSwitch,
}: {
  isActive: boolean;
  onChange: () => void;
  title: string;
  children: React.ReactNode;
  icon?: React.ReactNode;
  disabled?: boolean;
  noSwitch?: boolean;
}) {
  return (
    <div
      className={`flex flex-col rounded-md border border-slate-300 pt-4 pl-4 pr-4 pb-4 shadow-sm ${
        disabled ? 'bg-slate-200' : ''
      }`}
    >
      <div className='mb-3 flex flex-row items-center justify-between'>
        <div className='flex items-center'>
          {icon && icon}
          <span
            className={`flex border-b border-indigo-700 text-lg font-medium leading-6 text-slate-700 ${
              icon ? 'ml-2' : ''
            }`}
          >
            {title}
          </span>
        </div>
        {!noSwitch && (
          <Switch
            disabled={disabled}
            checked={isActive}
            onChange={(e) => {
              onChange();
            }}
            className={`${
              isActive ? 'bg-indigo-700' : 'bg-slate-400'
            } w- relative
          inline-flex h-[30px] w-[62px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white  focus-visible:ring-opacity-75 disabled:cursor-default disabled:bg-slate-400`}
          >
            <span className='sr-only'>Use setting</span>
            <span
              aria-hidden='true'
              className={`${isActive ? 'translate-x-8' : 'translate-x-0'} ${
                disabled ? 'bg-slate-100' : ''
              }
            pointer-events-none inline-block h-[26px] w-[26px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
        )}
      </div>
      {children}
    </div>
  );
}
