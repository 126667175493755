import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import api from '../api';
import { IFile } from '../api/services/file.service';
import { ITrack } from '../api/services/track.service';
import Loader from '../components/shared/Loader';
import { ShowStudio } from '../components/project/ShowStudio';
import StudioDropzoneWrapper from '../components/project/StudioDropzoneWrapper';
import { ActionBar } from '../components/project/sections/SubmissionActionBar';
import ProjectConfirmDialog from '../components/project/dialogs/ProjectConfirmDialog/ProjectConfirmDialog';
import { StudioDropzone } from '../components/project/ProjectDropzone';
import SubmissionInfo from '../components/project/sections/SubmissionInfo';
import {
  StudioStateProvider,
  useStudioState,
} from '../components/project/studioState';
import TourStudio from '../components/project/tour/TourStudio';
import { useAuth } from '../hooks/useAuth';
import Forbidden from './generic/Forbidden';
import NotFound from './generic/NotFound';

export const validateTrack = (track: ITrack & { files: IFile[] }) =>
  track.metadata.bpm &&
  track.metadata.duration &&
  // track.metadata.catalogue &&
  track.metadata.scaleType &&
  track.metadata.key &&
  track.name &&
  track.metadata.trackNumber &&
  !!track.files.filter((f) => f.role !== 'STEMS' && f.parentId === track.id)
    .length;

function ProjectStudioViewInternal() {
  let { id } = useParams();
  const { isAuthenticated } = useAuth();

  const {
    activeProjectRole,
    setActiveProjectId,
    activeProject,
    activeProjectTracks,
    activeProjectMembers,
    activeTrack,
    activeProjectInvites,
    activeFiles,
    refreshData,
    trackUpdateLoading,
    activeProjectLoading,
    setIsTrackValidationErrorsShown,
    isTourActive,
  } = useStudioState();
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);

  const openConfirmModal = () => {
    setIsConfirmModalOpened(true);
  };

  useEffect(() => {
    setActiveProjectId(id!);
    // eslint-disable-next-line
  }, [id]);

  async function updateProjectStatus(
    status: string,
    message?: string,
    cb?: () => void
  ) {
    if (!activeProject) return;
    try {
      await api.project.updateProjectPartially(activeProject.id, {
        status,
        message,
      });
      refreshData('activeProject');
      refreshData('activeProjectTracks');
      if (cb) return cb();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      {activeProjectLoading && <Loader />}
      {!activeProject &&
        !activeProjectLoading &&
        (activeProjectMembers.length === 0 ? <Forbidden /> : <NotFound />)}
      {isAuthenticated && activeProject && (
        <div className='flex flex-1 grow flex-col justify-center'>
          {/* Autostart tour if not yet completed by user */}
          <TourStudio />
          <div className='flex flex-col space-y-4 md:flex-1'>
            {/* ActionBar to host all actions a user can perform */}
            <ActionBar
              isTourActive={isTourActive}
              updateProjectStatus={updateProjectStatus}
              onSubmit={() => {
                const tracksWithFiles = activeProjectTracks.map((track) => ({
                  ...track,
                  files: activeFiles,
                }));

                const invalidTracks = tracksWithFiles.filter(
                  (t) => !validateTrack(t)
                );

                if (!!invalidTracks.length) {
                  setIsTrackValidationErrorsShown(true);
                  toast.error(
                    <div className='text-sm text-gray-700'>
                      The following issues occured:
                      <div className='space-y-3 pt-2'>
                        {invalidTracks.map((t) => (
                          <div key={t.id} className='space-y-2'>
                            {/* {JSON.stringify(t)} */}
                            <div className='font-semibold'>Track {t.name}</div>
                            {!t.name && (
                              <div>
                                Original Track name can't be empty. Please set a
                                valid Track Name.
                              </div>
                            )}
                            {!t.metadata.trackNumber && (
                              <div>Field track nr must not be empty</div>
                            )}
                            {!t.metadata.duration && (
                              <div>Field duration must not be empty</div>
                            )}
                            {!t.metadata.bpm && (
                              <div>Field bpm must not be empty</div>
                            )}
                            {/* {!t.metadata.catalogue && (
                              <div>Field catalogue must not be empty</div>
                            )} */}
                            {!t.metadata.scaleType && (
                              <div>Field scaleType must not be empty</div>
                            )}
                            {!t.metadata.key && (
                              <div>Field key must not be empty</div>
                            )}
                            {!t.files.length && (
                              <div>
                                At lease one stem file should be assigned to
                                track
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                } else {
                  openConfirmModal();
                }
              }}
              trackUpdateLoading={trackUpdateLoading}
              activeProject={activeProject}
              activeProjectRole={activeProjectRole}
              activeProjectMembers={activeProjectMembers}
              activeProjectInvites={activeProjectInvites}
              refreshData={refreshData}
              activeProjectFiles={activeFiles}
            />
            <div className='flex flex-col space-y-4 md:flex-1'>
              {/* General info about active submission, editable */}
              <SubmissionInfo />
              <StudioDropzoneWrapper>
                <div className='flex grow flex-row gap-4'>
                  <StudioDropzone
                    activeProject={activeProject}
                    activeProjectRole={activeProjectRole}
                    activeProjectTracks={activeProjectTracks}
                  />
                  <ShowStudio
                    activeTrack={activeTrack}
                    activeProjectTracks={activeProjectTracks}
                  />
                </div>
              </StudioDropzoneWrapper>
            </div>
          </div>
          <ProjectConfirmDialog
            isOpened={isConfirmModalOpened}
            close={() => {
              setIsConfirmModalOpened(false);
            }}
            tracks={activeProjectTracks}
            onConfirm={() => {
              updateProjectStatus('SUBMITTED', undefined, () => {
                setIsConfirmModalOpened(false);
              });
            }}
          />
        </div>
      )}
    </>
  );
}

export default function ProjectStudioView() {
  return (
    <StudioStateProvider>
      <ProjectStudioViewInternal />
    </StudioStateProvider>
  );
}
