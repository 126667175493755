import {
  FaCheck,
  FaTimes,
  FaTrash,
  FaUserCircle,
  FaUserClock,
} from 'react-icons/fa';
import stringToColor from 'string-to-color';
import {
  IProjectInvitation,
  IProjectMembership,
} from '../../../api/services/project.service';
import { globalThemeStyles } from '../../../config/globalStyles';
import StatusBadge from '../../shared/StatusBadge';

export default function ProjectMemberList({
  users,
  issuedInvites,
  deleteUser,
  revokeInvite,
  updateInvite,
}: {
  users: IProjectMembership[];
  issuedInvites: IProjectInvitation[];
  deleteUser: (userId: string) => void;
  revokeInvite: (email: string) => void;
  updateInvite: (inviteId: string, status: string) => void;
}) {
  return (
    <div className='flex max-h-44 flex-col space-y-2 overflow-y-scroll overscroll-auto'>
      {users.map((user, i) => (
        <div
          key={i}
          className={`flex items-center justify-between rounded-md border px-4 py-2 pr-2 transition-colors ease-in-out  ${globalThemeStyles.inputbox}`}
        >
          <div className='flex items-center'>
            <FaUserCircle
              className='h-8 w-8 rounded-full bg-white'
              color={stringToColor(user.targetUserId)}
            />
            <span
              className={`ml-2 block truncate font-semibold text-slate-700 ${globalThemeStyles.inputbox}`}
            >
              {user.user.firstname} {user.user.lastname}
            </span>
          </div>
          {user.role !== 'OWNER' && (
            <div
              className='mr-2 cursor-pointer rounded-xl text-indigo-600 transition-colors duration-200 ease-in-out hover:text-orange-600'
              onClick={(event) => {
                event.stopPropagation();
                deleteUser(user.targetUserId);
              }}
            >
              <FaTrash size={20} />
            </div>
          )}
          {user.role === 'OWNER' && <StatusBadge status={'Owner'} />}
        </div>
      ))}
      {issuedInvites.map((invite, i) => (
        <div
          key={i}
          className='flex items-center justify-between rounded-md border bg-slate-100 px-4 py-2 pr-2 transition-colors ease-in-out hover:bg-slate-200'
        >
          <div className='flex items-center'>
            <FaUserClock className='h-8 w-8  text-slate-500' />
            {invite.user && (
              <span
                className={`ml-2 block truncate font-semibold text-slate-700`}
              >
                {invite.user.firstname} {invite.user.lastname}
              </span>
            )}
            {!invite.user && (
              <span
                className={`ml-2 block truncate font-semibold text-slate-700`}
              >
                {invite.targetUserEmail}
              </span>
            )}
          </div>
          <StatusBadge status={invite.status} />
          {invite.status === 'OPEN' && (
            <div
              className='mr-2 rounded-xl text-indigo-600 transition-colors duration-200 ease-in-out hover:text-orange-600'
              onClick={(event) => {
                event.stopPropagation();
                revokeInvite(invite.targetUserEmail);
              }}
            >
              <FaTrash size={20} />
            </div>
          )}
          {invite.status === 'PENDING' && (
            <div className='mr-2 flex space-x-4'>
              <div
                className='rounded-xl text-emerald-600 transition-colors duration-200 ease-in-out hover:text-emerald-500'
                onClick={(event) => {
                  event.stopPropagation();
                  updateInvite(invite.id, 'APPROVED');
                }}
              >
                <FaCheck size={20} />
              </div>
              <div
                className='rounded-xl text-orange-600 transition-colors duration-200 ease-in-out hover:text-orange-500'
                onClick={(event) => {
                  event.stopPropagation();
                  updateInvite(invite.id, 'DENIED');
                }}
              >
                <FaTimes size={20} />
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
