import {
  errorWrapper,
  IPaginatedResponse,
  IResponse,
  ISearchRequest,
} from '..';
import client from '../client';
import { IFile } from './file.service';

type MasterStatusType = 'draft' | 'unmastered' | 'mastered';

export interface ITrack {
  id: string;
  isrc: string;
  teamId: string;
  projectId: string;
  parentId: string | null;
  createdBy: string;
  name: string;
  description: string;

  metadata: {
    artistName: string;
    bpm: string;
    // catalogue: string;
    duration: string;
    isMain: boolean;
    key: string;
    lyrics: string;
    mainTrackNumber: string;
    scaleType: string;
    style: string;
    trackNumber: string;
    versionName: string;
    equalRoyaltySplit: boolean;
    masterStatus: MasterStatusType;
  };
}

export interface INewTrack {
  teamId: string;
  projectId: string;
  parentId: string | null;

  name: string;
  description: string;

  metadata?: any;
}

export interface TrackWithFiles extends ITrack {
  files: IFile[];
}

// Track
async function getTracksByProject(projectId: string) {
  return errorWrapper(
    () => client.get<IResponse<ITrack[]>>(`/track/project:${projectId}`),
    {
      'K-SAAS-0006':
        'Either the user you provided was not found or the password did not match',
      'SAAS-AUTH-0002':
        'It seems like this email is only valid for a different login method! You might want to try logging in via Google, O365, Apple or password',
      'SAAS-AUTH-0003':
        "Something didn't quite work out on our end and its currently not possible for you to login using this method",
      'SAAS-AUTH-0013':
        "A user with this email address already exists. If that's your account, you might want to try logging in",
    }
  );
}

async function getTrack(trackId: string) {
  return client.get<IResponse<ITrack>>(`/track/${trackId}`);
}

async function getTracks() {
  return client.get<IResponse<ITrack[]>>(`/track`);
}

async function addTrack(track: INewTrack) {
  return client.post<IResponse<ITrack>>(`/track`, track);
}

async function deleteTrack(id: string) {
  return client.delete<IResponse<ITrack>>(`/track/${id}`);
}

async function updateTrackPartially(trackId: string, track: Partial<ITrack>) {
  return client.patch<IResponse<ITrack>>(`/track/${trackId}`, track);
}

async function searchTrack(req: ISearchRequest) {
  return client.post<IPaginatedResponse<TrackWithFiles[]>>(
    '/track/search',
    req
  );
}

const api = {
  getTracksByProject,
  addTrack,
  deleteTrack,
  updateTrackPartially,
  searchTrack,
  getTrack,
  getTracks,
};

export default api;
