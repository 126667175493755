import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Transition,
} from '@headlessui/react';
import { Fragment, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { classNames } from '../../../utils';

interface Props<T> {
  options: T[];
  onChange: (v: T) => void;
  filterFn: (query: string) => (v: T) => boolean;
  getValue: (v: T) => string;
  displayValue: (v: T) => string;
  displayOption: (v: T) => string | JSX.Element;
  disabled?: boolean;
  placeholder?: string;
  label?: string;
  error?: boolean;
  value: any;
  className: string;
}

export default function DropdownAutocomplete<T>({
  options,
  filterFn,
  displayValue,
  displayOption,
  disabled,
  placeholder = '',
  label,
  error,
  getValue,
  value,
  onChange,
  className,
}: Props<T>) {
  const [query, setQuery] = useState('');

  const filteredOptions =
    query === '' ? options : options.filter(filterFn(query));

  return (
    <>
      <div
        className={`flex items-center text-sm font-medium dark:text-slate-200 ${
          error ? 'text-red-500' : ''
        }`}
      >
        {label}
      </div>
      <Combobox
        value={value}
        onChange={onChange}
        disabled={disabled}
        as={'div'}
        className={`relative mt-3`}
      >
        <div
          className={`relative w-full cursor-default overflow-hidden text-left sm:text-sm ${className} ${
            disabled
              ? 'border border-gray-300 bg-slate-200 dark:border-gray-600 dark:bg-slate-600'
              : 'border border-gray-300 dark:border-gray-600'
          }`}
        >
          <ComboboxInput
            className={`w-full border-none py-2.5 pl-3 pr-10 text-sm leading-5 focus:ring-0 ${className} rounded-none`}
            placeholder={placeholder}
            displayValue={displayValue}
            onChange={(event) => setQuery(event.target.value)}
          />
          <ComboboxButton className='absolute inset-y-0 right-0 flex items-center rounded-md pr-2'>
            <FaChevronDown
              className='h-5 w-5 text-gray-400'
              aria-hidden='true'
            />
          </ComboboxButton>
        </div>
        <Transition
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <ComboboxOptions className='absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-slate-900 sm:text-sm'>
            {filteredOptions.map((option, i) => (
              <ComboboxOption
                key={i}
                className={({ active, selected }) =>
                  `relative cursor-pointer select-none py-2 pl-4 pr-4 ${classNames(
                    active
                      ? 'bg-indigo-600 text-white'
                      : 'text-gray-900 dark:text-gray-100',
                    selected ? 'bg-indigo-500 text-white' : ''
                  )}`
                }
                value={getValue(option)}
              >
                <>{displayOption(option)}</>
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        </Transition>
      </Combobox>
    </>
  );
}
