import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ITrack } from '../api/services/track.service';

interface PlayerContextType {
  url: string;
  setUrl: (v: string) => void;
  player: HTMLAudioElement | null;
  setPlayer: React.Dispatch<React.SetStateAction<HTMLAudioElement | null>>;
  isPlaying: boolean;
  togglePlay: () => void;
  setFileId: React.Dispatch<React.SetStateAction<string | undefined>>;
  canPlay: boolean;
  fileId: string | undefined;
  setTrack: React.Dispatch<React.SetStateAction<ITrack | undefined>>;
  track: ITrack | undefined;
}

const PlayerContex = createContext<PlayerContextType>({} as PlayerContextType);

export const PlayerProvider = ({ children }: PropsWithChildren) => {
  const pausedRef = useRef(false);
  const [track, setTrack] = useState<ITrack | undefined>();
  const [fileId, setFileId] = useState<string>();
  const [player, setPlayer] = useState<HTMLAudioElement | null>(null);
  const [url, setUrl] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [canPlay, setCanPlay] = useState(false);

  const togglePlay = () => {
    if (player) {
      if (isPlaying) {
        pausedRef.current = true;
        player.pause();
      } else {
        pausedRef.current = false;
        player.play();
      }
      setIsPlaying((prev) => !prev);
    }
  };

  useEffect(() => {
    pausedRef.current = false;
    setCanPlay(false);
    // eslint-disable-next-line
  }, [url]);

  useEffect(() => {
    if (player) {
      player.addEventListener('canplay', () => {
        setCanPlay(true);
        if (!pausedRef.current) {
          player.play();
        }
      });
      player.addEventListener('playing', () => {
        setIsPlaying(true);
      });
      player.addEventListener('ended', () => {
        setIsPlaying(false);
        pausedRef.current = true;
      });
    }
  }, [player]);

  return (
    <PlayerContex.Provider
      value={{
        setTrack,
        track,
        fileId,
        setFileId,
        togglePlay,
        player,
        setPlayer,
        url,
        setUrl,
        isPlaying,
        canPlay,
      }}
    >
      {children}
    </PlayerContex.Provider>
  );
};

export const usePlayer = () => useContext(PlayerContex);
