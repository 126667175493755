export function GetStartedStepWindowLabel({
  nextStep,
}: {
  nextStep: () => void;
}) {
  return (
    <div className='py-8'>
      <div className='mt-4 mb-10 text-center font-medium text-gray-700 dark:text-slate-200'>
        <p>You are almost set to get started with KTR Studio!</p>
        <p>
          Please complete the required information about your label on the next
          page.
        </p>
      </div>
      <div className='flex w-full justify-center'>
        <button
          type='submit'
          className='group relative flex w-1/2 justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-semibold text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
          onClick={() => nextStep()}
        >
          Team settings
        </button>
      </div>
    </div>
  );
}
