import { useAuth } from '../hooks/useAuth';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../api';
import { INotification } from '../api/services/notification.service';
import ActivitySection from '../components/dashboard/recentActivity/ActivitySection';

export default function ProjectActivity() {
  const { user, activeTeam } = useAuth();

  const [activities, setActivities] = useState<INotification[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  let { id } = useParams();

  useEffect(() => {
    if (activeTeam !== null) {
      if (activeTeam.teamMembershipDTO?.role !== 'GUEST') {
        if (id) {
          fetchActivities(id);
        }
      }
    }
  }, [activeTeam, id]);

  async function fetchActivities(projectId: string) {
    try {
      setLoading(true);
      const { data } =
        await api.notification.getNotificationsForProject(projectId);
      if (data.result) {
        setActivities(
          data.result.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
        );

        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      toast.error(
        'Something really went wrong, you might want to contact support!'
      );
    }
  }

  const ACTIVE_EVENTS = [
    'PROJECT_CREATED',
    'PROJECT_MEMBER_JOINED',
    'PROJECT_MEMBER_REMOVED',
    'PROJECT_STATUS_CHANGED',
    'FILE_CREATED',
    'COMPOSER_LINKED',
    'COMPOSER_UNLINKED',
    'TRACK_CREATED',
    'TRACK_DELETED',
    'PROJECT_MEMBER_INVITED_EXTERNAL',
  ];

  // const statusOrder = ['DRAFT', 'REQUESTED', 'SUBMITTED', 'DONE'];

  return (
    <>
      {user && (
        <div className='flex grow flex-col items-center px-4 md:px-8 lg:px-16 xl:px-24'>
          <div className='mt-16 grid w-full grid-cols-3 gap-12 lg:gap-20'>
            <div className='col-span-full space-y-12 lg:col-span-2 '>
              <ActivitySection
                activities={activities}
                activeEvents={ACTIVE_EVENTS}
                className='mt-6'
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
