import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import {
  DocumentIcon,
  QuestionMarkCircleIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import axios from 'axios';
import { Fragment, useEffect, useState } from 'react';
import { FaCompactDisc, FaFileImport } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../../api';
import useDebounce from '../../../hooks/useDebounce';
import { useLocalStorage } from '../../../hooks/useLocalStorage';

interface IItem {
  name: string;
  icon?: any;
  shortcut?: string;
  id?: string;
  url: string;
  type: string;
}

const defaultItem: IItem | null = null;
const quickActions: IItem[] = [
  // {
  //   name: "Add new project...",
  //   icon: TrackPlusIcon,
  //   shortcut: "N",
  //   url: "#",
  //   type: "action",
  // },
  // {
  //   name: "Add new track...",
  //   icon: TrackPlusIcon,
  //   shortcut: "F",
  //   url: "#",
  //   type: "action",
  // },
  // {
  //   name: "Add hashtag...",
  //   icon: HashtagIcon,
  //   shortcut: "H",
  //   url: "#",
  //   type: "action",
  // },
  // {
  //   name: "Add label...",
  //   icon: TagIcon,
  //   shortcut: "L",
  //   url: "#",
  //   type: "action",
  // },
];

function getIconForType(type: string, active: boolean) {
  switch (type) {
    case 'file':
      return (
        <DocumentIcon
          className={classNames(
            'h-6 w-6 flex-none',
            active ? 'text-white' : 'text-gray-400'
          )}
          aria-hidden='true'
        />
      );
    case 'submission':
      return (
        <FaFileImport
          className={classNames(
            'h-6 w-6 flex-none',
            active ? 'text-white' : 'text-gray-400'
          )}
          aria-hidden='true'
        />
      );
    case 'track':
      return (
        <FaCompactDisc
          className={classNames(
            'h-6 w-6 flex-none',
            active ? 'text-white' : 'text-gray-400'
          )}
          aria-hidden='true'
        />
      );
    case 'user':
      return (
        <UserIcon
          className={classNames(
            'h-6 w-6 flex-none',
            active ? 'text-white' : 'text-gray-400'
          )}
          aria-hidden='true'
        />
      );
  }
  return DocumentIcon;
}
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function SearchBar({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}) {
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 200);
  // const [open, setOpen] = useState(true);
  const [files, setFiles] = useState<IItem[]>([]);
  const [projects, setProjects] = useState<IItem[]>([]);
  const [tracks, setTracks] = useState<IItem[]>([]);
  const [totalProjectsFound, setTotalProjectsFound] = useState(0);
  const [totalFilesFound, setTotalFilesFound] = useState(0);
  const [totalTracksFound, setTotalTracksFound] = useState(0);
  // const [loading, setLoading] = useState(false);

  const [recent, setRecent] = useLocalStorage<IItem[]>('recentSearches', []);

  let navigate = useNavigate();

  useEffect(() => {
    if (debouncedQuery.length > 0) {
      searchFiles(debouncedQuery);
      searchProjects(debouncedQuery);
      searchTracks(debouncedQuery);
    }
    // eslint-disable-next-line
  }, [debouncedQuery]);

  async function searchFiles(query: string) {
    try {
      // setLoading(true);
      const response = await api.file.searchFile({
        condition: {
          type: 'eq',
          key: 'name',
          value: query,
        },
        size: 5,
      });
      if (response.data.result) {
        setFiles(
          response.data.result.content.map((f) => ({
            name: f.name,
            url: `/submission/${f.projectId}/${f.id}`,
            id: f.id,
            type: 'file',
          }))
        );
        setTotalFilesFound(response.data.result.totalElements);
      }
      // setLoading(false);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  async function searchTracks(query: string) {
    try {
      // setLoading(true);
      const response = await api.track.searchTrack({
        condition: {
          type: 'eq',
          key: 'name',
          value: query,
        },
        size: 5,
      });
      if (response.data.result) {
        setTracks(
          response.data.result.content.map((f) => ({
            name: f.name,
            url: `/submission/${f.projectId}/${f.id}`,
            id: f.id,
            type: 'track',
          }))
        );
        console.log(tracks);
        setTotalTracksFound(response.data.result.totalElements);
      }
      // setLoading(false);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  async function searchProjects(query: string) {
    try {
      // setLoading(true);
      const response = await api.project.searchProject({
        condition: {
          type: 'eq',
          key: 'name',
          value: query,
        },
        size: 5,
      });
      if (response.data.result) {
        setProjects(
          response.data.result.content.map((p) => ({
            name: p.name,
            url: `/submission/${p.id}`,
            id: p.id,
            type: 'submission',
          }))
        );
        setTotalProjectsFound(response.data.result.totalElements);
      }
      // setLoading(false);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  return (
    <Transition.Root
      show={isOpen}
      as={Fragment}
      afterLeave={() => setQuery('')}
      appear
    >
      <Dialog as='div' className='relative z-10' onClose={setIsOpen}>
        <TransitionChild
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity' />
        </TransitionChild>

        <div className='fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20'>
          <TransitionChild
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <DialogPanel className='mx-auto max-w-2xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all'>
              <Combobox
                onChange={(item: IItem) => {
                  if (!item) {
                    return;
                  }
                  const recentMaxLength = 4;
                  const existingIndex = recent.findIndex(
                    (it) => it.id === item.id
                  );
                  console.log('Test ', existingIndex);
                  if (existingIndex === -1) {
                    if (recent.length === recentMaxLength) {
                      recent.pop();
                    }
                    setRecent([item, ...recent]);
                  } else {
                    recent.splice(existingIndex, 1);
                    setRecent([item, ...recent]);
                  }
                  navigate(item.url);
                  setIsOpen(false);
                }}
                value={defaultItem}
              >
                <div className='relative'>
                  <MagnifyingGlassIcon
                    className='pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400'
                    aria-hidden='true'
                  />
                  <ComboboxInput
                    autoComplete='off'
                    autoFocus
                    className='h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm'
                    placeholder='Search...'
                    onChange={(event) => setQuery(event.target.value)}
                  />
                </div>

                <ComboboxOptions
                  static
                  className='max-h-80 scroll-py-2 divide-y divide-gray-100 overflow-y-auto'
                >
                  {query === '' && recent.length > 0 && (
                    <div className='p-2'>
                      <h2 className='mt-2 mb-2 px-3 text-xs font-semibold text-gray-500'>
                        Recent searches
                      </h2>
                      <ul className='text-sm text-gray-700'>
                        {query === '' &&
                          recent.length > 0 &&
                          recent.map((recentObj) => (
                            <ComboboxOption
                              key={recentObj.id}
                              value={recentObj}
                              className={({ active }) =>
                                classNames(
                                  'flex cursor-default select-none items-center rounded-md px-3 py-2',
                                  active ? 'bg-indigo-600 text-white' : ''
                                )
                              }
                            >
                              {({ active }) => (
                                <>
                                  {getIconForType(recentObj.type, active)}
                                  <span className='ml-3 flex-auto truncate'>
                                    {recentObj.name}
                                  </span>
                                  {active && (
                                    <span className='ml-3 flex-none text-indigo-100'>
                                      Jump to...
                                    </span>
                                  )}
                                </>
                              )}
                            </ComboboxOption>
                          ))}
                      </ul>
                    </div>
                  )}
                  {query !== '' && totalFilesFound > 0 && (
                    <div className='p-2'>
                      <h2 className='mt-2 mb-2 px-3 text-xs font-semibold text-gray-500'>
                        Files{' '}
                        <span className='ml-2 rounded-lg bg-gray-300 p-0.5 px-2'>
                          {totalFilesFound}
                        </span>
                      </h2>
                      <ul className='text-sm text-gray-700'>
                        {query !== '' &&
                          files.map((file) => (
                            <ComboboxOption
                              key={file.id}
                              value={file}
                              className={({ active }) =>
                                classNames(
                                  'flex cursor-default select-none items-center rounded-md px-3 py-2',
                                  active ? 'bg-indigo-600 text-white' : ''
                                )
                              }
                            >
                              {({ active }) => (
                                <>
                                  {getIconForType(file.type, active)}
                                  <span className='ml-3 flex-auto truncate'>
                                    {file.name}
                                  </span>
                                  {active && (
                                    <span className='ml-3 flex-none text-indigo-100'>
                                      Jump to...
                                    </span>
                                  )}
                                </>
                              )}
                            </ComboboxOption>
                          ))}
                      </ul>
                    </div>
                  )}

                  {query !== '' && totalTracksFound > 0 && (
                    <div className='p-2'>
                      <h2 className='mt-2 mb-2 px-3 text-xs font-semibold text-gray-500'>
                        Tracks{' '}
                        <span className='ml-2 rounded-lg bg-gray-300 p-0.5 px-2'>
                          {totalTracksFound}
                        </span>
                      </h2>
                      <ul className='text-sm text-gray-700'>
                        {query !== '' &&
                          tracks.map((track) => (
                            <ComboboxOption
                              key={track.id}
                              value={track}
                              className={({ active }) =>
                                classNames(
                                  'flex cursor-default select-none items-center rounded-md px-3 py-2',
                                  active ? 'bg-indigo-600 text-white' : ''
                                )
                              }
                            >
                              {({ active }) => (
                                <>
                                  {getIconForType(track.type, active)}
                                  <span className='ml-3 flex-auto truncate'>
                                    {track.name}
                                  </span>
                                  {active && (
                                    <span className='ml-3 flex-none text-indigo-100'>
                                      Jump to...
                                    </span>
                                  )}
                                </>
                              )}
                            </ComboboxOption>
                          ))}
                      </ul>
                    </div>
                  )}
                  {query !== '' && totalProjectsFound > 0 && (
                    <div className='p-2'>
                      <h2 className='mt-2 mb-2 px-3 text-xs font-semibold text-gray-500'>
                        Submissions{' '}
                        <span className='ml-2 rounded-lg bg-gray-300 p-0.5 px-2'>
                          {totalProjectsFound}
                        </span>
                      </h2>
                      <ul className='text-sm text-gray-700'>
                        {query !== '' &&
                          projects.map((project) => (
                            <ComboboxOption
                              key={project.id}
                              value={project}
                              className={({ active }) =>
                                classNames(
                                  'flex cursor-default select-none items-center rounded-md px-3 py-2',
                                  active ? 'bg-indigo-600 text-white' : ''
                                )
                              }
                            >
                              {({ active }) => (
                                <>
                                  {getIconForType(project.type, active)}
                                  <span className='ml-3 flex-auto truncate'>
                                    {project.name}
                                  </span>
                                  {active && (
                                    <span className='ml-3 flex-none text-indigo-100'>
                                      Jump to...
                                    </span>
                                  )}
                                </>
                              )}
                            </ComboboxOption>
                          ))}
                      </ul>
                    </div>
                  )}
                  {query !== '' &&
                    files.length === 0 &&
                    projects.length === 0 &&
                    tracks.length === 0 && (
                      <div className='py-12 px-6 text-center sm:px-14'>
                        <QuestionMarkCircleIcon
                          className='mx-auto h-6 w-6 text-gray-400'
                          aria-hidden='true'
                        />
                        <p className='mt-4 text-sm text-gray-900'>
                          We couldn't find any anything with that term. Please
                          try again.
                        </p>
                      </div>
                    )}
                  {/* || files.length === 0 || projects.length === 0) */}
                  {/* TODO: use instant actions when nothing found -> you want to create project "xx" */}
                  {query === '' && quickActions.length > 0 && (
                    <div className='p-2'>
                      <h2 className='sr-only'>Quick actions</h2>
                      <ul className='text-sm text-gray-700'>
                        {quickActions.map((action) => (
                          <ComboboxOption
                            key={action.shortcut}
                            value={action}
                            className={({ active }) =>
                              classNames(
                                'flex cursor-default select-none items-center rounded-md px-3 py-2',
                                active ? 'bg-indigo-600 text-white' : ''
                              )
                            }
                          >
                            {({ active }) => (
                              <>
                                <action.icon
                                  className={classNames(
                                    'h-6 w-6 flex-none',
                                    active ? 'text-white' : 'text-gray-400'
                                  )}
                                  aria-hidden='true'
                                />
                                <span className='ml-3 flex-auto truncate'>
                                  {action.name}
                                </span>
                                <span
                                  className={classNames(
                                    'ml-3 flex-none text-xs font-semibold',
                                    active ? 'text-indigo-100' : 'text-gray-400'
                                  )}
                                >
                                  <kbd className='font-sans'>⌘</kbd>
                                  <kbd className='font-sans'>
                                    {action.shortcut}
                                  </kbd>
                                </span>
                              </>
                            )}
                          </ComboboxOption>
                        ))}
                      </ul>
                    </div>
                  )}
                </ComboboxOptions>
                {/* )} */}
              </Combobox>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
