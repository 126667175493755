import { IResponse } from '..';
import client from '../client';

export interface IComposer {
  id: string;
  teamId?: string;
  createdBy?: string;

  firstName: string;
  middleName: string;
  lastName: string;
  email: string;

  country: string;
  street: string;
  city: string;
  zip: string;
  state: string;

  ipi: string;
  pro: string;
  royaltyShare: number;
}

export interface INewComposer {
  teamId: string;

  firstName: string;
  middleName: string;
  lastName: string;
  email: string;

  country: string;
  street: string;
  city: string;
  zip: string;

  state: string;
  ipi: string;
  pro: string;
  royaltyShare: string;
}

export interface IComposerParticipation {
  composerId: string;
  trackId: string;

  createdAt: string;
  updatedAt: string;
}

export interface UpdateComposerForTrackInput {
  royaltyShare: number;
}

async function getComposers() {
  return client.get<IResponse<IComposer[]>>(`/composer`);
}

async function getTenantComposers(tenantId: string) {
  return client.get<IResponse<IComposer[]>>(`/composer/tenant:${tenantId}`);
}

async function getComposersByTrack(trackId: string) {
  return client.get<IResponse<IComposer[]>>(`/composer/track:${trackId}`);
}

async function addComposer(composer: INewComposer) {
  return client.post<IResponse<IComposer>>(`/composer`, composer);
}

async function deleteComposer(id: string) {
  return client.delete<IResponse<IComposer>>(`/composer/${id}`);
}

async function addComposerToTrack(composerId: string, trackId: string) {
  return client.post<IResponse<IComposerParticipation>>(
    `/composer/${composerId}/track`,
    {
      trackId,
    }
  );
}

async function removeComposerFromTrack(composerId: string, trackId: string) {
  return client.delete<IResponse<IComposerParticipation>>(
    `/composer/${composerId}/track/${trackId}`
  );
}

async function updateComposerPartially(
  composerId: string,
  composer: Partial<IComposer>
) {
  return client.patch<IResponse<IComposer>>(
    `/composer/${composerId}`,
    composer
  );
}

async function updateComposerForTrack(
  id: string,
  trackId: string,
  data: UpdateComposerForTrackInput
) {
  return client.put<IResponse<IComposer[]>>(
    `/composer/${id}/track/${trackId}`,
    data
  );
}

const api = {
  getComposers,
  getComposersByTrack,
  addComposer,
  deleteComposer,
  addComposerToTrack,
  removeComposerFromTrack,
  updateComposerPartially,
  updateComposerForTrack,
  getTenantComposers,
};

export default api;
