import { DateTime } from 'luxon';
import { getTagsForCSVExport } from './csvHelpers';

// Define the column names, values and order in the traceability report
export const exportMapping_HarvestMedia = [
  {
    name: 'LIBRARY: Name',
    value: 'Midnight Music',
  },
  {
    name: 'ALBUM: Code',
    key: 'album.metadata.albumCode',
  },
  {
    name: 'ALBUM: Identity',
    value: '',
  },
  {
    name: 'ALBUM: Title',
    key: 'album.name',
  },
  {
    name: 'ALBUM: Display Title',
    value: '',
  },
  {
    name: 'ALBUM: Description',
    key: 'album.description',
  },
  {
    name: 'ALBUM: Keywords',
    calculate: getTagsForCSVExport('albumTags', 'KEYWORD'),
  },
  {
    name: 'ALBUM: Tags',
    value: '',
  },
  {
    name: 'ALBUM: Styles',
    calculate: getTagsForCSVExport('albumTags', 'STYLE'),
  },
  {
    name: 'ALBUM: Release Date',
    calculate: (rowObject: any) => {
      const iso = rowObject['album.metadata.releaseDate'];
      if (!iso) return '';
      return DateTime.fromISO(iso).toFormat('dd/MM/yyyy');
    },
  },
  {
    name: 'ALBUM: Artwork Filename',
    value: '',
  },
  {
    name: 'TRACK: Title',
    key: 'track.name',
  },
  {
    name: 'TRACK: Display Title',
    key: 'track.name',
  },
  {
    name: 'TRACK: Alternate Title',
    value: '',
  },
  {
    name: 'TRACK: Description',
    key: 'track.description',
  },
  {
    name: 'TRACK: Number',
    key: 'track.metadata.trackNumber',
  },
  {
    name: 'TRACK: Is Main',
    calculate: function (rowObject: any) {
      if (!rowObject.track) {
        console.log('🚀 ~ file: columnDefinitions.ts:73 ~ track:', rowObject);
        return 'Y';
      }
      return rowObject.track.metadata.mainTrackNumber ? 'N' : 'Y';
    },
  },
  {
    name: 'TRACK: Main Track Number',
    key: 'track.metadata.mainTrackNumber',
  },
  {
    name: 'TRACK: Version',
    key: 'track.metadata.versionName',
  },
  {
    name: 'TRACK: Duration',
    key: 'track.metadata.duration',
  },
  {
    name: 'TRACK: BPM',
    key: 'track.metadata.bpm',
  },
  {
    name: 'TRACK: Tempo',
    calculate: function (rowObject: any) {
      if (rowObject['track.metadata.bpm'] <= 40) {
        return 'Very Slow';
      } else if (rowObject['track.metadata.bpm'] <= 60) {
        return 'Slow';
      } else if (rowObject['track.metadata.bpm'] <= 80) {
        return 'Medium Slow';
      } else if (rowObject['track.metadata.bpm'] <= 100) {
        return 'Medium';
      } else if (rowObject['track.metadata.bpm'] <= 120) {
        return 'Medium Fast';
      } else if (rowObject['track.metadata.bpm'] <= 160) {
        return 'Fast';
      } else if (rowObject['track.metadata.bpm'] > 160) {
        return 'Very Fast';
      } else {
        return '';
      }
    },
  },
  {
    name: 'TRACK: Genre',
    calculate: getTagsForCSVExport('tags', 'STYLE'),
  },
  {
    name: 'TRACK: Mixout',
    value: '',
  },
  {
    name: 'TRACK: Instrumentation',
    calculate: getTagsForCSVExport('tags', 'INSTRUMENT'),
  },
  {
    name: 'TRACK: Keywords',
    calculate: getTagsForCSVExport('tags', 'KEYWORD'),
  },
  {
    name: 'TRACK: Lyrics',
    key: 'tags.lyrics',
  },
  {
    name: 'TRACK: Identity',
    value: '',
  },
  {
    name: 'TRACK: Category Codes',
    value: '',
  },
  {
    name: 'TRACK: Composer(s)',
    value: '',
  },
  {
    name: 'TRACK: Publisher(s)',
    value: '',
  },
  {
    name: 'TRACK: Artist(s)',
    value: '',
  },
  {
    name: 'TRACK: Audio Filename',
    key: 'file.name',
  },
  {
    name: 'TRACK: Mood',
    calculate: getTagsForCSVExport('tags', 'MOOD'),
  },
  {
    name: 'TRACK: Music For',
    calculate: getTagsForCSVExport('tags', 'MUSICFOR'),
  },
  {
    name: 'ARTIST:1:First Name',
    value: '',
  },
  {
    name: 'ARTIST:1:Middle Name',
    value: '',
  },
  {
    name: 'ARTIST:1: Last Name',
    value: '',
  },
  {
    name: 'ARTIST:1: Society',
    value: '',
  },
  {
    name: 'ARTIST:1: IPI',
    value: '',
  },
  {
    name: 'WRITER:1: First Name',
    key: 'composers.0.firstName',
  },
  {
    name: 'WRITER:1: Middle Name',
    key: 'composers.0.middleName',
  },
  {
    name: 'WRITER:1: Last Name',
    key: 'composers.0.lastName',
  },
  {
    name: 'WRITER:1: Capacity',
    value: 'Composer/Author',
  },
  {
    name: 'WRITER:1: Society',
    key: 'composers.0.pro',
  },
  {
    name: 'WRITER:1: IPI',
    key: 'composers.0.ipi',
  },
  {
    name: 'WRITER:1: Territory',
    value: 'WORLD',
  },
  {
    name: 'WRITER:1: Owner Performance Share %',
    calculate: function (rowObject: any) {
      if (rowObject['composers.3.firstName']) {
        return '13';
      } else if (rowObject['composers.2.firstName']) {
        return '17';
      } else if (rowObject['composers.1.firstName']) {
        return '25';
      } else {
        return '50';
      }
    },
  },
  {
    name: 'WRITER:1: Owner Mechanical Share %',
    value: '0',
  },
  {
    name: 'WRITER:1: Collection Performance Share %',
    calculate: function (rowObject: any) {
      if (rowObject['composers.3.firstName']) {
        return '13';
      } else if (rowObject['composers.2.firstName']) {
        return '17';
      } else if (rowObject['composers.1.firstName']) {
        return '25';
      } else {
        return '50';
      }
    },
  },
  {
    name: 'WRITER:1: Collection Mechanical Share %',
    value: '0',
  },
  {
    name: 'WRITER:1: Original Publisher',
    value: 'Audiomode UG', //TODO: library.OGPublisher??
  },
  {
    name: 'WRITER:2: First Name',
    key: 'composers.1.firstName',
  },
  {
    name: 'WRITER:2: Middle Name',
    key: 'composers.1.middleName',
  },
  {
    name: 'WRITER:2: Last Name',
    key: 'composers.1.lastName',
  },
  {
    name: 'WRITER:2: Capacity',
    calculate: function (rowObject: any) {
      if (rowObject['composers.1.firstName']) {
        return 'Composer/Author';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:2: Society',
    key: 'composers.1.pro',
  },
  {
    name: 'WRITER:2: IPI',
    key: 'composers.1.ipi',
  },
  {
    name: 'WRITER:2: Territory',
    calculate: function (rowObject: any) {
      if (rowObject['composers.1.firstName']) {
        return 'WORLD';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:2: Owner Performance Share %',
    calculate: function (rowObject: any) {
      if (rowObject['composers.3.firstName']) {
        return '13';
      } else if (rowObject['composers.2.firstName']) {
        return '17';
      } else if (rowObject['composers.1.firstName']) {
        return '25';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:2: Owner Mechanical Share %',
    calculate: function (rowObject: any) {
      if (rowObject['composers.1.firstName']) {
        return '0';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:2: Collection Performance Share %',
    calculate: function (rowObject: any) {
      if (rowObject['composers.3.firstName']) {
        return '13';
      } else if (rowObject['composers.2.firstName']) {
        return '17';
      } else if (rowObject['composers.1.firstName']) {
        return '25';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:2: Collection Mechanical Share %',
    calculate: function (rowObject: any) {
      if (rowObject['composers.1.firstName']) {
        return '0';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:2: Original Publisher',
    calculate: function (rowObject: any) {
      if (rowObject['composers.1.firstName']) {
        return 'Audiomode UG';
      } else {
        return '';
      }
    }, //TODO: library.OGPublisher??
  },
  {
    name: 'WRITER:3: First Name',
    key: 'composers.2.firstName',
  },
  {
    name: 'WRITER:3: Middle Name',
    key: 'composers.2.middleName',
  },
  {
    name: 'WRITER:3: Last Name',
    key: 'composers.2.lastName',
  },
  {
    name: 'WRITER:3: Capacity',
    calculate: function (rowObject: any) {
      if (rowObject['composers.2.firstName']) {
        return 'Composer/Author';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:3: Society',
    key: 'composers.2.pro',
  },
  {
    name: 'WRITER:3: IPI',
    key: 'composers.2.ipi',
  },
  {
    name: 'WRITER:3: Territory',
    calculate: function (rowObject: any) {
      if (rowObject['composers.2.firstName']) {
        return 'WORLD';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:3: Owner Performance Share %',
    calculate: function (rowObject: any) {
      if (rowObject['composers.3.firstName']) {
        return '12';
      } else if (rowObject['composers.2.firstName']) {
        return '16';
      } else if (rowObject['composers.1.firstName']) {
        return '';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:3: Owner Mechanical Share %',
    calculate: function (rowObject: any) {
      if (rowObject['composers.2.firstName']) {
        return '0';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:3: Collection Performance Share %',
    calculate: function (rowObject: any) {
      if (rowObject['composers.3.firstName']) {
        return '12';
      } else if (rowObject['composers.2.firstName']) {
        return '16';
      } else if (rowObject['composers.1.firstName']) {
        return '';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:3: Collection Mechanical Share %',
    calculate: function (rowObject: any) {
      if (rowObject['composers.2.firstName']) {
        return '0';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:3: Original Publisher',
    calculate: function (rowObject: any) {
      if (rowObject['composers.2.firstName']) {
        return 'Audiomode UG';
      } else {
        return '';
      }
    }, //TODO: library.OGPublisher??
  },
  {
    name: 'WRITER:4: First Name',
    key: 'composers.3.firstName',
  },
  {
    name: 'WRITER:4: Middle Name',
    key: 'composers.3.middleName',
  },
  {
    name: 'WRITER:4: Last Name',
    key: 'composers.3.lastName',
  },
  {
    name: 'WRITER:4: Capacity',
    calculate: function (rowObject: any) {
      if (rowObject['composers.3.firstName']) {
        return 'Composer/Author';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:4: Society',
    key: 'composers.3.pro',
  },
  {
    name: 'WRITER:4: IPI',
    key: 'composers.3.ipi',
  },
  {
    name: 'WRITER:4: Territory',
    calculate: function (rowObject: any) {
      if (rowObject['composers.3.firstName']) {
        return 'WORLD';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:4: Owner Performance Share %',
    calculate: function (rowObject: any) {
      if (rowObject['composers.3.firstName']) {
        return '12';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:4: Owner Mechanical Share %',
    calculate: function (rowObject: any) {
      if (rowObject['composers.3.firstName']) {
        return '0';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:4: Collection Performance Share %',
    calculate: function (rowObject: any) {
      if (rowObject['composers.3.firstName']) {
        return '12';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:4: Collection Mechanical Share %',
    calculate: function (rowObject: any) {
      if (rowObject['composers.3.firstName']) {
        return '0';
      } else {
        return '';
      }
    },
  },
  {
    name: 'WRITER:4: Original Publisher',
    calculate: function (rowObject: any) {
      if (rowObject['composers.3.firstName']) {
        return 'Audiomode UG';
      } else {
        return '';
      }
    }, //TODO: library.OGPublisher??
  },
  {
    name: 'PUBLISHER:1: Name',
    value: 'Audiomode UG',
  },
  {
    name: 'PUBLISHER:1: Capacity',
    value: 'Original Publisher',
  },
  {
    name: 'PUBLISHER:1: Society',
    value: 'GEMA',
  },
  {
    name: 'PUBLISHER:1: IPI',
    value: '181891988',
  },
  {
    name: 'PUBLISHER:1: Territory',
    value: 'WORLD',
  },
  {
    name: 'PUBLISHER:1: Owner Performance Share %',
    value: '50',
  },
  {
    name: 'PUBLISHER:1: Owner Mechanical Share %',
    value: '100',
  },
  {
    name: 'PUBLISHER:1: Collection Performance Share %',
    value: '50',
  },
  {
    name: 'PUBLISHER:1: Collection Mechanical Share %',
    value: '100',
  },
  {
    name: 'PUBLISHER:1: Original Publisher',
    value: '',
  },
  {
    name: 'PUBLISHER:2: Name',
    value: '',
  },
  {
    name: 'PUBLISHER:2: Capacity',
    value: '',
  },
  {
    name: 'PUBLISHER:2: Society',
    value: '',
  },
  {
    name: 'PUBLISHER:2: IPI',
    value: '',
  },
  {
    name: 'PUBLISHER:2: Territory',
    value: '',
  },
  {
    name: 'PUBLISHER:2: Owner Performance Share %',
    value: '',
  },
  {
    name: 'PUBLISHER:2: Owner Mechanical Share %',
    value: '',
  },
  {
    name: 'PUBLISHER:2: Collection Performance Share %',
    value: '',
  },
  {
    name: 'PUBLISHER:2: Collection Mechanical Share %',
    value: '',
  },
  {
    name: 'PUBLISHER:2: Original Publisher',
    value: '',
  },
  {
    name: 'CODE: ISWC',
    value: '',
  },
  {
    name: 'CODE: ISRC',
    key: 'track.isrc',
  },
  {
    name: 'CODE: SGAE WORK NUMBER',
    value: '',
  },
  {
    name: 'CODE: GEMA WORK NUMBER',
    value: '',
  },
];
