import saveAs from 'file-saver';
import { DateTime } from 'luxon';
import {
  FaArrowLeft,
  FaCheck,
  FaEnvelope,
  FaFileArchive,
  FaFileCsv,
  FaFileDownload,
  FaSpinner,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import api from '../../../api';
import { exportAsCSV } from '../../../api/csv/csvExporter';
import { IFile } from '../../../api/services/file.service';
import {
  IProject,
  IProjectInvitation,
  IProjectMembership,
} from '../../../api/services/project.service';
import { ITeam } from '../../../api/services/team.service';

import JSZip from 'jszip';
import * as _ from 'lodash';
import { exportMapping_Cadenzabox } from '../../../api/csv/columnDefinitionsCadenzabox';
import { exportMapping_HarvestMedia } from '../../../api/csv/columnDefinitionsHarverstMedia';
import { ITrack } from '../../../api/services/track.service';
import { useAuth } from '../../../hooks/useAuth';
import { classNames, flattenObject } from '../../../utils';
import ExportOptionMenu from './actionBar/ExportOptionMenu';
import { IStateProp } from '../studioState';
import { useState } from 'react';
import { ConfirmRejectionModal } from '../dialogs/ConfirmRejectionModal';
import {
  getStemFilenameSubmission,
  getWavFilenameSubmission,
} from '../../../utils/fileHelper';

interface IFileMap {
  [key: string]: IFile[];
}

function buildFileMap(files: IFile[]) {
  const map: IFileMap = {};
  files.forEach((file) => {
    if (!map[file.parentId]) {
      map[file.parentId] = [file];
    } else {
      map[file.parentId] = [...map[file.parentId], file];
    }
  });
  return map;
}

const supportedExportFormats = {
  harvestMedia: exportMapping_HarvestMedia,
  cadenzabox: exportMapping_Cadenzabox,
};

export function ActionBar({
  activeProjectRole,
  activeProject,
  activeProjectMembers,
  activeProjectInvites,
  activeProjectFiles,
  trackUpdateLoading,
  refreshData,
  onSubmit,
  updateProjectStatus,
  isTourActive,
}: {
  activeProjectRole: string | null;
  activeProject: IProject;
  activeProjectMembers: IProjectMembership[];
  activeProjectInvites: IProjectInvitation[];
  activeProjectFiles: IFile[];
  trackUpdateLoading: boolean;
  refreshData: (state: IStateProp) => void;
  onSubmit: () => void;
  updateProjectStatus(
    status: string,
    message?: string,
    cb?: () => void
  ): Promise<void>;
  isTourActive: boolean;
}) {
  let navigate = useNavigate();
  let { activeTeam } = useAuth();
  const [isConfirmRejectionModalOpened, setIsConfirmRejectionModalOpened] =
    useState(false);
  const [isExportRunning, setIsExportRunning] = useState(false);

  function isPublisher(role: string | null) {
    return role === 'OWNER';
  }

  function isRoleLoaded(role: string | null) {
    return role !== null && role !== '' && role !== undefined;
  }

  async function exportFilesAsZip(
    team: ITeam,
    fileRole: 'MAIN' | 'MASTER' | 'STEMS'
  ) {
    setIsExportRunning(true);
    const projectId = activeProject.id;

    const [tracksRes, filesRes] = await Promise.all([
      api.track.getTracksByProject(projectId),
      api.file.getFilesByProject(projectId),
    ]);

    const files = filesRes.data.result;
    const tracks = tracksRes.data.result;

    const tracksMap = tracks.reduce(
      (
        acc: {
          [key: string]: ITrack;
        },
        track
      ) => {
        acc[track.id] = track;
        return acc;
      },
      {}
    );

    var zip = new JSZip();

    await Promise.all(
      files
        .filter((f) => f.role === fileRole)
        .map(async (file) => {
          const urlResponse = await api.file.getFilePresignedDownloadUrl(
            file.id
          );
          const response = await fetch(urlResponse.data.result.presignedUrl);
          const fileBlob = await response.blob();
          const track = tracksMap[file.parentId];

          let filename = '';
          if (file.role === 'MAIN' || file.role === 'MASTER') {
            filename = getWavFilenameSubmission(track, team, file);
          } else if (file.role === 'STEMS') {
            filename = getStemFilenameSubmission(track, team);
          } else {
            console.error('Unknown file role', file);
          }
          zip.file(filename, fileBlob);
        })
    );

    const zipBlob = await zip.generateAsync({ type: 'blob' });
    const suffix =
      fileRole === 'MAIN'
        ? 'raw'
        : fileRole === 'MASTER'
          ? 'mastered'
          : 'stems';
    const filename = `${activeProject.name}_${suffix}_${DateTime.now().toISODate()}.zip`;

    saveAs(zipBlob, filename);
    setIsExportRunning(false);
  }

  async function exportSubmission(
    exportFormat: keyof typeof supportedExportFormats,
    team: ITeam
  ) {
    const projectId = activeProject.id;

    const [projectObjResponse, projectTracksResponse, projectFilesResponse] =
      await Promise.all([
        api.project.getProject(projectId),
        api.track.getTracksByProject(projectId),
        api.file.getFilesByProject(projectId),
      ]);

    const projectObj = projectObjResponse.data.result;
    const projectTracks = projectTracksResponse.data.result;
    const projectFiles = projectFilesResponse.data.result;

    const fileMap = buildFileMap(projectFiles);

    const rowObjects = await Promise.all(
      projectTracks.map((track) =>
        getTrackInfo(track, projectObj, fileMap[track.id], team)
      )
    );

    const csvSeparator = exportFormat === 'cadenzabox' ? ',' : ';';
    const multipleObjectDelimiter = exportFormat === 'cadenzabox' ? ';' : ',';
    const csvString = exportAsCSV(
      rowObjects,
      supportedExportFormats[exportFormat],
      csvSeparator,
      multipleObjectDelimiter
    );
    const blob = new Blob([csvString], { type: 'text/csv' });
    saveAs(
      blob,
      `${activeProject.name}_${exportFormat}_${DateTime.now().toISODate()}.csv`
    );
  }

  async function getTrackInfo(
    track: ITrack,
    project: IProject,
    files: IFile[],
    team: ITeam
  ) {
    const [trackTagResponse, trackComposerResponse] = await Promise.all([
      api.tag.getTagsByObject(track.id),
      api.composer.getComposersByTrack(track.id),
    ]);
    const allRelatedTags = trackTagResponse.data.result;
    const groupedTags = _.groupBy(allRelatedTags, 'type');
    const composers = trackComposerResponse.data.result;
    return flattenObject({
      library: team,
      track: track,
      submission: project,
      file: files,
      tags: groupedTags,
      composers,
    });
  }

  return (
    <div className='flex flex-row justify-between'>
      <button
        className='flex items-center space-x-2 rounded bg-indigo-600 px-4 py-1 font-semibold text-white hover:bg-indigo-800'
        onClick={() => navigate(-1)}
      >
        <FaArrowLeft />
        <div>Back</div>
      </button>
      <div className='flex items-center space-x-4'>
        {isRoleLoaded(activeProjectRole) &&
          isPublisher(activeProjectRole) &&
          activeProject.status === 'DONE' && (
            <ExportOptionMenu
              btnLabel={
                <>
                  <div>Download</div>
                  {isExportRunning && <FaSpinner className='animate-spin' />}
                  {!isExportRunning && <FaFileDownload />}
                </>
              }
              items={[
                {
                  label: 'Download raw files',
                  icon: (
                    <FaFileArchive
                      className='mr-2 h-5 w-5'
                      aria-hidden='true'
                    />
                  ),
                  onClick: () =>
                    activeTeam && exportFilesAsZip(activeTeam, 'MAIN'),
                },
                {
                  label: 'Download stem files',
                  icon: (
                    <FaFileArchive
                      className='mr-2 h-5 w-5'
                      aria-hidden='true'
                    />
                  ),
                  onClick: () =>
                    activeTeam && exportFilesAsZip(activeTeam, 'STEMS'),
                },
                {
                  label: 'Download mastered files',
                  icon: (
                    <FaFileArchive
                      className='mr-2 h-5 w-5'
                      aria-hidden='true'
                    />
                  ),
                  onClick: () =>
                    activeTeam && exportFilesAsZip(activeTeam, 'MASTER'),
                },
              ]}
            />
          )}
        {activeTeam != null &&
          isRoleLoaded(activeProjectRole) &&
          isPublisher(activeProjectRole) &&
          activeProject.status === 'DONE' && (
            <ExportOptionMenu
              items={[
                {
                  label: 'Export Cadenzabox',
                  icon: (
                    <FaFileCsv className='mr-2 h-5 w-5' aria-hidden='true' />
                  ),
                  onClick: () =>
                    activeTeam && exportSubmission('cadenzabox', activeTeam),
                },
                {
                  label: 'Export Harvest Media',
                  icon: (
                    <FaFileCsv className='mr-2 h-5 w-5' aria-hidden='true' />
                  ),
                  onClick: () =>
                    activeTeam && exportSubmission('harvestMedia', activeTeam),
                },
              ]}
            />
          )}
        {isRoleLoaded(activeProjectRole) &&
          isPublisher(activeProjectRole) &&
          activeProject.status === 'SUBMITTED' && (
            <div className='flex justify-end space-x-4'>
              <button
                onClick={() => updateProjectStatus('DONE')}
                className='flex items-center space-x-2 rounded bg-emerald-600 px-4 py-1 font-semibold text-white hover:bg-emerald-800 disabled:bg-slate-300 disabled:text-slate-200 dark:disabled:bg-slate-600 dark:disabled:text-slate-700'
                data-testid='accept-submission-btn'
              >
                <div>Accept</div>
              </button>
              <button
                onClick={() => setIsConfirmRejectionModalOpened(true)}
                className='flex items-center space-x-2 rounded bg-red-600 px-4 py-1 font-semibold text-white hover:bg-red-800 disabled:bg-slate-300 disabled:text-slate-200 dark:disabled:bg-slate-600 dark:disabled:text-slate-700'
                data-testid='reject-submission-btn'
              >
                <div>Reject</div>
              </button>
            </div>
          )}
        {(isTourActive || activeProject.status === 'REQUESTED') && (
          <div className='flex justify-end space-x-4'>
            <button
              id='submit-project'
              disabled={activeProjectFiles.length === 0}
              onClick={isTourActive ? undefined : onSubmit}
              className='flex items-center space-x-2 rounded bg-emerald-600 px-4 py-1 font-semibold text-white hover:bg-emerald-800 disabled:bg-slate-300 disabled:text-slate-200 dark:disabled:bg-slate-600 dark:disabled:text-slate-700'
            >
              <div>Submit</div>
              <FaEnvelope />
            </button>
          </div>
        )}
        {isRoleLoaded(activeProjectRole) &&
          isPublisher(activeProjectRole) &&
          activeProject.status === 'DRAFT' && (
            <div className='flex justify-end space-x-4'>
              <button
                // TODO: fix workaround
                disabled={
                  activeProjectMembers.length < 2 &&
                  activeProjectInvites.length < 1
                }
                onClick={() => updateProjectStatus('REQUESTED')}
                className='flex items-center space-x-2 rounded bg-emerald-600 px-4 py-1 font-semibold text-white hover:bg-emerald-800 disabled:bg-slate-300 disabled:text-slate-200 dark:disabled:bg-slate-600 dark:disabled:text-slate-700'
              >
                <div>Request</div>
                <FaEnvelope />
              </button>
            </div>
          )}
        <div className='flex justify-end space-x-4'>
          <button
            disabled={!trackUpdateLoading}
            className={classNames(
              'flex  items-center space-x-2 rounded bg-emerald-600 px-4 py-2 font-semibold text-white hover:bg-emerald-800 disabled:bg-slate-300 disabled:text-slate-200 dark:disabled:bg-slate-600 dark:disabled:text-slate-700',
              trackUpdateLoading ? 'bg-sky-600' : ''
            )}
          >
            {trackUpdateLoading ? (
              <FaSpinner className='animate-spin' />
            ) : (
              <FaCheck />
            )}
          </button>
        </div>
      </div>
      <ConfirmRejectionModal
        isOpened={isConfirmRejectionModalOpened}
        setIsOpened={setIsConfirmRejectionModalOpened}
        onSubmit={(msg: string) => {
          updateProjectStatus('REJECTED', msg, () => {
            setIsConfirmRejectionModalOpened(false);
          });
        }}
      />
    </div>
  );
}
