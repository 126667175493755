import React, { PropsWithChildren } from 'react';
import { classNames } from '../../utils';
import { globalThemeStyles } from '../../config/globalStyles';
import { FaFileImport, FaPlusSquare } from 'react-icons/fa';

interface Props {
  className?: string;
  onClickAdd?: () => void;
  title: string;
  headerChildren?: JSX.Element;
  icon?: JSX.Element;
  testid?: string;
}

export const Card = ({
  children,
  className,
  onClickAdd,
  title,
  headerChildren,
  icon,
  testid = '',
}: PropsWithChildren<Props>) => {
  return (
    <div
      className={classNames(
        `${className} flex w-full flex-col overflow-hidden rounded-2xl shadow-xl`,
        globalThemeStyles.box
      )}
    >
      <div className='flex w-full items-center justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-800'>
        <div className='flex items-center'>
          <div className='mr-10 flex items-center rounded-lg'>
            <div className=''>
              {icon || <FaFileImport size={20} className='text-indigo-700' />}
            </div>
            <span className='ml-2 flex text-lg font-medium leading-6 '>
              {title}
            </span>
          </div>
        </div>
        <div className='flex items-center gap-x-8'>
          {headerChildren}
          {onClickAdd && (
            <div className=''>
              <div
                className='tour-1-step1 cursor-pointer text-indigo-600'
                onClick={onClickAdd}
                data-testid={`${testid}-add-button`}
              >
                <FaPlusSquare size={24} />
              </div>
            </div>
          )}
        </div>
      </div>
      {children}
    </div>
  );
};
