//Return the access token stored in localstorage
function getAccessToken() {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    return JSON.parse(accessToken);
  }
  return '';
}

//Return the access token stored in localstorage
function getRefreshToken() {
  const refreshToken = localStorage.getItem('refreshToken');
  if (refreshToken) {
    return JSON.parse(refreshToken);
  }
  return '';
}

//Update the access token stored in local storage with new access token
function updateAccessToken(accessToken: string) {
  localStorage.setItem('accessToken', JSON.stringify(accessToken));
}

function deleteTokens() {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
}

function getLastActiveTeam() {
  const tenant = localStorage.getItem('lastActiveTeamId');
  if (tenant) {
    return JSON.parse(tenant);
  }
  return '';
}

const handler = {
  getAccessToken,
  getRefreshToken,
  updateAccessToken,
  deleteTokens,
  getLastActiveTeam,
};

export default handler;
