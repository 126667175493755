import InputMask, { BeforeMaskedStateChangeStates } from 'react-input-mask';

interface Props {
  value: string;
  className?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  disabled: boolean;
  maskPlaceholder?: string;
  mask?: string;
}

export default function MaskedInput({
  value,
  className,
  placeholder,
  onChange,
  disabled,
  maskPlaceholder = '00:00',
  mask = '99:99',
}: Props) {
  function beforeMaskedStateChange({
    nextState,
  }: BeforeMaskedStateChangeStates) {
    return {
      ...nextState,
      value: !nextState.value
        ? nextState.value
        : nextState.value
            .split(':')
            .map((v) => String(+v > 59 ? 59 : v))
            .join(':'),
    };
  }

  return (
    <InputMask
      type='text'
      mask={mask}
      maskPlaceholder={maskPlaceholder}
      onChange={(e) => onChange(e.target.value)}
      value={value}
      className={className}
      placeholder={placeholder}
      disabled={disabled}
      onBlur={() => {
        if (value === maskPlaceholder) {
          onChange('');
        }
      }}
      beforeMaskedStateChange={beforeMaskedStateChange}
    />
  );
}
