import React, { ChangeEventHandler } from 'react';
import useDebounce from './useDebounce';

interface UseSeacrhInput<T> {
  data: T[];
  filterFn: (searchString: string) => (v: T) => boolean;
  delay?: number;
}

export const useSearch = <T>({
  data,
  filterFn,
  delay = 400,
}: UseSeacrhInput<T>) => {
  const [searchString, setSearchString] = React.useState('');

  const debouncedSearchString = useDebounce(searchString, delay);

  const onSearchChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchString(e.target.value);
  };

  return {
    found: data.filter(filterFn(debouncedSearchString.toLowerCase())),
    onSearchChange,
    searchString,
    setSearchString,
  };
};
