import { PropsWithChildren } from 'react';

interface CellProps {
  label: string;
  className?: string;
}

export const Cell = ({
  label,
  children,
  className = ' ',
}: PropsWithChildren<CellProps>) => (
  <div className={className}>
    <div className='flex items-center text-sm font-medium dark:text-slate-200 '>
      {label}
    </div>
    <div className='flex items-center text-lg font-medium'>{children}</div>
  </div>
);
