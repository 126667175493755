import { useAuth } from '../hooks/useAuth';

import axios from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from '../api';
import { IProject } from '../api/services/project.service';
import AlbumSection from '../components/projects/AlbumSection';
import { useTeamLogo } from '../hooks/useTeamLogo';

export default function Albums() {
  const { user, activeTeam } = useAuth();
  const { tenantLandingImageUrl } = useTeamLogo();

  const [albums, setAlbums] = useState<IProject[]>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (activeTeam !== null) {
      fetchAlbums();
    }
  }, [activeTeam]);

  async function fetchAlbums() {
    try {
      setLoading(true);
      const { data } = await api.album.getAlbums();
      if (data.result) {
        setAlbums(data.result);
        setLoading(false);
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  return (
    <>
      {user && (
        <div className='flex grow flex-col items-center'>
          {activeTeam && (
            <>
              {tenantLandingImageUrl && (
                <div className=''>
                  <img
                    className='mt-4 max-h-16'
                    src={tenantLandingImageUrl}
                    alt='tenantLandingImageUrl'
                  />
                </div>
              )}
              <div className='mt-20 flex w-full px-5 md:mt-8 md:px-20 lg:px-36'>
                <AlbumSection
                  className='h-128'
                  albums={albums?.sort((a, b) =>
                    a.createdAt > b.createdAt ? -1 : 1
                  )}
                  loading={loading}
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}
