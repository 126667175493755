/**
 * prepare a string to be put into a csv cell
 * @param {string} str string to be prepared for a csv cell
 * @param {string} separator character used to separate tags inside a cell
 * @returns escaped string
 */
function stringToCSVCell(str: string, separator: string) {
  if (str == null) {
    return '';
  }
  // replace the internal separator (tags) with the specified one
  str = str.replace(/,|;/g, separator);
  // if escapable chars are included
  const mustQuote =
    str.indexOf(',') > -1 ||
    str.indexOf(';') > -1 ||
    str.indexOf('"') > -1 ||
    str.indexOf('\r') > -1 ||
    str.indexOf('\n') > -1;
  if (mustQuote) {
    let string = '"';
    for (let c of str) {
      if (c === '"') {
        string += '"';
      }
      string += c;
    }
    string += '"';
    return string;
  }
  return str;
}

/**
 * map object rows to ordered array of fields
 * @param {*} rowObject rows as object
 * @param {*} columnDefinitions ordered array implying the position of a field in a row
 * @returns ordered array containing all fields of a row
 */
function transformRowObjectToOrderedRowArray(
  rowObject: any,
  columnDefinitions: {
    name: string;
    key?: string;
    value?: string;
    calculate?: (rowObject: any) => string;
  }[]
) {
  return columnDefinitions.map((column) => {
    if (column.calculate !== undefined) {
      console.log(
        '🚀 ~ file: csvExporter.ts:47 ~ returncolumnDefinitions.map ~ column:',
        column
      );
      return column.calculate(rowObject);
    }
    if (column.key !== undefined) {
      if (rowObject[column.key] !== undefined) {
        return '' + rowObject[column.key]; // royaltyshare is int so int to string here
      } else {
        return '';
      }
    }
    if (column.value !== undefined) {
      return column.value;
    }
    return '';
  });
}

/**
 * map an ordered array of strings to a csv row string
 * @param {string[]} row ordered array containing all fields of a row
 * @param {string} cellSeparator character used to separate cells
 * @param {string} tagSeparator character used to separate tags inside a cell
 * @returns csv row string
 */
function toCSVRow(row: string[], cellSeparator: string, tagSeparator: string) {
  return row
    .map((str) => stringToCSVCell(str, tagSeparator))
    .join(cellSeparator);
}

/**
 * export row objects to an ordered csv file
 * @param {*} rowObjects rows object
 * @param {*} columnDefinitions ordered array implying the position of a field in a row
 * @param {string} cellSeparator character used to separate cells
 * @param {string} tagSeparator character used to separate tags inside a cell
 * @returns final csv string representation of the traceability data
 */
export function exportAsCSV(
  rowObjects: any[],
  columnDefinitions: {
    name: string;
    key?: string;
    value?: string;
    calculate?: (rowObject: any) => string;
  }[],
  cellSeparator: string = ';',
  tagSeparator: string = ','
) {
  // create headers
  const csvHeaders =
    columnDefinitions.map((column) => column.name).join(cellSeparator) + '\n';
  // order row fields
  const orderedRows = rowObjects.map((rowObject) =>
    transformRowObjectToOrderedRowArray(rowObject, columnDefinitions)
  );
  console.log('🚀 ~ file: csvExporter.ts:94 ~ orderedRows:', orderedRows);
  // escape csv special chars
  const csvRows = orderedRows.map((row) =>
    toCSVRow(row, cellSeparator, tagSeparator)
  );
  return csvHeaders + csvRows.join('\n');
}
