import React, { PropsWithChildren } from 'react';
import { FaFolderPlus } from 'react-icons/fa';

interface Props {
  onClickPlus?: () => void;
  title: string;
  icon: React.ReactNode;
  className?: string;
}

export const Sidebar = ({
  onClickPlus,
  title,
  children,
  icon,
  className,
}: PropsWithChildren<Props>) => {
  return (
    <div className='flex grow flex-col overflow-hidden rounded-2xl bg-white shadow-xl dark:bg-slate-900'>
      <div className='flex items-center justify-between rounded-t-2xl border-b border-gray-200 bg-gray-50 p-2 px-4 dark:border-gray-600 dark:bg-gray-800'>
        <div className='flex flex-grow items-center'>
          <div className='mr-10 flex items-center bg-gray-50 dark:bg-gray-800'>
            <div className='flex'>{icon}</div>
            <div className='ml-2 flex text-lg font-medium leading-6 text-slate-700 dark:text-slate-200'>
              {title}
            </div>
          </div>
        </div>
        {onClickPlus && (
          <div className='flex space-x-2'>
            <div
              className='cursor-pointer text-indigo-600'
              onClick={onClickPlus}
              id='add-track-btn'
            >
              <FaFolderPlus size={16} />
            </div>
          </div>
        )}
      </div>
      <div
        className={`MiniScrollbar flex max-h-64 flex-col overflow-auto md:max-h-full md:grow md:basis-0 ${className}`}
      >
        {children}
      </div>
    </div>
  );
};
