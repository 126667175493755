export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export function flattenObject(ob: any) {
  let toReturn: any = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] == 'object' && ob[i] !== null) {
      var flatObject = flattenObject(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + '.' + x] =
          flatObject[x] === undefined || flatObject[x] === null
            ? ''
            : flatObject[x];
      }
    } else {
      toReturn[i] = ob[i] === undefined || ob[i] === null ? '' : ob[i];
    }
  }
  return toReturn;
}

export const isEmail = (v: string) =>
  // eslint-disable-next-line
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
    v
  );

export function splitArray(array: any[], condition: (obj: any) => boolean) {
  const result: any[][] = [[], []];

  array.forEach((element) => {
    result[condition(element) ? 0 : 1].push(element);
  });

  return result;
}

/**
 * Checks whether a given actual content type is allowed based on the allowed content type.
 *
 * @param {string} allowed - The allowed content type (e.g., 'image/*').
 * @param {string} actual - The actual content type to check (e.g., 'image/png').
 * @returns {boolean} Returns `true` if the actual content type is allowed, `false` otherwise.
 */
export const isContentTypeAllowed = (allowed: string, actual: string) => {
  const allowedSplit = allowed.split('/');
  const actualSplit = actual.split('/');
  if (allowedSplit[1] === '*') {
    if (actualSplit[0] === allowedSplit[0]) {
      return true;
    }
  } else {
    if (
      actualSplit[0] === allowedSplit[0] &&
      actualSplit[1] === allowedSplit[1]
    ) {
      return true;
    }
  }
  return false;
};

export const isFileExtensionAllowed = (allowed: string[], actual: string) => {
  const actualSplit = actual.split('.');
  if (actualSplit.length > 1) {
    const actualExtension = '.' + actualSplit[actualSplit.length - 1];
    if (allowed.indexOf(actualExtension) > -1) {
      return true;
    }
  }
  return false;
};
export function generateRandomString(length: number) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}
