import { FaTimes, FaUserAstronaut } from 'react-icons/fa';
import {
  IProjectInvitation,
  IProjectMembership,
} from '../../../api/services/project.service';
import GenericDialog from '../../shared/dialog/GenericDialog';
import ProjectSettingsUserSection from './ProjectSettingsUserSection';

export default function ProjectMembersDialog({
  isOpen,
  setIsOpen,
  projectMembers,
  projectInvites,
  fetchProjectMembers,
  fetchProjectInvites,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  projectMembers: IProjectMembership[];
  projectInvites: IProjectInvitation[];
  fetchProjectMembers: () => void;
  fetchProjectInvites: () => void;
}) {
  return (
    <>
      <GenericDialog
        onUpperRightClick={() => setIsOpen(false)}
        upperRightIcon={
          <FaTimes size={20} className='text-gray-600 dark:text-gray-300' />
        }
        header='Artist'
        headerIcon={<FaUserAstronaut size={20} />}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <ProjectSettingsUserSection
          projectMembers={projectMembers}
          projectInvites={projectInvites}
          fetchProjectInvites={fetchProjectInvites}
          fetchProjectMembers={fetchProjectMembers}
        />
      </GenericDialog>
    </>
  );
}
