import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

export default function GenericDialog({
  isOpen,
  setIsOpen,
  header,
  headerIcon,
  onUpperRightClick,
  upperRightIcon,
  children,
  footer,
  className = '',
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  header: string;
  headerIcon?: React.ReactNode;
  onUpperRightClick?: () => void;
  upperRightIcon?: React.ReactNode;
  children: React.ReactNode;
  footer?: React.ReactNode;
  className?: string;
}) {
  return (
    <Transition show={isOpen} as={'div'}>
      <Dialog
        as='div'
        className='relative z-10'
        onClose={() => setIsOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel
                className={`w-full max-w-xl transform rounded-lg bg-white py-4 px-4 text-left align-middle shadow-xl transition-all dark:bg-gray-800 lg:py-6 lg:px-6 ${className}`}
              >
                <div className='flex items-center justify-between'>
                  <div className='flex items-center text-xl font-semibold leading-6 text-slate-700 dark:text-slate-200'>
                    {headerIcon}
                    <span className='ml-2'>{header}</span>
                  </div>
                  {upperRightIcon && (
                    <button onClick={onUpperRightClick}>
                      {upperRightIcon}
                    </button>
                  )}
                </div>
                <div className='mt-5 space-y-6'>{children}</div>
                <div className='mt-4 flex justify-end'>
                  {footer && footer}
                  {!footer && (
                    <button
                      className='rounded bg-indigo-600 p-2 px-4 font-semibold text-white hover:bg-indigo-800'
                      onClick={() => setIsOpen(false)}
                    >
                      Done
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
