import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../../../api';
import { IProject } from '../../../../api/services/project.service';
import { ITag, ITagType } from '../../../../api/services/tag.service';
import { useAlbumStudioState } from '../../albumState';
import { useTags } from '../../../../hooks/useTags';

export function AlbumTagsPicker({ activeAlbum }: { activeAlbum: IProject }) {
  const { id } = useParams<{
    id: string;
  }>();

  const { activeActiveAlbumTags, setActiveAlbumTags } = useAlbumStudioState();
  const [searchString, setSearchString] = useState('');

  const {
    activeTagType,
    setActiveTagType,
    activeTags,
    setActiveTags,
    allTags,
    selectableTagTypes,
  } = useTags();

  useEffect(() => {
    if (id) {
      api.tag.getTagsByObject(id).then((res) => {
        setActiveTags(res.data.result);
      });
    }
    // eslint-disable-next-line
  }, [id]);

  const currentTags = allTags.filter((tag) => {
    return tag.type === activeTagType?.name;
  });

  const filteredTags = !!searchString
    ? currentTags.filter((tag) => {
        return tag.name.toLowerCase().includes(searchString.toLowerCase());
      })
    : currentTags;

  const currentActiveTags = activeTags.filter(
    (tag) => tag.type === activeTagType?.name
  );

  async function removeTagFromAlbum(tag: ITag) {
    try {
      await api.tag.removeTagFromObject(tag.id, activeAlbum.id);
      setActiveAlbumTags([
        ...activeActiveAlbumTags.filter((x) => x.id !== tag.id),
      ]);
    } catch (error) {}
  }

  async function addTagToAlbum(tag: ITag) {
    try {
      await api.tag.addTagToObject(tag.id, 'trackCollection', activeAlbum.id);
      setActiveAlbumTags([...activeActiveAlbumTags, tag]);
    } catch (error) {}
  }

  function handleTagSelect(tag: ITag) {
    let index = activeTags.findIndex((tagObj) => tagObj.name === tag.name);

    if (index !== -1) {
      setActiveTags(activeTags.filter((x) => x.name !== tag.name));
      removeTagFromAlbum(tag);
    } else {
      if (activeTagType && currentActiveTags.length < activeTagType?.tagsMax) {
        setActiveTags([...activeTags, tag]);

        addTagToAlbum(tag);
      } else {
        toast.error('Tags Tag limit reached');
      }
    }
  }

  function showTagCount(tagType: ITagType) {
    const activeTagsForTypes = activeTags.filter(
      (tag) => tag.type === tagType.name
    );
    const isFull = activeTagsForTypes.length >= tagType.tagsMax;
    const isEnough = activeTagsForTypes.length >= tagType.tagsMin;
    return (
      <div
        className={`text-md ml-1 select-none ${
          isFull
            ? 'text-emerald-500'
            : isEnough
              ? 'text-yellow-500'
              : 'text-red-500'
        } `}
      >
        ( {activeTags.filter((tag) => tag.type === tagType.name).length} /{' '}
        {tagType.tagsMax} )
      </div>
    );
  }

  const onClickCategory = (tagType: ITagType) => () => {
    setActiveTagType(tagType);
  };

  return (
    <div className='flex w-full flex-col'>
      <div className={`cursor-pointer rounded-md p-2`}>
        <div className='flex h-full w-full items-center justify-around px-1'>
          <div className='animate flex flex-wrap justify-start  duration-1000'>
            {selectableTagTypes.map((tagType) => {
              return (
                <div
                  key={tagType.name}
                  onClick={onClickCategory(tagType)}
                  className={`${
                    activeTagType?.id === tagType.id ? 'bg-slate-500' : ''
                  } animate col-span-1 row-span-1 mx-2 flex cursor-pointer items-center rounded-md border border-gray-200 px-2 transition-all duration-200 dark:border-gray-600 dark:hover:bg-slate-600`}
                >
                  <div className='flex w-full flex-col items-center justify-center px-1 '>
                    <div className='text-md font-semibold text-gray-600 dark:text-gray-300'>
                      {tagType.displayName}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {activeTagType && showTagCount(activeTagType)}
          <div>
            <input
              onChange={(event) => setSearchString(event.target.value)}
              placeholder='Search Tags'
              value={searchString}
              onClick={(e) => e.stopPropagation()}
              className='h-8 rounded-md border-2 border-gray-100 pl-3 pr-10 text-sm leading-5 focus:ring-0 dark:border-gray-800 dark:bg-slate-800'
            />
          </div>
        </div>
      </div>
      <div className='MiniScrollbar my-1 h-32 overflow-auto rounded-md border border-gray-200 px-2 dark:border-gray-600'>
        <div className={`mt-1 flex cursor-pointer flex-wrap justify-start`}>
          {filteredTags.map((tag, index) => {
            return (
              <div
                key={index}
                onClick={() => handleTagSelect(tag)}
                className={`my-1 mx-1 flex h-6 cursor-pointer select-none items-center rounded-md px-2 text-center text-sm font-medium text-white ${
                  activeTags.findIndex((tagObj) => tagObj.name === tag.name) >
                  -1
                    ? 'bg-blue-700 dark:bg-blue-600 dark:text-white'
                    : 'bg-blue-300 dark:bg-blue-900 dark:text-gray-300'
                } `}
              >
                {tag.name}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
