import { Listbox, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { FaCheck, FaChevronDown } from 'react-icons/fa';

export default function BasicSelect({
  options,
  selected,
  placeholder,
  setSelected,
}: {
  options: { key: string; value: string }[];
  selected: { key: string; value: string } | null;
  placeholder?: string;
  setSelected: (obj: { key: string; value: string }) => void;
}) {
  return (
    <Listbox value={selected} onChange={setSelected}>
      <div className='relative mt-1 rounded-lg border dark:border-slate-400'>
        <Listbox.Button className='relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-300 dark:bg-slate-600 sm:text-sm'>
          <span className='block truncate'>
            {selected
              ? selected.value
              : placeholder
                ? placeholder
                : 'Select option'}
          </span>
          <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
            <FaChevronDown
              className='h-5 w-5 text-gray-400'
              aria-hidden='true'
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <Listbox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-slate-400 sm:text-sm'>
            {options.map((option, optionIdx) => (
              <Listbox.Option
                key={optionIdx}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 dark:hover:bg-slate-500 dark:hover:text-white ${
                    active ? 'bg-indigo-100 text-indigo-900' : 'text-gray-900'
                  }`
                }
                value={option}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? 'font-medium' : 'font-normal'
                      }`}
                    >
                      {option.value}
                    </span>
                    {selected ? (
                      <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600'>
                        <FaCheck className='h-5 w-5' aria-hidden='true' />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
