import { ContractStatusType } from '../../api/services/contract.service';
import { capitalize } from 'lodash';

interface IContractStatusBadgeProps {
  status: ContractStatusType;
  className?: string;
}

function determineStatusColor(status: ContractStatusType) {
  switch (status) {
    case 'REQUESTED':
      return 'bg-yellow-800 text-yellow-200';
    case 'SIGNED':
      return 'bg-green-200 text-green-800';
    default:
      return 'bg-slate-300 text-slate-800';
  }
}

export const ContractStatusBadge = ({
  status,
  className = '',
}: IContractStatusBadgeProps) => {
  return (
    <div
      className={`flex h-6 justify-center rounded px-2.5 py-0.5 text-sm font-semibold ${determineStatusColor(
        status
      )} ${className}`}
    >
      <div>{capitalize(status.toLowerCase())}</div>
    </div>
  );
};
