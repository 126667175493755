import axios from 'axios';
import { ReactNode, useEffect, useState } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../api';
import { AccountCompletionStepWindow } from '../components/onboarding/BAccountCompletionStepWindow';
import { AccountTypeStepWindow } from '../components/onboarding/AAccountTypeStepWindow';
import { GetStartedStepWindow } from '../components/onboarding/CGetStartedStepWindow';
import { TeamCreationStepWindow } from '../components/onboarding/BTeamCreationStepWindow';
import { LabelWelcomeStepWindow } from '../components/onboarding/ALabelWelcomeStepWindow';
import { useAuth } from '../hooks/useAuth';
import { UserQuestionnaireStepWindow } from '../components/onboarding/DUserQuestionnaireStepWindow';
import { GetStartedStepWindowLabel } from '../components/onboarding/CGetStartedStepWindowLabel';

interface IStep {
  showInvited?: boolean;
  index: number;
  name: string;
  skippable: boolean;
  confirmable?: boolean;
}

type LocationProps = {
  state: {
    from: Location;
  };
};

export default function Onboarding() {
  const { user, updateUser, activeTeam } = useAuth();
  let location = useLocation() as LocationProps;
  let navigate = useNavigate();

  const [accountType, setAccountType] = useState('');

  useEffect(() => {
    if (user && user.onboarded) {
      console.log(location.state);
      if (location.state && location.state.from) {
        navigate(location.state.from.pathname, { replace: true });
      } else {
        navigate('/', { replace: true });
      }
    }
  }, [user]);

  const stepsInvited: IStep[] = [
    {
      index: 1,
      name: 'You have been invited',
      skippable: false,
    },
    {
      index: 2,
      name: 'Get Started',
      skippable: false,
    },
  ];
  const stepsSolo: IStep[] = [
    {
      index: 1,
      name: 'Account Type',
      skippable: false,
    },
    {
      index: 2,
      name: 'Account Information',
      skippable: false,
      confirmable: true,
    },
    {
      index: 3,
      name: 'Who are you?',
      skippable: true,
    },
    {
      index: 4,
      name: 'Get Started',
      skippable: false,
    },
  ];
  const [currentStep, setCurrentStep] = useState<IStep>(stepsSolo[0]);
  const [activeSteps, setActiveSteps] = useState<IStep[]>(stepsSolo);

  useEffect(() => {
    if (activeTeam !== null) {
      setActiveSteps(stepsInvited);
    } else {
      setActiveSteps(stepsSolo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTeam]);

  useEffect(() => {
    setCurrentStep(activeSteps[0]);
  }, [activeSteps]);

  async function finishOnboarding() {
    if (!user) {
      console.log('User not set..');
      return;
    }
    try {
      const response = await api.user.updateUser(user?.id, {
        onboarded: true,
      });
      if (response.data.result) {
        updateUser();
        if (activeTeam?.teamMembershipDTO?.role !== 'GUEST') {
          navigate('/team', { replace: true });
        }
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  function nextStep() {
    if (!currentStep) {
      console.log('No current step');
      return;
    }

    const currentStepIndex = currentStep.index - 1;
    if (currentStepIndex + 1 < activeSteps.length) {
      setCurrentStep(activeSteps[currentStepIndex + 1]);
    } else {
      console.log('finish');
      finishOnboarding();
    }
  }

  function Step({
    index,
    name,
    active,
    done,
  }: {
    index: number;
    name: string;
    active?: boolean;
    done?: boolean;
  }) {
    return (
      <div className=''>
        <span
          className={`w-full border-b-4 ${
            done || active
              ? 'border-indigo-600 dark:border-slate-200'
              : 'border-gray-400'
          } ${active ? 'animate-pulse' : ''} block`}
        >
          &nbsp;
        </span>
        <h4
          className={`text-sm font-semibold ${
            done || active
              ? 'text-indigo-600 dark:text-slate-200'
              : 'text-gray-400'
          } mt-4 `}
        >
          STEP {index}
        </h4>
        <p
          className={`text-sm font-semibold ${
            done || active
              ? 'text-gray-700 dark:text-slate-200'
              : 'text-gray-400'
          }`}
        >
          {name}
        </p>
      </div>
    );
  }

  function Steps({ steps }: { steps: IStep[] }) {
    return (
      <div className='mt-6 grid w-full grid-cols-4 gap-8 rounded-md border bg-white px-7 pb-6 shadow-sm dark:bg-slate-800'>
        {steps.map((v) => (
          <Step
            key={v.index}
            index={v.index}
            name={v.name}
            done={currentStep && currentStep.index > v.index}
            active={currentStep && currentStep.index === v.index}
          />
        ))}
      </div>
    );
  }

  function StepActionWindow({
    step,
    children,
  }: {
    step: IStep;
    children: ReactNode;
  }) {
    return (
      <div className='mt-6 flex w-full rounded-md border bg-white shadow-sm dark:border-slate-500 dark:bg-slate-900'>
        <div
          className={`flex w-full flex-col items-center ${
            step.skippable || step.confirmable ? 'pb' : 'pb-4'
          }`}
        >
          <div className='w-full border-b border-indigo-600 pt-3 pb-3 text-center dark:border-slate-500 dark:bg-slate-800'>
            <h2 className='text-lg font-semibold text-gray-700 dark:text-slate-200'>
              <span className='text-indigo-600 dark:text-slate-200'>
                Step {step.index}
              </span>{' '}
              - {step.name}
            </h2>
          </div>
          {children}
          {step.skippable && (
            <div className='flex w-full flex-row justify-end border-t py-2 pr-6 text-center dark:border-slate-500 dark:bg-slate-800'>
              <button
                type='submit'
                className='group relative flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-semibold text-slate-600 hover:bg-slate-200 focus:outline-none dark:hover:bg-slate-500'
                onClick={() => nextStep()}
              >
                <span className='inline-flex items-center font-semibold dark:text-slate-200'>
                  Skip <FaChevronRight className='ml-1' size={15} />
                </span>
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className='flex w-full flex-grow justify-center'>
      <div className='flex w-full flex-col items-center lg:w-3/4 '>
        {/* <div className="h-full flex flex-col items-center px-4 sm:px-6 lg:px-8 bg-gray-50">
      <div className="mt-20 w-full px-2 lg:w-3/4"> */}
        <div>
          <h2 className='text-center text-3xl font-extrabold text-gray-900 dark:text-slate-400'>
            Welcome to KTR Studio
          </h2>
          <p className='mt-2 text-center text-sm text-gray-600 dark:text-slate-200'>
            Please complete your account setup below
          </p>
        </div>

        {activeTeam !== null && currentStep !== null && (
          <>
            <StepActionWindow step={currentStep}>
              {currentStep.index === 1 && (
                <LabelWelcomeStepWindow
                  user={user}
                  nextStep={nextStep}
                  team={activeTeam}
                />
              )}
              {currentStep.index === 2 && (
                <GetStartedStepWindow nextStep={nextStep} />
              )}
            </StepActionWindow>
          </>
        )}
        {activeTeam === null && (
          <>
            <Steps steps={activeSteps} />
            <StepActionWindow step={currentStep}>
              {currentStep.index === 1 && (
                <AccountTypeStepWindow
                  user={user}
                  nextStep={nextStep}
                  setAccountType={setAccountType}
                />
              )}
              {currentStep.index === 2 && accountType === 'ARTIST' && (
                <AccountCompletionStepWindow user={user} nextStep={nextStep} />
              )}
              {currentStep.index === 2 && accountType === 'LABEL' && (
                <TeamCreationStepWindow user={user} nextStep={nextStep} />
              )}
              {currentStep.index === 3 && (
                <UserQuestionnaireStepWindow user={user} nextStep={nextStep} />
              )}
              {currentStep.index === 4 && accountType === 'LABEL' && (
                <GetStartedStepWindowLabel nextStep={nextStep} />
              )}
              {currentStep.index === 4 && accountType === 'ARTIST' && (
                <GetStartedStepWindow nextStep={nextStep} />
              )}
            </StepActionWindow>
          </>
        )}
      </div>
    </div>
  );
}
