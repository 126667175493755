import { Tooltip } from 'flowbite-react';
import React from 'react';
import { ImInfo } from 'react-icons/im';

interface Props {
  hint: React.ReactNode;
  className?: string;
}

export default function InfoTooltip({ hint, className = '' }: Props) {
  return (
    <Tooltip content={hint} className={className}>
      <ImInfo />
    </Tooltip>
  );
}
