import { useEffect, useState } from 'react';
import { FaSpinner, FaUserAstronaut, FaUsers } from 'react-icons/fa';
import { toast } from 'react-toastify';
import api from '../api';
import { IProject } from '../api/services/project.service';
import { ITeamMembership } from '../api/services/team.service';
import SubmissionSection from '../components/projects/SubmissionSection';
import Loader from '../components/shared/Loader';
import { Sidebar } from '../components/shared/Sidebar';
import { Person } from '../components/shared/Person';
import { useAuth } from '../hooks/useAuth';
import { DateTime } from 'luxon';
import { ITrack } from '../api/services/track.service';
import { Cell } from '../components/shared/Cell';

export const Artists = () => {
  const { activeTeam } = useAuth();
  const [artists, setArtists] = useState<ITeamMembership[]>();
  const [projects, setProjects] = useState<IProject[]>();
  const [loadingArtists, setLoadingArtists] = useState(false);
  const [loadingSubmissions, setLoadingSubmissions] = useState(false);
  const [activeArtist, setActiveArtist] = useState<ITeamMembership>();
  const [tracks, setTracks] = useState<ITrack[]>([]);
  const showError = () => {
    toast.error(
      'Something really went wrong, you might want to contact support!'
    );
  };

  const onClickArtist = (a: ITeamMembership) => {
    setActiveArtist(a);
    setLoadingSubmissions(true);
    api.project
      .getProjectsOfUser(a.userId)
      .then((res) => {
        const submissions = res.data.result.filter(
          (p) => p.type === 'SUBMISSION'
        );
        setProjects(submissions);
      })
      .catch(showError)
      .finally(() => {
        setLoadingSubmissions(false);
      });
  };

  useEffect(() => {
    if (activeTeam) {
      setLoadingArtists(true);
      Promise.all([
        api.team.getTeamMembers(activeTeam.id),
        api.track.getTracks(),
      ])
        .then(([membersRes, tracksRes]) => {
          const projectGuests = membersRes.data.result.filter(
            (a) => a.role === 'GUEST'
          );
          setArtists(projectGuests);
          setTracks(tracksRes.data.result || []);
          if (!!projectGuests.length) {
            onClickArtist(projectGuests[0]);
          }
        })
        .catch(showError)
        .finally(() => {
          setLoadingArtists(false);
        });
    }
    // eslint-disable-next-line
  }, [activeTeam]);

  const renderArtists = () => {
    if (!artists) {
      return (
        <div className='pt-5 text-center font-semibold'>No Artists yet</div>
      );
    }
    return artists
      .sort((a, b) => a.user.firstname.localeCompare(b.user.firstname))
      .map((a) => (
        <Person
          firstname={a.user.firstname}
          lastname={a.user.lastname}
          key={a.userId}
          onClick={() => {
            onClickArtist(a);
          }}
          active={activeArtist?.userId === a.userId}
        />
      ));
  };

  if (loadingArtists) return <Loader />;

  return (
    <div className='flex flex-grow flex-col gap-x-5 gap-y-5 sm:flex-row'>
      <div className='flex flex-col sm:w-1/3'>
        <Sidebar
          title='Artists'
          icon={<FaUsers size={16} className='text-indigo-700' />}
        >
          <div className='grow'>
            {loadingArtists ? (
              <div className='flex items-center justify-center p-20'>
                <FaSpinner size={30} className='animate-spin' />
              </div>
            ) : (
              renderArtists()
            )}
          </div>
        </Sidebar>
      </div>
      <div className='flex flex-col gap-y-5 sm:w-2/3'>
        {activeArtist && (
          <div className='flex flex-col overflow-hidden rounded-2xl bg-white shadow-xl dark:bg-gray-800'>
            <div className='flex min-h-[40px] items-center justify-between border-b bg-gray-50 py-2 px-4 dark:border-gray-600 dark:bg-gray-800'>
              <div className='mr-10 flex items-center bg-gray-50 dark:bg-gray-800'>
                <div className='flex'>
                  <FaUserAstronaut size={16} className='text-indigo-700' />
                </div>
                <div className='ml-2 flex text-lg font-medium leading-6 text-slate-700 dark:text-slate-200'>
                  Artist information
                </div>
              </div>
            </div>
            <div className='divide-y'>
              <div className='w-full space-y-4 p-2 px-4 pb-4 dark:bg-gray-900'>
                <div className='flex items-center text-lg font-medium'>
                  Artist Name
                </div>
                <div className='flex items-center pl-5 text-lg font-medium'>
                  {activeArtist?.user.firstname} {activeArtist?.user.lastname}
                </div>
              </div>
              <div className='px-4 pb-4'>
                <div className='gap-x-4 gap-y-3 pt-2 sm:grid sm:grid-cols-3'>
                  <Cell
                    label='Joined Label:'
                    className='flex items-center gap-x-2 sm:block'
                  >
                    {activeArtist.createdAt
                      ? DateTime.fromISO(activeArtist.createdAt).toFormat(
                          'dd MMMM, yyyy'
                        )
                      : null}
                  </Cell>
                  <Cell
                    label='Total Submissions: '
                    className='flex items-center gap-x-2 sm:block'
                  >
                    {projects?.length}
                  </Cell>
                  <Cell
                    label='Total Tracks: '
                    className='flex items-center gap-x-2 sm:block'
                  >
                    {
                      tracks.filter((t) => t.createdBy === activeArtist.userId)
                        .length
                    }
                  </Cell>
                </div>
              </div>
            </div>
          </div>
        )}
        <SubmissionSection
          className='grow'
          loading={loadingSubmissions}
          projects={projects}
          artistUserId={activeArtist?.userId}
        />
      </div>
    </div>
  );
};
