import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import { ITag } from '../../../../../api/services/tag.service';
import { globalThemeStyles } from '../../../../../config/globalStyles';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';

interface TagSelectProps {
  canBeModified: boolean;
  catalogue: ITag[];
  updateCatalogue: (values: ITag[]) => void;
  options: ITag[];
}

export default function TagSelect({
  canBeModified,
  catalogue,
  updateCatalogue,
  options,
}: TagSelectProps) {
  return (
    <Listbox
      value={catalogue}
      onChange={updateCatalogue}
      multiple
      disabled={!canBeModified}
    >
      <div className='relative mt-3'>
        <ListboxButton
          className={`border-1 w-full cursor-default border border-slate-100 py-2.5 pl-3 pr-10 text-left shadow-md sm:text-sm ${globalThemeStyles.inputbox}`}
        >
          <span className=' truncate'>
            {catalogue.length > 0
              ? catalogue.map((c) => c.name).join(',')
              : 'Not selected'}
          </span>
          <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
            <ChevronDownIcon
              aria-hidden='true'
              className='h-5 w-5 text-gray-400'
            />
          </span>
        </ListboxButton>

        <ListboxOptions
          transition
          className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in dark:bg-slate-800 dark:text-slate-200 sm:text-sm'
        >
          {options.map((person) => (
            <ListboxOption
              key={person.id}
              value={person}
              className='group relative cursor-default select-none py-2 pl-3 pr-9 data-[focus]:bg-indigo-600 data-[focus]:text-white dark:text-slate-200'
            >
              <span className='block truncate font-normal group-data-[selected]:font-semibold'>
                {person.name}
              </span>

              <span className='absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden'>
                <CheckIcon aria-hidden='true' className='h-5 w-5' />
              </span>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
    </Listbox>
  );
}
