import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import axios from 'axios';
import { FaSpinner } from 'react-icons/fa';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import api from '../../api';
import { APP_AUTH_TARGET, APP_URL } from '../../config/config';
import { GoogleAdsParams } from '../../hooks/useAdParams';
import { useAuth } from '../../hooks/useAuth';
import { useLocalStorage } from '../../hooks/useLocalStorage';

function OAuthVerification() {
  const { handleOAuthCallback, user } = useAuth();
  const [searchParams] = useSearchParams();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);

  const params = useParams();
  let navigate = useNavigate();

  const [fromLocation, setFromLocation] = useLocalStorage<string | null>(
    'fromLocation',
    null
  );

  const [adParamsLocal, setAdParamsLocal] =
    useLocalStorage<GoogleAdsParams | null>('adParams', null);

  useEffect(() => {
    console.log(params);
    const provider = params.providerId;
    const code = searchParams.get('code');
    const state = searchParams.get('state');
    if (code && provider) {
      if (state && state != null && state !== 'null') {
        // forward to other sites
        console.log(state, provider, code);
        processSSORedirect(state, provider, code);
      } else {
        console.log('oauth code received, forward to backend');
        oAuthVerification(provider, code);
      }
    } else {
      // something went wrong
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      if (success) {
        console.log('here');
        api.user.addUserInsight(user.id, 'AD_PARAMS', adParamsLocal);
        const { language, platform, userAgent } = navigator;
        api.user.addUserInsight(user.id, 'BROWSER_PARAMS', {
          language,
          platform,
          userAgent,
        });
        setAdParamsLocal(null);
      }
      if (fromLocation !== null) {
        setFromLocation(null);
        navigate(fromLocation, { replace: true });
      } else {
        navigate('/', { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, success]);

  async function processSSORedirect(
    target: string,
    provider: string,
    code: string
  ) {
    try {
      const response = await api.auth.getSSORedirectUrl(provider, target);
      if (response.data.result) {
        if (target.indexOf(APP_AUTH_TARGET) > -1) {
          console.log('oauth code received, forward to client');
          window.open(`${APP_URL}?provider=${provider}&code=${code}`, '_self');
          setLoading(false);
        } else {
          window.location.assign(
            `${response.data.result.targetUrl}/${provider}?code=${code}`
          );
          setLoading(false);
        }
      } else {
        console.log('redirect failed');
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  async function oAuthVerification(provider: string, code: string) {
    try {
      // setLoading(true);
      const tokenResponse = await handleOAuthCallback({ provider, code });
      // setLoading(false);
      if (tokenResponse) {
        if (tokenResponse.userCreation) {
          toast.success('SignUp successful');
          setSuccess(true);
          setLoading(false);
          if (window.gtag) {
            window.gtag('event', 'conversion', {
              send_to: 'AW-10926362464/oMOtCJ-A7cUDEOCejNoo',
            });
          }
          console.log('Conversion');
        } else {
          toast.success('Login successful');
        }
      } else {
        toast.error('Error on sign-in');
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error('Something really went wrong!');
      }
      navigate('/login');
    }
  }

  return (
    <div className='flex grow flex-col items-center'>
      {loading && (
        <>
          <h2 className='mt-10 text-center text-2xl font-bold text-slate-700'>
            Verifying Identity
          </h2>
          <FaSpinner className='animate-spin' />
          <p className='mt-2 text-center text-lg text-slate-500'>
            You will be redirected shortly...
          </p>
        </>
      )}
      {success && !loading && (
        <>
          <h2 className='mt-10 text-center text-2xl font-bold text-slate-700'>
            Login Successful
          </h2>
          <p className='mt-2 text-center text-lg text-slate-500'>
            You should now be logged into your local client and this window can
            be closed.
          </p>
        </>
      )}
      {!success && !loading && (
        <>
          <h2 className='mt-10 text-center text-2xl font-bold text-slate-700'>
            Something went wrong
          </h2>
          <p className='mt-2 text-center text-lg text-slate-500'>
            Please try contacting our support
          </p>
        </>
      )}
    </div>
  );
}

export default OAuthVerification;
