import { ITrack } from '../../api/services/track.service';
import { DropzoneWrapper } from './DropzoneWrapper';
import StudioFileList from './sections/SubmissionTrackList';
import { TrackInfo } from './sections/TrackInfo';
import { useDropzoneState } from './StudioDropzoneWrapper';

export function ShowStudio({
  activeTrack,
  activeProjectTracks,
}: {
  activeTrack: ITrack | null;
  activeProjectTracks: ITrack[];
}) {
  const { acceptedFiles, rootProps, inputProps } = useDropzoneState();

  return (
    <>
      {(activeProjectTracks.length > 0 || acceptedFiles.length > 0) && (
        <>
          <div className='flex basis-1/3 flex-col space-y-6'>
            <div className='flex flex-1 items-stretch'>
              {/* show list of tracks */}
              <DropzoneWrapper
                rootProps={rootProps}
                inputProps={inputProps}
                testid='StudioDropzone-input'
              >
                <StudioFileList />
              </DropzoneWrapper>
            </div>
          </div>
          {<TrackInfo />}
        </>
      )}
    </>
  );
}
