import axios from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../../api';
import {
  IProjectInvitation,
  IProjectMembership,
  IUserShort,
} from '../../../api/services/project.service';
import { ITeamMembership } from '../../../api/services/team.service';
import { useAuth } from '../../../hooks/useAuth';
import { useStudioState } from '../studioState';
import ProjectMemberList from './ProjectMemberList';
import UserCombobox from './UserCombobox';

export default function ProjectSettingsUserSection({
  projectMembers,
  projectInvites,
  fetchProjectMembers,
  fetchProjectInvites,
}: {
  projectMembers: IProjectMembership[];
  projectInvites: IProjectInvitation[];
  fetchProjectMembers: () => void;
  fetchProjectInvites: () => void;
}) {
  const { activeProject } = useStudioState();
  const { activeTeam } = useAuth();
  const [selectedUser, setSelectedUser] = useState<IUserShort | null>(null);
  const [isEmailInvite, setIsEmailInvite] = useState<boolean>(false);
  const [teamMembers, setTeamMembers] = useState<ITeamMembership[]>([]);

  useEffect(() => {
    const emailInvite = selectedUser?.email ? true : false;
    setIsEmailInvite(emailInvite);
    if (selectedUser && activeProject) {
      if (emailInvite) {
        inviteProjectMember({
          email: selectedUser.email,
          projectId: activeProject.id,
        });
      } else {
        inviteProjectMember({
          userId: selectedUser.id,
          projectId: activeProject.id,
        });
      }
    }
  }, [selectedUser]);

  useEffect(() => {
    if (activeTeam) {
      fetchTeamMembers(activeTeam.id);
    }
  }, [activeTeam]);

  async function fetchTeamMembers(id: string) {
    try {
      const { data } = await api.team.getTeamMembers(id);
      if (data.result) {
        setTeamMembers(data.result);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function removeProjectMember(projectId: string, userId: string) {
    try {
      const { status } = await api.project.removeProjectMember(
        projectId,
        userId
      );
      if (status === 204) {
        toast.success('Removed user from project');
        fetchProjectMembers();
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  async function revokeProjectInvite(projectId: string, email: string) {
    try {
      const { status } = await api.project.revokeProjectInvite(
        projectId,
        email
      );
      if (status === 204) {
        toast.success('Revoked Project Invitation');
        fetchProjectMembers();
        fetchProjectInvites();
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  async function updateInvite(id: string, status: string) {
    try {
      const { data } = await api.project.updateProjectInvitePartially(id, {
        status,
      });
      if (data.result) {
        console.log(data.result);
        if (activeProject) {
          fetchProjectMembers();
          fetchProjectInvites();
        }
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  async function inviteProjectMember({
    email,
    userId,
    projectId,
  }: {
    email?: string | null;
    userId?: string;
    projectId: string;
  }) {
    try {
      console.log(email);
      const { data } = await api.project.inviteProjectMember(projectId, {
        email,
        userId,
        role: 'GUEST',
        teamId: activeTeam?.id,
      });
      if (data.result) {
        console.log(data.result);
        toast.success('Invited user to project');
        fetchProjectMembers();
        fetchProjectInvites();
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  return (
    <div className='flex flex-col rounded-md pb-2'>
      <span className='text-sm text-slate-500 dark:text-slate-400'>
        {
          'Select an artist that you have previously worked with or invite them by email'
        }
      </span>
      {projectMembers.filter((x) => x.role === 'GUEST').length === 0 &&
        projectInvites.length === 0 && (
          <div className='mt-4 flex flex-col justify-center'>
            <div className='flex justify-center'>
              <div className='flex-grow'>
                <UserCombobox
                  teamMembers={
                    teamMembers.length > 0
                      ? teamMembers
                          .filter(
                            (member) => member.user && member.role === 'GUEST'
                          )
                          .map((member) => member.user)
                      : []
                  }
                  selected={selectedUser}
                  setSelected={setSelectedUser}
                />
              </div>
            </div>
          </div>
        )}
      <div className='mt-4'>
        <ProjectMemberList
          users={projectMembers.filter((p) => p.role === 'GUEST')}
          issuedInvites={projectInvites}
          deleteUser={(userId: string) => {
            if (activeProject) {
              removeProjectMember(activeProject.id, userId);
            }
          }}
          revokeInvite={(email: string) => {
            if (activeProject) {
              revokeProjectInvite(activeProject.id, email);
            }
          }}
          updateInvite={updateInvite}
        />
      </div>
    </div>
  );
}
