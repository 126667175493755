import axios from 'axios';
import { useEffect, useState } from 'react';
import { FaChevronRight, FaCompactDisc } from 'react-icons/fa';
import { toast } from 'react-toastify';
import stringToColor from 'string-to-color';
import api from '../../api';
import { IAuthenticatedUser, useAuth } from '../../hooks/useAuth';
import StatusBadge from '../shared/StatusBadge';

export function TeamCreationStepWindow({
  user,
  nextStep,
}: {
  user: IAuthenticatedUser | null;
  nextStep: () => void;
}) {
  const { activeTeam, updateUser } = useAuth();
  const [teamName, setTeamName] = useState('');

  const [email, setEmail] = useState(user?.email);
  const [firstname, setFirstname] = useState(user?.firstname);
  const [lastname, setLastname] = useState(user?.lastname);

  useEffect(() => {
    if (activeTeam) {
      setTeamName(activeTeam.name);
    }
  }, [activeTeam]);

  async function updateUserInternal() {
    if (!user) {
      console.log('User not set..');
      return;
    }
    if (!teamName || teamName === '') {
      toast.error('Please enter a label name');
      return;
    }
    try {
      const response = await api.user.updateUser(user?.id, {
        email,
        firstname,
        lastname,
      });
      if (response.data.result) {
        toast.success('User Information updated successfully');
        if (!activeTeam) {
          const response = await api.team.addTeam({
            name: teamName,
          });
          if (response.data.result) {
            toast.success('Label created');
          }
        } else {
          const response = await api.team.updateTeam(activeTeam.id, {
            name: teamName,
          });
          if (response.data.result) {
            toast.success('Label updated');
          }
        }
        nextStep();
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  return (
    <>
      <span className='mt-4 text-center font-medium text-gray-700 dark:text-slate-200'>
        Please check and complete your information
      </span>
      <div className='my-8 flex w-full justify-between gap-8 px-8 lg:w-4/6'>
        <div className='flex w-full flex-col gap-5'>
          <div className='col-span-2 flex flex-col'>
            <div className='border-b-2 border-slate-600 text-lg font-bold '>
              Label
            </div>
          </div>
          <div className='col-span-2 flex space-x-4'>
            <FaCompactDisc size={60} color={stringToColor(teamName)} />

            <div className='w-full'>
              <label
                htmlFor='labelName'
                className='block text-sm font-medium text-gray-700 dark:text-slate-200'
              >
                Label name
              </label>
              <div className='mt-1'>
                <input
                  disabled={activeTeam ? true : false}
                  id='labelName'
                  name='labelName'
                  type='labelName'
                  autoComplete='labelName'
                  required
                  className='w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500  focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 disabled:bg-slate-200 dark:border-slate-600 dark:bg-slate-800 dark:text-slate-200 sm:text-sm'
                  placeholder=''
                  onChange={(teamName) => setTeamName(teamName.target.value)}
                  defaultValue={teamName}
                />
              </div>
            </div>
          </div>
          {activeTeam && (
            <div className='flex w-full'>
              <StatusBadge
                status='Label Created'
                className='bg-green-200 text-green-800'
              />
            </div>
          )}
        </div>
        <div className='grid w-full grid-cols-2 gap-5'>
          <div className='col-span-2 flex flex-col'>
            <div className='border-b-2 border-slate-600 text-lg font-bold '>
              User
            </div>
          </div>
          <div>
            <label
              htmlFor='firstName'
              className='block text-sm font-medium text-gray-700 dark:text-slate-200'
            >
              First name
            </label>
            <div className='mt-1'>
              <input
                id='firstName'
                name='firstName'
                type='firstName'
                autoComplete='firstName'
                required
                className='w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500  focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 disabled:bg-slate-200 dark:border-slate-600 dark:bg-slate-800 dark:text-slate-200 sm:text-sm'
                placeholder=''
                onChange={(firstName) => setFirstname(firstName.target.value)}
                defaultValue={firstname}
              />
            </div>
          </div>
          <div>
            <label
              htmlFor='lastName'
              className='block text-sm font-medium text-gray-700 dark:text-slate-200'
            >
              Last name
            </label>
            <div className='mt-1'>
              <input
                id='lastName'
                name='lastName'
                type='lastName'
                autoComplete='lastName'
                required
                className='w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500  focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 disabled:bg-slate-200 dark:border-slate-600 dark:bg-slate-800 dark:text-slate-200 sm:text-sm'
                placeholder=''
                onChange={(lastName) => setLastname(lastName.target.value)}
                defaultValue={lastname}
              />
            </div>
          </div>
          <div className='col-span-2'>
            <label
              htmlFor='email-address'
              className='block text-sm font-medium text-gray-700 dark:text-slate-200'
            >
              Email address
            </label>
            <div className='mt-1'>
              <input
                id='email-address'
                name='email'
                type='email'
                autoComplete='email'
                required
                className='w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500  focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 disabled:bg-slate-200 dark:border-slate-600 dark:bg-slate-800 dark:text-slate-200 sm:text-sm'
                placeholder=''
                disabled={user?.authProviderId !== 'local'}
                onChange={(email) => setEmail(email.target.value)}
                defaultValue={email}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='flex w-full justify-end border-t py-2 pr-6 text-center dark:border-slate-500 dark:bg-slate-800'>
        <button
          type='submit'
          className='text-md flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:bg-indigo-800 dark:text-slate-300'
          onClick={() => updateUserInternal()}
        >
          <span className='inline-flex items-center font-semibold dark:text-slate-200'>
            Confirm <FaChevronRight className='ml-1' size={15} />
          </span>
        </button>
      </div>
    </>
  );
}
