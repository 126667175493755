import {
  FaBackward,
  FaEllipsisV,
  FaForward,
  FaPauseCircle,
  FaPlayCircle,
  FaVolumeDown,
  FaVolumeMute,
} from 'react-icons/fa';

import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import './AutoScrollingText.css';
import { usePlayer } from '../../../hooks/usePlayer';
import { Menu, Transition } from '@headlessui/react';
import { classNames } from '../../../utils';
import { globalThemeStyles } from '../../../config/globalStyles';
import { Link } from 'react-router-dom';

export function NavbarPlayer() {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const {
    url,
    setPlayer,
    isPlaying,
    togglePlay,
    track,
    player,
    canPlay,
    fileId,
  } = usePlayer();

  const [duration, setDuration] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [shouldScroll, setShouldScroll] = useState(false);
  const [muted, setMuted] = useState(false);

  useEffect(() => {
    const containerWidth = containerRef.current?.offsetWidth || 0;
    const contentWidth = contentRef.current?.offsetWidth || 0;

    if (contentWidth > containerWidth) {
      setShouldScroll(true);
    }
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      setPlayer(audioRef.current);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (player) {
      player.addEventListener('durationchange', (event: Event) => {
        setDuration((event.target as HTMLAudioElement).duration);
      });
      player.addEventListener('timeupdate', () => {
        setCurrentTime(player.currentTime);
      });
    }
  }, [player]);

  const handleSliderChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    // const newValue = ;
    if (player) player.currentTime = +event.target.value;
  };

  const handleSeek = (newTime: number) => {
    if (!player) return;
    player.currentTime = currentTime + newTime;
  };

  return (
    // <div className='flex items-center rounded-xl bg-indigo-600 p-2 '>
    //   <button
    //     onClick={togglePlaybackState}
    //     className='mr-2 rounded-full bg-amber-500 p-2'
    //   >
    //     {!playbackState && <FaPlay size={15} className='text-white' />}
    //     {playbackState && <FaPause size={15} className='text-white' />}
    //   </button>

    //   <div className='truncate font-semibold text-slate-50'>
    //     {currentTrack?.name}
    //   </div>
    // </div>
    <div
      className='flex w-[41rem] rounded-lg bg-white ring-1 ring-slate-700/10 dark:bg-slate-700'
      data-fileid={fileId}
    >
      <div className='flex items-center space-x-4 p-2 px-6'>
        <button
          disabled={!canPlay || !player?.seekable}
          className='disabled:opacity-50'
          onClick={() => {
            handleSeek(-5);
          }}
        >
          <FaBackward className='h-4 w-4 flex-none text-slate-700 dark:text-slate-400' />
        </button>
        <button
          onClick={togglePlay}
          disabled={!canPlay}
          className='disabled:opacity-50'
        >
          <PlayIcon isPlaying={isPlaying} />
        </button>
        <button
          className='disabled:opacity-50'
          disabled={!canPlay || !player?.seekable}
          onClick={() => {
            handleSeek(5);
          }}
        >
          <FaForward className='h-4 w-4 flex-none text-slate-700 dark:text-slate-400' />
        </button>
      </div>
      <div className='flex w-[41rem] flex-auto items-center border-l border-slate-500/60 pl-2 pr-4 text-xs leading-5 text-slate-700 dark:text-slate-400'>
        <div className='flex flex-col justify-between'>
          {/* TODO:To enable scrolling, remove flex from container below, need to find solution for centering text */}
          <div
            className={`auto-scroll-container flex w-[28rem] justify-center overflow-hidden text-xs leading-5 text-slate-700 dark:text-slate-400 ${
              shouldScroll ? 'scrolling' : ''
            }`}
            ref={containerRef}
          >
            <div
              className='auto-scroll-content overflow-hidden'
              ref={contentRef}
            >
              {track?.metadata?.trackNumber
                ? `${track?.metadata?.trackNumber}.`
                : ''}{' '}
              {track?.name} - {track?.metadata?.artistName}
            </div>
          </div>

          <div className='flex items-center gap-x-2'>
            <div className='-mt-[1.5px] leading-none'>
              {formatSeconds(Math.floor(currentTime))}
            </div>
            <div
              className={`relative flex flex-grow items-center ${
                !canPlay ? 'opacity-50' : ''
              }`}
            >
              <input
                type='range'
                min={0}
                max={Math.floor(duration)}
                value={currentTime}
                onChange={handleSliderChange}
                className='relative z-20 w-full cursor-pointer opacity-0'
                disabled={!canPlay}
              />
              <div className='absolute top-1/2 h-2 w-full -translate-y-1/2 rounded-full bg-slate-100 dark:bg-slate-400' />
              <div
                className='absolute top-1/2 h-2 flex-none -translate-y-1/2 rounded-full bg-slate-800'
                style={{
                  width: `calc(${(currentTime / duration) * 100}%)`,
                }}
              />
              <div
                className='absolute top-1/2 h-[1.125rem] w-1 -translate-y-1/2 -translate-x-1/2 rounded-full bg-slate-800'
                style={{
                  left: `calc(${(currentTime / duration) * 100}%)`,
                }}
              />
            </div>
            <div className='-mt-[1.5px] leading-none'>
              {formatSeconds(Math.floor(duration))}
            </div>
          </div>
        </div>
        <div className='flex items-center'>
          <button
            className='disabled:opacity-50'
            onClick={() => {
              if (!player) return;
              player.muted = !player.muted;
              setMuted((prev) => !prev);
            }}
            disabled={!canPlay}
          >
            <VolumeIcon muted={muted} />
          </button>
          <Menu>
            <div className='relative ml-2'>
              <Menu.Button
                disabled={!track}
                className='flex items-center disabled:opacity-50'
              >
                <FaEllipsisV className='h-4 w-4' />
              </Menu.Button>
              <Transition
                as='div'
                enter='transition duration-100 ease-out'
                enterFrom='transform scale-95 opacity-0'
                enterTo='transform scale-100 opacity-100'
                leave='transition duration-75 ease-out'
                leaveFrom='transform scale-100 opacity-100'
                leaveTo='transform scale-95 opacity-0'
                className='absolute right-0 pt-2'
              >
                <Menu.Items
                  className={classNames(
                    'rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                    globalThemeStyles.box
                  )}
                >
                  <Menu.Item>
                    <Link
                      to={`/submission/${track?.projectId}`}
                      className={classNames(
                        globalThemeStyles.hoverItem,
                        'flex cursor-pointer items-center whitespace-nowrap px-4 py-2 text-sm transition-colors'
                      )}
                    >
                      Jump to Submission
                    </Link>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </div>
          </Menu>
        </div>
      </div>
      <audio ref={audioRef} src={url} controls className='hidden' />
    </div>
  );
}

const VolumeIcon = ({ muted }: { muted: boolean }) => {
  const Icon = muted ? FaVolumeMute : FaVolumeDown;
  return <Icon className='ml-4 h-4 w-4 flex-none' />;
};

const PlayIcon = ({ isPlaying }: { isPlaying: boolean }) => {
  const Icon = isPlaying ? FaPauseCircle : FaPlayCircle;
  return (
    <Icon className='h-6 w-6 flex-none text-slate-700 dark:text-slate-400' />
  );
};

export function formatSeconds(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();
  const formattedSeconds =
    remainingSeconds < 10
      ? `0${remainingSeconds}`
      : remainingSeconds.toString();

  return `${formattedMinutes}:${formattedSeconds}`;
}
