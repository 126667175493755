import { useEffect } from 'react';
import { useAuth } from './useAuth';
import { mapAppEnvironment } from '../components/global/navbar/Navbar';
import { useLocalStorage } from './useLocalStorage';

export const useTeamLogo = () => {
  const { activeTeam } = useAuth();
  const [tenantLandingImageUrl, setTenantLandingImageUrl] = useLocalStorage<
    string | null
  >('tenantLandingImageUrl', null);

  useEffect(() => {
    if (activeTeam !== null) {
      if (activeTeam.landingImageFileId) {
        const appEnv = mapAppEnvironment(window.location.host);
        setTenantLandingImageUrl(
          `https://${appEnv}-ktr-assets.s3.nl-ams.scw.cloud/${activeTeam?.landingImageFileId}`
        );
      } else {
        setTenantLandingImageUrl(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTeam]);

  return {
    tenantLandingImageUrl,
  };
};
