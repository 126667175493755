import { IResponse } from '..';
import client from '../client';

export interface IComment {
  id: string;
  fileId: string;
  userId: string;
  userDisplayName: string;
  user: {
    id: string;
    firstname: string;
    lastname: string;
    avatar: string;
  };
  body: string;

  createdAt: string;
  updatedAt: string;
}

export interface INewComment {
  body: string;
  fileId: string;
  authorDisplayName?: string;
}

async function getCommentsByFile(commentId: string) {
  return client.get<IResponse<IComment[]>>(`/comment?fileId=${commentId}`);
}

async function getComment(id: string) {
  return client.get<IResponse<IComment>>(`/comment/${id}`);
}

async function addComment(comment: INewComment) {
  return client.post<IResponse<IComment>>(`/comment`, comment);
}

async function deleteComment(id: string) {
  return client.delete<IResponse<IComment>>(`/comment/${id}`);
}

const api = {
  getCommentsByFile,
  getComment,
  addComment,
  deleteComment,
};

export default api;
