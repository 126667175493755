import { AxiosResponse } from 'axios';
import { IResponse } from '..';
import client from '../client';

export type ContractStatusType = 'DRAFT' | 'REQUESTED' | 'SIGNED';

export interface Contract {
  composerId?: string;
  contractId: string;
  projectId: string;
  status: ContractStatusType;
  contract: ContractDTO;
  createdAt: string; // Assuming that OffsetDateTime is serialized as a string
  updatedAt: string; // Assuming that OffsetDateTime is serialized as a string
}

export interface ContractDTO {
  id: string;
  tenantId: string;
  name: string;
  type: string;
  fileId: string;
  version: number;
  foreignId: string;
  foreignSystem: string;
  createdAt: string; // Assuming that OffsetDateTime is serialized as a string
  updatedAt: string; // Assuming that OffsetDateTime is serialized as a string
}

async function getContracts() {
  return client.get(`/contract`);
}

async function getContract(id: string) {
  return client.get(`/contract/${id}`);
}

async function postContract(input: Omit<ContractDTO, 'id'>) {
  return client.post<Omit<ContractDTO, 'id'>, AxiosResponse<ContractDTO>>(
    `/contract`,
    input
  );
}

async function getProjectContracts(projectId: string) {
  return client.get<IResponse<Contract[]>>(`/contract/projectId:${projectId}`);
}

async function getComposerContracts(composerId: string) {
  return client.get<IResponse<Contract[]>>(
    `/contract/composerId:${composerId}`
  );
}

async function postComposerContract(contractId: string) {
  return client.post<IResponse<ContractDTO[]>>(
    `/contract/${contractId}/composer`
  );
}

const api = {
  getContract,
  postContract,
  getContracts,
  getComposerContracts,
  postComposerContract,
  getProjectContracts,
};

export default api;
