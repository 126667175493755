import { Switch } from '@headlessui/react';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { FaUserPlus } from 'react-icons/fa';
import { toast } from 'react-toastify';
import api from '../../../../api';
import { IComposer } from '../../../../api/services/composer.service';
import { IProject } from '../../../../api/services/project.service';
import { ITrack } from '../../../../api/services/track.service';
import { IAuthenticatedUser } from '../../../../hooks/useAuth';
import InfoTooltip from '../../../shared/InfoTooltip';
import { IStateProp } from '../../studioState';
import ViewComposerModal from './composerTab/ViewComposerModal';
import { Contract } from '../../../../api/services/contract.service';
import ComposerCombobox from './composerTab/ComposerCombobox';
import ComposerRow from './composerTab/ComposerRow';

const ROYALTY_SHARES_LIMIT = 100;

const handleError = (e: unknown) => {
  if (axios.isAxiosError(e)) {
    if (e.response?.data) {
      toast.error(e.response?.data.errorMessage);
    }
  } else {
    toast.error(
      'Something really went wrong, you might want to contact support!'
    );
  }
};

export function ComposerTabWindow({
  user,
  submission,
  activeTrack,
  canBeModified,
  refreshData,
  isTourActive,
  contracts,
}: {
  user: IAuthenticatedUser | null;
  submission: IProject | null;
  activeTrack: ITrack;
  canBeModified: boolean;
  refreshData: (state: IStateProp) => void;
  isTourActive?: boolean;
  contracts?: Contract[];
}) {
  const [isOpened, setIsOpened] = useState(false);
  const [activeComposer, setActiveComposer] = useState<IComposer | null>(null);

  const [trackComposers, setTrackComposers] = useState<IComposer[]>([]);
  const [composers, setComposers] = useState<IComposer[]>([]);
  const handleOpenModal = (composer: IComposer | null) => {
    setIsOpened(true);
    setActiveComposer(composer);
  };

  const handleClose = () => {
    setIsOpened(false);
    setActiveComposer(null);
  };

  const handleSave = async (composerId: string) => {
    try {
      if (activeTrack.metadata.equalRoyaltySplit) {
        await calculateRoyalty(
          [...trackComposers.map((v) => v.id), composerId].filter(
            (id, i, self) => self.indexOf(id) === i
          )
        );
      }
      const [trackComposersRes, composersRes] = await Promise.all([
        fetchTrackComposers(activeTrack.id),
        fetchComposers(),
      ]);
      setComposers(composersRes);
      setTrackComposers(trackComposersRes);
    } catch (e) {
      handleError(e);
    }
  };

  const activeTrackId = activeTrack.id;
  const calculateRoyalty = useCallback(
    async (composersIds: string[]) => {
      const composersCount = composersIds.length;
      const remainder = ROYALTY_SHARES_LIMIT % composersCount;
      const shares =
        remainder === 0
          ? Array(composersCount).fill(ROYALTY_SHARES_LIMIT / composersCount)
          : Array(composersCount)
              .fill(Math.floor(ROYALTY_SHARES_LIMIT / composersCount))
              .map((v, i) => {
                if (i + 1 <= remainder) {
                  return v + 1;
                }
                return v;
              });

      if (!!composersIds.length) {
        await Promise.all(
          composersIds.map((composerId, i) =>
            api.composer.updateComposerForTrack(composerId, activeTrackId, {
              royaltyShare: shares[i],
            })
          )
        );
      }
    },
    [activeTrackId]
  );

  useEffect(() => {
    (async () => {
      try {
        let [trackComposersRes, composersRes] = await Promise.all([
          fetchTrackComposers(activeTrackId),
          fetchComposers(),
        ]);
        setComposers(composersRes);
        setTrackComposers(trackComposersRes);
      } catch (error) {
        handleError(error);
      }
    })();
  }, [activeTrackId]);

  async function fetchTrackComposers(trackId: string) {
    const response = await api.composer.getComposersByTrack(trackId);
    return response.data.result || [];
  }

  async function fetchComposers() {
    const response = await api.composer.getComposers();
    return response.data.result || [];
  }

  async function deleteComposers(composer: IComposer) {
    try {
      const response = await api.composer.removeComposerFromTrack(
        composer.id,
        activeTrack.id
      );
      if (response.status === 204) {
        if (activeTrack.metadata.equalRoyaltySplit) {
          await calculateRoyalty(
            trackComposers.map((v) => v.id).filter((cId) => cId !== composer.id)
          );
        }
        const [trackComposersRes, composersRes] = await Promise.all([
          fetchTrackComposers(activeTrack.id),
          fetchComposers(),
        ]);
        setComposers(composersRes);
        setTrackComposers(trackComposersRes);
      }
    } catch (e) {
      handleError(e);
    } finally {
    }
  }

  async function addComposer(composer: IComposer) {
    if (activeTrack) {
      const response2 = await api.composer.addComposerToTrack(
        composer.id,
        activeTrack.id
      );
      if (response2.data.result) {
        if (response2.data.statusCode === 200) {
          toast.success('Composer added successfully');
          try {
            if (activeTrack.metadata.equalRoyaltySplit) {
              await calculateRoyalty([
                ...trackComposers.map((v) => v.id),
                composer.id,
              ]);
            }
            const [trackComposersRes, composersRes] = await Promise.all([
              fetchTrackComposers(activeTrack.id),
              fetchComposers(),
            ]);
            setComposers(composersRes);
            setTrackComposers(trackComposersRes);
          } catch (error) {
            handleError(error);
          } finally {
          }
        } else if (response2.data.statusCode === 409) {
          toast.error('Composer already in Project');
        }
      }
    }
  }

  const onChageRoyaltyType = async (checked: boolean) => {
    if (checked) {
      await calculateRoyalty(trackComposers.map((v) => v.id));
      const [trackComposersRes, composersRes] = await Promise.all([
        fetchTrackComposers(activeTrack.id),
        fetchComposers(),
      ]);
      setComposers(composersRes);
      setTrackComposers(trackComposersRes);
    }

    await api.track.updateTrackPartially(activeTrackId, {
      metadata: {
        ...activeTrack.metadata,
        equalRoyaltySplit: checked,
      },
    });
    refreshData('activeTrack');
  };

  const onConfirmComposerRoyaltyChange = async (
    composerId: string,
    royaltyShare: number
  ) => {
    try {
      await api.composer.updateComposerForTrack(composerId, activeTrackId, {
        royaltyShare,
      });
      const [trackComposersRes, composersRes] = await Promise.all([
        fetchTrackComposers(activeTrack.id),
        fetchComposers(),
      ]);
      setComposers(composersRes);
      setTrackComposers(trackComposersRes);
    } catch (error) {
      handleError(error);
    } finally {
    }
  };

  return (
    <>
      <ViewComposerModal
        disabled={
          activeComposer !== null && activeComposer?.createdBy !== user?.id
        }
        isOpened={isOpened}
        project={submission}
        activeComposer={activeComposer}
        close={handleClose}
        onUpdate={handleSave}
        activeTrack={activeTrack}
      />
      <div className='flex w-full flex-col'>
        <div>
          <div className='flex justify-between'>
            <div>
              <div className='max-w-lg text-sm text-gray-500 dark:text-slate-200'>
                Don't forget to give credit where credit is due - add all
                contributors to ensure fair play and recognition in your musical
                creations.
              </div>
            </div>
            {/* <ComposerSearch
            onCreateNew={handleOpenModal}
            onSelect={() => fetchTrackComposers(activeTrack.id)}
            project={submission}
            composers={composers}
            activeComposers={trackComposers}
          /> */}
            <div className='flex items-center space-x-4'>
              <div className='z-10'>
                <ComposerCombobox
                  disabled={!canBeModified}
                  composers={composers.filter(
                    (composer) =>
                      !trackComposers.find((c) => c.id === composer.id)
                  )}
                  setSelected={addComposer}
                  selected={null}
                />
              </div>
              <button
                id='add-composer'
                disabled={!canBeModified}
                onClick={() => handleOpenModal(null)}
                className=' flex items-center space-x-2 rounded bg-indigo-600 py-1 px-4 font-semibold text-white hover:bg-indigo-800 disabled:bg-slate-300 disabled:text-slate-200 dark:disabled:bg-slate-600 dark:disabled:text-slate-700'
              >
                <FaUserPlus size={24} />
              </button>
            </div>
          </div>
          <div className='mt-3 max-w-lg text-sm text-gray-500 dark:text-slate-200'>
            <div
              className='inline-flex w-auto items-center gap-x-2 pr-1'
              id='royalty-switch-div'
            >
              <div className='flex items-center'>
                Equal Royalty Split&nbsp;
                <InfoTooltip hint='If you want to input a custom royalty shares for each composer, deactivate this toogle switch.' />
              </div>
              <Switch
                checked={activeTrack.metadata.equalRoyaltySplit}
                onChange={onChageRoyaltyType}
                className={`${
                  activeTrack.metadata.equalRoyaltySplit
                    ? 'bg-blue-600'
                    : 'bg-gray-200'
                } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className='sr-only'>Enable notifications</span>
                <span
                  className={`${
                    activeTrack.metadata.equalRoyaltySplit
                      ? 'translate-x-6'
                      : 'translate-x-1'
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
            </div>
          </div>
        </div>
        <div className='relative mt-5 flex-grow'>
          <ul className='absolute h-full w-full divide-y divide-gray-200 overflow-auto rounded-md border border-gray-200 p-2 px-10 shadow-sm dark:border-slate-400'>
            {!trackComposers.length && isTourActive && (
              <ComposerRow
                index={0}
                validate={(v: number) => false}
                onConfirmComposerRoyaltyChange={onConfirmComposerRoyaltyChange}
                editableRoyalty={!activeTrack.metadata.equalRoyaltySplit}
                key={1}
                composer={{
                  id: '8439a615-b9bb-4a8b-af67-46750513df00',
                  teamId: '95643ba5-9e44-4a5a-a6f4-29b3d809a1d0',
                  createdBy: '1056f098-ecbc-4bcd-abcc-c26dcc535a46',
                  firstName: 'David',
                  middleName: '',
                  lastName: 'Doe',
                  email: 'test@test.com',
                  street: 'Test street',
                  city: 'Test city',
                  zip: '123123',
                  state: 'test',
                  country: 'Afghanistan',
                  ipi: '12312312312',
                  pro: 'ONDA',
                  royaltyShare: 100,
                }}
                createdByUser={true}
                canBeModified={canBeModified}
                handleDelete={() => {}}
                handleOpenModal={() => {}}
              />
            )}

            {trackComposers.map((composer, i) => (
              <ComposerRow
                contracts={(contracts || []).filter(
                  (c) =>
                    c.composerId === composer.id && c.contract?.type === 'STA'
                )}
                index={i}
                validate={(v: number) =>
                  trackComposers
                    .filter((c) => c.id !== composer.id)
                    .reduce((acc, c) => {
                      acc = acc + c.royaltyShare;
                      return acc;
                    }, 0) +
                    v <=
                  100
                }
                onConfirmComposerRoyaltyChange={onConfirmComposerRoyaltyChange}
                editableRoyalty={!activeTrack.metadata.equalRoyaltySplit}
                key={composer.id}
                composer={composer}
                createdByUser={!!user && composer.createdBy === user.id}
                canBeModified={canBeModified}
                handleDelete={deleteComposers}
                handleOpenModal={handleOpenModal}
              />
            ))}
            {trackComposers.length === 0 && (
              <div className='flex h-full w-full items-center justify-center text-lg text-gray-500'>
                No composers added
              </div>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}
