import { ITrack } from '../../../../api/services/track.service';
import { FaCompactDisc } from 'react-icons/fa';
import { IComposer } from '../../../../api/services/composer.service';
import Composer from './Composer';

interface Props {
  track: ITrack;
  composers: IComposer[];
}

export default function Track({ track, composers }: Props) {
  return (
    <div className='py-4'>
      <div className='mb-3 flex items-center gap-x-2'>
        <FaCompactDisc className='mr-1 h-7 w-7 text-gray-600 dark:text-gray-300' />
        <div className='text-lg'>
          <span className='leading-tight dark:text-gray-300'>Name: </span>
          <span className='font-semibold leading-tight text-indigo-600 dark:text-gray-200'>
            {track.name}
          </span>
        </div>
        {track.metadata?.versionName && (
          <>
            <span>/</span>
            <div>
              <div className='text-lg dark:border-gray-300'>
                <span className='leading-tight dark:text-gray-300'>
                  Version name:{' '}
                </span>
                <span className='font-semibold leading-tight text-indigo-600 dark:text-gray-200'>
                  {track.metadata.versionName}
                </span>
              </div>
            </div>
          </>
        )}
      </div>
      <div className='gap-3 space-y-3 lg:grid lg:grid-cols-2 lg:space-y-0'>
        {composers.map((c) => (
          <Composer composer={c} key={c.id} />
        ))}
      </div>
    </div>
  );
}
