import { FaTimes } from 'react-icons/fa';
import GenericDialog from '../../shared/dialog/GenericDialog';
import { FaFileImport } from 'react-icons/fa';
import Input from '../../shared/form/Input';
import { FormikErrors, FormikValues, useFormik } from 'formik';
import { globalThemeStyles } from '../../../config/globalStyles';
import { useEffect } from 'react';

interface Props {
  setIsOpened: (v: boolean) => void;
  isOpened: boolean;
  onSubmit: (message: string) => void;
}

interface FormValues {
  reason: string;
}

export const ConfirmRejectionModal = ({
  setIsOpened,
  isOpened,
  onSubmit,
}: Props) => {
  const formik = useFormik<FormikValues>({
    enableReinitialize: true,
    initialValues: {
      reason: '',
    },
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {};

      if (!values.reason) {
        errors.reason = 'Required';
      }

      return errors;
    },
    onSubmit: (values) => {
      onSubmit(values.reason);
    },
  });

  useEffect(() => {
    if (isOpened) {
      formik.resetForm();
    }
    // eslint-disable-next-line
  }, [isOpened]);

  return (
    <GenericDialog
      onUpperRightClick={() => setIsOpened(false)}
      upperRightIcon={<FaTimes size={20} />}
      header='Reject Submission'
      isOpen={isOpened}
      setIsOpen={setIsOpened}
      headerIcon={<FaFileImport size={16} className='text-indigo-700' />}
      footer={<></>}
    >
      <form
        className='flex min-h-[400px] flex-col justify-between'
        onSubmit={formik.handleSubmit}
      >
        <div>
          <Input
            label='Reason Of Rejection'
            error={!!formik.errors.reason && !!formik.touched.reason}
            onChange={formik.handleChange}
            value={formik.values.reason}
            type='text'
            name='reason'
            id='reason'
            className={globalThemeStyles.inputbox}
            data-testid='reject-submission-input'
          />
        </div>
        <div className='mt-4 flex items-center justify-end gap-x-3'>
          <button
            className='rounded bg-indigo-600 p-2 px-4 font-semibold text-white hover:bg-indigo-800'
            type='submit'
            data-testid='reject-submission-submit-btn'
          >
            Confirm
          </button>
          <button
            type='button'
            className='rounded p-2 px-4 font-semibold text-slate-600 hover:bg-slate-200'
            onClick={() => setIsOpened(false)}
          >
            Cancel
          </button>
        </div>
      </form>
    </GenericDialog>
  );
};
