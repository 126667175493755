import React from 'react';
import Input from './Input';
import { MdSearch } from 'react-icons/md';

export const SearchInput = ({
  onChange,
  className = '',
  ...props
}: React.InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <div className='relative'>
      <Input
        autoComplete=''
        type='text'
        onChange={onChange}
        className={`!mt-0 rounded-md border border-gray-300 bg-gray-50 p-1 pl-8 text-slate-700 focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-slate-200 dark:border-gray-300 dark:bg-slate-800 dark:text-slate-200 dark:disabled:bg-slate-600 ${className}`}
        {...props}
      />
      <MdSearch className='absolute top-1/2 left-[6px] h-6 w-6 -translate-y-1/2' />
    </div>
  );
};
