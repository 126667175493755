import { IResponse } from '..';
import client from '../client';

export interface IFeature {
  id: string;
  name: string;
  description: string;
}

async function getMyFeatures() {
  return client.get<IResponse<IFeature[]>>(`/feature/my`);
}

const api = {
  getMyFeatures,
};

export default api;
