function TabBarEntry({
  tab,
  tabNumber,
  activeTab,
  setActiveTab,
}: {
  tab: { name: string; icon: React.ReactNode };
  tabNumber: number;
  activeTab: number;
  setActiveTab: (tabNumber: number) => void;
}) {
  return (
    <div
      id={tab.name}
      onClick={() => {
        setActiveTab(tabNumber);
      }}
      className={`flex w-full cursor-pointer items-center justify-center rounded-lg px-2 hover:bg-slate-200 dark:hover:bg-slate-700 ${
        tabNumber === activeTab
          ? 'bg-slate-200 hover:bg-slate-300 dark:bg-indigo-700 dark:text-white dark:hover:bg-indigo-800'
          : 'text-slate-700 dark:text-slate-200'
      }`}
    >
      <div className={`flew-row flex items-center`}>
        <div className='text-indigo-700 dark:text-gray-400'>{tab.icon}</div>
        <div className={`ml-2 flex text-lg font-medium leading-6 `}>
          {tab.name}
        </div>
      </div>
    </div>
  );
}

export function TabBar({
  tabs,
  activeTab,
  setActiveTab,
}: {
  tabs: { name: string; icon: React.ReactNode }[];
  activeTab: number;
  setActiveTab: (tabNumber: number) => void;
}) {
  return (
    <div className='flex items-center justify-between border-b border-gray-200 p-2 px-4 dark:border-gray-600'>
      <div className='flex flex-grow items-center space-x-4'>
        {tabs.map((tab, index) => (
          <TabBarEntry
            key={index}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabNumber={index}
            tab={tab}
          />
        ))}
      </div>
    </div>
  );
}
