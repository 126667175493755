import axios from 'axios';
import detect from 'bpm-detective';
import { useEffect, useState } from 'react';
import { FaCompactDisc } from 'react-icons/fa';
import { toast } from 'react-toastify';
import api from '../../../../api';
import { IFile } from '../../../../api/services/file.service';
import { IProject } from '../../../../api/services/project.service';
import { ITeam } from '../../../../api/services/team.service';
import { ITrack } from '../../../../api/services/track.service';
import { IAuthenticatedUser } from '../../../../hooks/useAuth';
import { useDropzoneState } from '../../StudioDropzoneWrapper';

export function FileUploadRow({
  file,
  team,
  user,
  activeProject,
  futureTrackNumber,
  onUploadCompleted,
  onUploadStarted,
}: {
  file: File;
  team: ITeam;
  user: IAuthenticatedUser | null;
  activeProject: IProject;
  futureTrackNumber: number;
  onUploadCompleted: (fileObj: IFile, trackObj: ITrack) => void;
  onUploadStarted: (fileObj: IFile, trackObj: ITrack) => void;
}) {
  const { setAcceptedFilesWrapper } = useDropzoneState();
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const [track, setTrack] = useState<ITrack | null>(null);

  useEffect(() => {
    uploadFile(file);
    // eslint-disable-next-line
  }, [file]);

  async function uploadFile(file: File) {
    try {
      const trackObj = await addTrack(file);
      if (!trackObj) return;

      setTrack(trackObj);
      const fileObj = await addFile(file, trackObj.id);
      // get upload url
      onUploadStarted(fileObj, trackObj);
      const result = await api.file.getFilePresignedUploadUrl(fileObj.id);
      const url = result.data.result?.presignedUrl;
      try {
        // blocks until file uploaded successfully
        await upload(file, url);
      } catch (error) {
        await api.file.deleteFile(fileObj.id);
        await api.track.deleteTrack(trackObj.id);
        toast.error('Failed to upload file');
        return;
      }
      // set file status to READY
      await updateFile(fileObj);
      onUploadCompleted(fileObj, trackObj);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status === 409) {
        toast.error('Track with the same name already exists');
        setAcceptedFilesWrapper((p: File[]) =>
          p.filter((f) => f.name !== file.name)
        );
      }
    }
  }

  function cleanFileType(file: File) {
    // Workaround to change file type on mac
    if (file.type === 'audio/x-wav') {
      return 'audio/wav';
    }
    return file.type;
  }

  async function upload(file: File, url: string) {
    return await axios.put(url, file, {
      headers: {
        'Content-Type': cleanFileType(file),
      },
      onUploadProgress: (progress: any) => {
        const percentUploadedRaw = (progress.loaded / progress.total) * 100;
        const percentUploaded = Math.round(percentUploadedRaw);
        setFileUploadProgress(percentUploaded);
      },
    });
  }

  async function updateFile(file: IFile) {
    const { data: fileResponse } = await api.file.updateFilePartially(file.id, {
      status: 'READY',
    });
    return fileResponse.result;
  }

  function secondsToMMSS(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
      .toString()
      .padStart(2, '0')}`;
  }

  async function addTrack(file: File) {
    const audioContext = new AudioContext();
    const audioBuffer = await audioContext.decodeAudioData(
      await file.arrayBuffer()
    );
    let bpm = '';
    try {
      bpm = detect(audioBuffer);
    } catch (e) {}

    const { data: trackResponse } = await api.track.addTrack({
      name: file.name.split('.').slice(0, -1).join('.'),
      description: '',
      metadata: {
        artistName: user?.firstname,
        trackNumber: futureTrackNumber,
        duration: secondsToMMSS(audioBuffer.duration),
        bpm,
        lyrics: '',
        style: '',
        catalogue: '',
        scaleType: '',
        key: '',
        versionName: '',
        mainTrackNumber: '',
        isMain: true,
        equalRoyaltySplit: true,
      },
      teamId: activeProject.teamId,
      parentId: null,
      projectId: activeProject.id,
    });

    try {
      bpm = detect(audioBuffer);
    } catch (e) {
      toast.warn('Sample is to short to accurately detect BPM.');
    }

    return trackResponse.result;
  }

  async function addFile(file: File, trackId: string) {
    const { data: fileResponse } = await api.file.addFile({
      name: file.name,
      size: file.size,
      parentId: trackId,
      contentType: cleanFileType(file),
      projectId: activeProject.id,
      tenantId: activeProject.teamId,
      role: 'MAIN',
    });
    return fileResponse.result;
  }

  return (
    <div
      key={file.name}
      className={` flex items-center justify-between border-b border-gray-200 px-4 py-4 transition-colors ease-in-out dark:border-gray-600 ${
        fileUploadProgress === 100 ? 'hidden' : ''
      }`}
    >
      <div className='flex w-full'>
        <div className='flex w-full flex-row items-center justify-between space-x-4'>
          <div className='flex grow items-center space-x-2'>
            <div>
              <FaCompactDisc
                size={40}
                className='text-gray-600 dark:text-gray-300'
              />
            </div>
            <div className='flex w-full flex-col justify-center'>
              {track && file && (
                <>
                  <div className='flex w-full justify-between'>
                    <span className='break-all text-sm font-semibold'>
                      {track.name}
                    </span>
                    <span className='text-sm font-medium'>
                      {`${fileUploadProgress}%`}
                    </span>
                  </div>
                  <div className='h-5 w-full flex-col justify-end break-all text-sm font-semibold text-gray-400'>
                    <div className='h-2.5 w-full rounded-full bg-gray-700 dark:bg-gray-300'>
                      <div
                        className='h-2.5 rounded-full bg-emerald-600'
                        style={{
                          width: `${fileUploadProgress + 1}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
