export function GetStartedStepWindow({ nextStep }: { nextStep: () => void }) {
  return (
    <div className='py-8'>
      <div className='mt-4 mb-10 font-medium text-gray-700 dark:text-slate-200'>
        You are all set to get started with KTR Studio!
      </div>
      <div className='flex w-full justify-center'>
        <button
          type='submit'
          className='group relative flex w-1/2 justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-semibold text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
          onClick={() => nextStep()}
        >
          Let's Go!
        </button>
      </div>
    </div>
  );
}
