export function KTRLogo() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 3800 3680'>
      <g fill='rgb(69,62,219)'>
        <g>
          <path d='M678 3441 c-156 -50 -271 -165 -318 -319 -19 -62 -20 -95 -20 -1262 0 -1162 1 -1200 20 -1261 20 -67 64 -144 109 -193 42 -46 139 -105 209 -126 62 -19 95 -20 1262 -20 1167 0 1200 1 1262 20 70 21 167 80 209 126 45 49 89 126 109 193 19 61 20 99 20 1261 0 1167 -1 1200 -20 1262 -21 70 -80 167 -126 209 -49 45 -126 89 -193 109 -61 19 -98 20 -1265 19 -1134 0 -1204 -1 -1258 -18z m1474 -1368 l3 -718 245 0 245 0 3 -153 3 -152 -696 0 -695 0 0 150 0 150 73 1 c39 1 151 2 247 3 l175 1 3 718 2 717 195 0 195 0 2 -717z m-1260 354 l3 -143 39 -52 c37 -50 40 -53 79 -50 l40 3 121 192 121 193 102 0 102 0 -164 -253 c-120 -185 -165 -261 -165 -283 0 -24 32 -72 145 -217 80 -103 145 -190 145 -192 0 -3 -37 -5 -82 -4 l-83 0 -161 212 c-161 212 -161 212 -202 215 l-42 3 0 -215 0 -216 -95 0 -95 0 0 475 0 475 94 0 95 0 3 -143z m1750 -29 l3 -173 95 -3 95 -3 87 176 88 175 99 0 100 0 -46 -87 c-25 -49 -63 -121 -84 -161 -52 -98 -52 -134 -1 -166 71 -44 124 -146 124 -236 -1 -139 -83 -240 -231 -284 -43 -13 -103 -16 -288 -16 l-233 0 0 475 0 475 95 0 95 0 2 -172z'></path>
          <path d='M2642 1923 l3 -168 135 0 c147 0 169 7 206 63 26 39 26 165 0 208 -34 56 -59 64 -210 64 l-136 0 2 -167z'></path>
        </g>
      </g>
      <g fill='rgb(255,255,255)'>
        <g>
          <path d='M1725 2103 l0 -716 -145 1 c-80 1 -191 -1 -247 -5 l-103 -6 0 -179 0 -178 725 0 725 0 -2 183 -3 182 -245 1 -245 0 -3 717 -2 717 -228 0 -227 0 0 -717z'></path>
          <path d='M670 2095 l0 -505 125 0 125 0 0 215 c0 131 4 215 10 215 7 0 261 -322 325 -413 11 -16 27 -17 148 -15 l137 3 -170 219 c-93 120 -166 224 -163 230 4 6 83 128 176 271 93 143 172 266 174 273 4 9 -29 12 -143 12 l-149 0 -121 -192 c-66 -106 -125 -193 -131 -195 -6 -2 -28 20 -49 49 l-39 52 -3 143 -3 143 -125 0 -124 0 0 -505z'></path>
          <path d='M2420 2095 l0 -505 263 0 c213 0 274 3 318 16 159 47 231 144 232 314 1 71 -3 94 -24 140 -25 53 -72 109 -119 140 l-23 16 30 54 c17 30 62 117 102 193 l71 137 -145 0 -145 0 -88 -175 -87 -176 -65 3 -65 3 -2 170 -2 170 -125 3 -126 3 0 -506z m490 -51 c60 -36 84 -139 45 -197 -34 -52 -61 -62 -175 -62 l-105 0 -3 138 -3 137 107 0 c80 0 113 -4 134 -16z'></path>
        </g>
      </g>
    </svg>
  );
}
