import { FaUserAstronaut } from 'react-icons/fa';
import { classNames } from '../../utils';

interface Props {
  onClick: () => void;
  firstname: string;
  lastname: string;
  className?: string;
  active: boolean;
}

export const Person = ({
  firstname,
  lastname,
  onClick,
  className = '',
  active,
}: Props) => {
  return (
    <div
      className={classNames(
        'flex w-full cursor-pointer items-center justify-between border-l-4 border-b border-y-gray-200 border-l-transparent bg-gray-50 p-4 font-semibold transition-colors hover:border-l-gray-200 dark:border-y-gray-600 dark:bg-gray-800 hover:dark:border-l-gray-600',
        className,
        active ? 'border-l-gray-200 dark:border-l-gray-600' : ''
      )}
      onClick={onClick}
    >
      <div className='flex grow items-center space-x-2'>
        <div>
          <FaUserAstronaut
            size={32}
            className='text-gray-600 dark:text-gray-300'
          />
        </div>
        <div>
          {firstname} {lastname}
        </div>
      </div>
    </div>
  );
};
